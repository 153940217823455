import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const userFeedbackApi = createApi({
  reducerPath: 'userFeedbackApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),
  tagTypes: ['userFeedback'],

  endpoints: (builder) => ({
    getFeedbackList: builder.query({
      query: () => {
        return {
          url: '/educator/cm/level-feedback',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['userFeedback'],
    }),

    getfeedbackItem: builder.query({
      query: ({id}) => {
        return {
          url: `/educator/cm/feedback/${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['userFeedback'],
    }),
  }),
});

export const {
  useGetFeedbackListQuery,
//   useGetFeedbackItemQuery,
} = userFeedbackApi;