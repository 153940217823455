import {Flex, Table, Tbody, Text, Th, Thead, Tr} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {IsErrorMessage, LoaderSpinner, SharedWrapper} from 'common';
import {useGetCourseVersionsQuery} from 'services/coursesService';
import {VersionItem} from './VersionItem';
import {ReactComponent as versionsIcon} from 'assets/svg/clock.svg';

export const Versions = ({courseId}: VersionsDetails) => {
  const {data, isLoading, error} = useGetCourseVersionsQuery(courseId);

  return (
    <>
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <VersionsIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Versions
          </Text>
        </Flex>

        {isLoading && <LoaderSpinner />}

        <IsErrorMessage error={error} />

        {!isLoading && !error && data && (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>VERSION</Th>
                  <Th>EDUCATOR</Th>
                  <Th>CREATED</Th>
                  <Th>STATUS</Th>
                  <Th />
                </Tr>
              </Thead>

              <Tbody>
                {data.map(({id, educator, createdAt, version, status}: any) => (
                  <VersionItem
                    key={id}
                    createdAt={createdAt}
                    id={id}
                    educator={educator}
                    version={version}
                    status={status}
                    versionEntityId={courseId}
                  />
                ))}
              </Tbody>
            </Table>

            {!data.length && (
              <Text color="slate.800" p={5}>
                No versions
              </Text>
            )}
          </>
        )}
      </SharedWrapper>
    </>
  );
};

const VersionsIcon = styled(versionsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

type VersionsDetails = {courseId: number};
