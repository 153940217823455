import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import {useGetAnswerIdQuery, useUpdateAnswerMutation} from 'services/questionsService';
import {LoaderSpinner, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {useParamId} from 'utils';

export const Answer = ({baseUrl, question}: any) => {
  const id = useParamId();
  const history = useHistory();
  const toast = useToast();

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<any>();

  const {data: answer, isLoading} = useGetAnswerIdQuery(id);
  const [updateAnswer] = useUpdateAnswerMutation();

  const [inputLengthValue, setInputLengthValue] = useState(0);
  const maxLengthValue = 40;

  useEffect(() => {
    if (!isLoading && answer) {
      setValue('answer', answer.title);
      setValue('element', answer.payload?.firstColumnElementId);
      setInputLengthValue(answer.title.length);
    }
  }, [answer]);

  const onSubmit = async (data: any) => {
    const updatedAnswer = {
      title: data.answer,
      payload: {firstColumnElementId: data.element},
    };
    const result = (await updateAnswer({answerId: id, updatedAnswer})) as any;

    if (result.data.success) {
      toast({
        title: 'Answer edited',
        description: result.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      history.push(baseUrl + '/answers');
    }
  };

  if (isLoading) return <LoaderSpinner />;

  const isInvalidForm = !!errors.answer;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Answer details" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>

                <Input
                  id="answer"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('answer', {
                    required: 'Answer is required',
                    maxLength: {value: maxLengthValue, message: 'Too long answer'},
                    onChange: (e) => setInputLengthValue(e.target.value.length),
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
                <Text>
                  {inputLengthValue} of {maxLengthValue} available characters
                </Text>
              </Box>

              <Box>
                <FormLabel htmlFor="element" color="slate.400">
                  CORRESPONDING ELEMENT
                </FormLabel>

                <Select
                  id="element"
                  bg="blue.200"
                  {...register('element', {
                    required: 'CORRESPONDING ELEMENT is required',
                    valueAsNumber: true,
                  })}
                >
                  {question.questionPayload.elements.map(({id, value}: any) => {
                    return (
                      <option key={id} value={id}>
                        {value}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
