import {Controller, useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import styled from '@emotion/styled';
import {CreatableSelect} from 'chakra-react-select';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Text,
} from '@chakra-ui/react';
import {
  useCreateNewGroupMutation,
  useGetGroupsQuery,
} from 'services/avatarService';

import {
  LoaderSpinner,
  SharedWrapper,
  ImageContentInput,
  IsErrorMessage,
} from 'common';
import {AvatarGroupChildProps} from 'models';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';
import {Menu, Header} from './components';

export const AvatarGroupCreate = () => {
  const {data: groups, error, isLoading} = useGetGroupsQuery(null);
  const [createCourse, {data: createdGroup, isSuccess}] = useCreateNewGroupMutation();

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<AvatarGroupChildProps>();

  const onSubmit = async (avatarGroupData: AvatarGroupChildProps) => {
    await createCourse({...avatarGroupData});
  };

  if (isSuccess) {
    return <Redirect to={`/create-group/${createdGroup.id}`} />;
  }

  if (isLoading) return <LoaderSpinner />;

  const groupsFiltered = (groups ?? []).map((group: AvatarGroupChildProps) => {
    if (!group.parent_id) {
      return group;
    }
  });

  const isInvalidForm = !!errors.label;

  return (
    <>
      <Header />
      <Menu />

      <SharedWrapper my="40px" p="40px">
        <Flex align="center" mb="5">
          <DetailsIcon />

          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Create Group
          </Text>
        </Flex>

        <IsErrorMessage error={error} />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="label" color="slate.400">
                  Label
                </FormLabel>

                <Input
                  id="label"
                  bg="blue.200"
                  {...register('label', {
                    required: 'Label is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.label && errors.label.message}
                </FormErrorMessage>
              </Box>
              <Box>
                <FormLabel htmlFor="thumbnail_url" color="slate.400">
                  Thumbnail
                </FormLabel>
                <Controller
                  name="thumbnail_url"
                  control={control}
                  defaultValue={''}
                  render={({field: {value}}) => (
                    <ImageContentInput value={value} inputName="thumbnail_url" setValue={setValue} />
                  )}
                />

                <FormErrorMessage>{errors.thumbnail_url && errors.thumbnail_url.message}</FormErrorMessage>
              </Box>
              <Box>
                <FormLabel htmlFor="parent_id" color="slate.400">
                  Parent Group
                </FormLabel>
                <Controller
                  name="parent_id"
                  control={control}
                  rules={{}}
                  render={({field: {onChange, onBlur}}) => (
                    <CreatableSelect
                      isClearable
                      options={groups}
                      placeholder={'Choose...'}
                      onChange={(option: AvatarGroupChildProps) =>
                        onChange(option.id)
                      }
                      onBlur={onBlur}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.parent_id && 'Tags is required'}
                </FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Create
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
