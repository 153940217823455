import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer, Text, useToast} from '@chakra-ui/react';
import {LoaderSpinner, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {useGetAnswerIdQuery, useUpdateAnswerMutation} from 'services/questionsService';
import {useParamId} from 'utils';

export const Answer = ({baseUrl}: any) => {
  const toast = useToast();
  const history = useHistory();

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<any>();

  const [inputLengthValue, setInputLengthValue] = useState(0);
  const maxLengthValue = 10;

  const id = useParamId();

  const {data: answer, isLoading} = useGetAnswerIdQuery(id);
  const [updateAnswer] = useUpdateAnswerMutation();

  useEffect(() => {
    if (!isLoading && answer) {
      setValue('answer', answer.title);
      setInputLengthValue(answer.title.length);
    }
  }, [answer]);

  const onSubmit = async (data: any) => {
    const updatedAnswer = {
      title: data.answer.toUpperCase(),
    };
    const result = (await updateAnswer({answerId: id, updatedAnswer})) as any;

    if (result.data.success) {
      toast({
        title: 'New answer added',
        description: result.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      history.push(baseUrl + '/answers');
    }
  };

  if (isLoading) return <LoaderSpinner />;

  const isInvalidForm = !!errors.answer;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title={'Answer details'} />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>
                <Input
                  id="answer"
                  bg="blue.200"
                  autoComplete="off"
                  textTransform="uppercase"
                  {...register('answer', {
                    required: 'Answer is required',
                    maxLength: {value: maxLengthValue, message: 'Too long answer'},
                    onChange: (e) => setInputLengthValue(e.target.value.length),
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
                <Text>
                  {inputLengthValue} of {maxLengthValue} available characters
                </Text>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
