import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {CreatableSelect} from 'chakra-react-select';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spacer,
  Text,
  Textarea,
} from '@chakra-ui/react';
import {
  useCreateNewVersionCourseMutation,
  useGetCourseByIdQuery,
  useGetCourseStatusesQuery,
  useUpdateCourseMutation,
} from 'services/coursesService';
import {useGetTopicsTagsQuery} from 'services/topicsService';
import {LoaderSpinner, setCurrentCourseId, SharedWrapper} from 'common';
import {CourseData, OptionType, Status, Tag} from 'models';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';

export type DetailsProps = {id: string | number} | any;

export const Details = ({id, versionEntityId}: DetailsProps) => {
  const dispatch = useDispatch();

  const {data, isLoading} = useGetCourseByIdQuery({id});
  const {data: courseTags, isLoading: isLoadingTopicsTags} = useGetTopicsTagsQuery('');
  const {data: statuses, isLoading: isLoadingStatuses} = useGetCourseStatusesQuery('');

  const [updateCourse] = useUpdateCourseMutation();
  const [createNewVersionCourse] = useCreateNewVersionCourseMutation();

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<CourseData>();

  useEffect(() => {
    if (!isLoading && data) {
      const {title, description, lastStatus, tags} = data;
      setValue('title', title);
      setValue('description', description);
      setValue('statusTypeId', lastStatus.statusTypeId);
      setValue('tags', tags);

      dispatch(setCurrentCourseId(+id));
    }
  }, [data]);

  if (isLoading || isLoadingStatuses || isLoadingTopicsTags) return <LoaderSpinner />;

  const saveChangesHandler = async (courseData: CourseData) => {
    await updateCourse({id, courseData});
  };

  const newVersionHandler = async (courseData: CourseData) => {
    const {title, description, tags} = courseData;
    const newVersionData = {
      description,
      tags,
      title,
    };
    await createNewVersionCourse({id: versionEntityId, courseData: newVersionData});
  };

  const selectedTags =
    data?.tags?.map((tag: string) => ({
      label: tag,
      value: tag,
    })) ?? [];

  const topicsTagsFiltered =
    courseTags ??
    []
      .filter((tag: Tag) => tag.is_enabled)
      .map(({tag_value}: Tag) => ({
        label: tag_value,
        value: tag_value,
      }));

  const isInvalidForm = !!errors.statusTypeId || !!errors.title || !!errors.description || !!errors.tags;

  return (
    <>
      <SharedWrapper p="10">
        <Flex align="center" justify="space-between">
          <Flex align="center" mb="5">
            <DetailsIcon />
            <Text fontWeight="medium" fontSize="16" color="slate.900">
              Course details
            </Text>
          </Flex>
        </Flex>
        <Box>
          <Form>
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="status" color="slate.400">
                  Status
                </FormLabel>

                <Select
                  id="status"
                  bg="blue.200"
                  {...register('statusTypeId', {
                    required: 'Status is required',
                    valueAsNumber: true,
                  })}
                >
                  {statuses
                    ?.filter((status: Status) => status.is_enabled)
                    .map((status: Status) => {
                      return (
                        <option key={status.id} value={status.id}>
                          {status.view_value}
                        </option>
                      );
                    })}
                </Select>
                <FormErrorMessage>{errors.statusTypeId && errors.statusTypeId.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="title" color="slate.400">
                  Title
                </FormLabel>

                <Input
                  id="title"
                  bg="blue.200"
                  {...register('title', {
                    required: 'Title is required',
                  })}
                />
                <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="description" color="slate.400">
                  Description
                </FormLabel>
                <Textarea
                  id="description"
                  bg="blue.200"
                  {...register('description', {
                    required: 'Description is required',
                  })}
                />
                <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="tags" color="slate.400">
                  Tags
                </FormLabel>
                <Controller
                  name="tags"
                  control={control}
                  rules={{required: 'Tags is required'}}
                  render={({field: {onChange, onBlur}}) => (
                    <CreatableSelect
                      options={topicsTagsFiltered}
                      placeholder={'Choose...'}
                      isMulti
                      onChange={(options: OptionType[]) => onChange(options?.map((option: OptionType) => option.value))}
                      onBlur={onBlur}
                      defaultValue={selectedTags}
                    />
                  )}
                />
                <FormErrorMessage>{errors.tags && 'Tags is required'}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />
            <ButtonGroup>
              <Button type="submit" isLoading={isSubmitting} mt="10" onClick={handleSubmit(saveChangesHandler)}>
                Save changes
              </Button>
              <Button type="submit" isLoading={isSubmitting} mt="10" onClick={handleSubmit(newVersionHandler)}>
                Save as new version
              </Button>
            </ButtonGroup>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
