import {ChakraProvider} from '@chakra-ui/react';
import {Routes} from './Routes';
import {AppHelmet} from './AppHelmet';
import {mainTheme} from 'theme';

function App() {
  return (
    <>
      <AppHelmet />
      <ChakraProvider theme={mainTheme}>
        <Routes />
      </ChakraProvider>
    </>
  );
}

export default App;
