import {useEffect} from 'react';
import styled from '@emotion/styled';
import {useDispatch} from 'react-redux';
import {useForm} from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Text,
  Textarea,
} from '@chakra-ui/react';
import {useGetLessonByIdQuery, useUpdateLessonMutation} from 'services/lessonsService';
import {LoaderSpinner, setCurrentLessonId, SharedWrapper} from 'common';
import {LessonDetails} from 'models';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';

export const Details = ({id}: any) => {
  const dispatch = useDispatch();

  const {data: lesson, isLoading, error} = useGetLessonByIdQuery(id);
  const [updateLesson] = useUpdateLessonMutation();

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<LessonDetails>();

  useEffect(() => {
    if (!isLoading && !error && lesson) {
      const {title, description} = lesson;
      setValue('title', title);
      setValue('description', description);

      dispatch(setCurrentLessonId(+id));
    }
  }, [lesson]);

  if (isLoading) return <LoaderSpinner />;

  const onSubmit = async (updatedLesson: LessonDetails) => {
    await updateLesson({lessonId: id, updatedLesson});
  };

  const isInvalidForm = !!errors.title || !!errors.description;

  return (
    <>
      <SharedWrapper p="10">
        <Flex align="center" mb="5">
          <DetailsIcon />

          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Lesson details
          </Text>
        </Flex>

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="title" color="slate.400">
                  Title
                </FormLabel>

                <Input
                  id="title"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('title', {
                    required: 'Title is required',
                  })}
                />
                <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="description" color="slate.400">
                  Description
                </FormLabel>
                <Textarea id="description" bg="blue.200" autoComplete="off" {...register('description')} />
                <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
