import {useHistory} from 'react-router-dom';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer, Text} from '@chakra-ui/react';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {LoaderSpinner, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {useParamId} from 'utils';

export const Element = ({baseUrl, questionId, question}: any) => {
  const history = useHistory();
  const id = useParamId();

  const [updateQuestion] = useUpdateQuestionMutation();

  const element = question.questionPayload.elements.find((elem: any) => +elem.id === +id);

  if (!element) return <LoaderSpinner />;

  const {value} = element;

  const [inputLengthValue, setInputLengthValue] = useState(value.length);
  const maxLengthValue = 40;

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>({
    defaultValues: {value},
  });

  const isInvalidForm = !!errors.value;

  const onSubmit = async ({value}: any) => {
    const updatedElements = question.questionPayload.elements.map((elem: any) =>
      +elem.id === +id
        ? {
          value,
          id,
        }
        : elem
    );

    const updatedQuestion = {
      questionPayload: {...question.questionPayload, elements: updatedElements},
    };

    await updateQuestion({questionId, updatedQuestion});

    history.push(baseUrl + '/parameters');
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="First Column Element" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="value" color="slate.400">
                Title
              </FormLabel>

              <Input
                id="value"
                bg="blue.200"
                autoComplete="off"
                {...register('value', {
                  required: 'Value is required',
                  maxLength: {value: maxLengthValue, message: 'Too long value'},
                  onChange: (e) => setInputLengthValue(e.target.value.length),
                })}
              />
              <FormErrorMessage>{errors.value && errors.value.message}</FormErrorMessage>
              <Text>
                {inputLengthValue} of {maxLengthValue} available characters
              </Text>
            </Box>
          </FormControl>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting} mt="10">
            Save changes
          </Button>
        </Form>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
