import {Route, useRouteMatch} from 'react-router-dom';
import {useGetQuestionByIdQuery} from 'services/questionsService';
import {Answer, Answers, Details, Element, NewAnswer, NewElement, Parameters} from './components';
import {useParamId} from 'utils';

export const Columns = () => {
  const {url} = useRouteMatch();
  const questionId = useParamId();
  const {data: question} = useGetQuestionByIdQuery(questionId);

  return (
    <>
      <Route exact path={url} render={() => <Details questionId={questionId} question={question} />} />

      <Route
        exact
        path={url + '/parameters'}
        render={() => <Parameters question={question} questionId={questionId} baseUrl={url} />}
      />

      <Route exact path={url + '/new-answer'} render={() => <NewAnswer questionId={questionId} baseUrl={url} />} />

      <Route
        exact
        path={url + '/parameters/elements/:id'}
        render={() => <Element questionId={questionId} question={question} baseUrl={url} />}
      />
      <Route
        exact
        path={url + '/parameters/new-element'}
        render={() => <NewElement questionId={questionId} question={question} baseUrl={url} />}
      />

      <Route
        exact
        path={url + '/answers'}
        render={() => <Answers questionId={questionId} question={question} baseUrl={url} />}
      />

      <Route exact path={url + '/answers/:id'} render={() => <Answer question={question} baseUrl={url} />} />
    </>
  );
};
