import {SharedMenu} from 'common';
import {useRouteMatch} from 'react-router-dom';

const links = [
  {
    title: 'details',
    value: '',
  },
  {
    title: 'KEY FACTS',
    value: '/keyfacts',
  },
  {
    title: 'LESSONS',
    value: '/lessons',
  },
  {
    title: 'ARCHIVED',
    value: '/lessons-archived',
  },
];

export const Menu = () => {
  const {url} = useRouteMatch();

  return <SharedMenu baseUrl={url} separatorAfterElement={2} links={links} />;
};
