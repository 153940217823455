import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Spacer, Switch} from '@chakra-ui/react';
import {LoaderSpinner, SharedWrapper} from 'common';
import {useGetAnswerIdQuery, useUpdateAnswerMutation} from 'services/questionsService';
import {QuestionHeader} from 'pages/question/common';
import {useParamId} from 'utils';

export const Answer = () => {
  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<any>();

  const id = useParamId();

  const {data: answer, isLoading} = useGetAnswerIdQuery(id);
  const [updateAnswer] = useUpdateAnswerMutation();

  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    if (!isLoading && answer) {
      setValue('answer', answer.title);

      setIsCorrect(!!answer.isCorrect);
    }
  }, [answer]);

  const onSubmit = async (data: any) => {
    const updatedAnswer = {
      title: data.answer,
      isCorrect,
    };
    await updateAnswer({answerId: id, updatedAnswer});
  };

  const isCorrectHandler = () => setIsCorrect((prev) => !prev);

  if (isLoading) return <LoaderSpinner />;

  const isInvalidForm = !!errors.answer || !!errors.imageContent;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title={'Answer details'} />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>

                <Input
                  id="answer"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('answer', {
                    required: 'Answer is required',
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
              </Box>

              <Box>
                <Flex justify="space-between" align="baseline">
                  <FormLabel htmlFor="isCorrect" color="slate.400">
                    Is correct?
                  </FormLabel>

                  <Switch id="isCorrect" isChecked={isCorrect} onChange={isCorrectHandler} />
                </Flex>

                <FormErrorMessage>{errors.isCorrect && errors.isCorrect.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
