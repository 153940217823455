export const enum QuestionTypes {
  Captcha = 1,
  RoamingCapture = 2,
  AppearingCapture = 3,
  Trivia = 4,
  Tinder = 5,
  ImageBins = 7,
  SentenceGaps = 8,
  ImageGaps = 9,
  TextGaps = 10,
  ImageOrder = 11,
  Columns = 14,
  Wordsearch = 15,
  UQuestion = 16,
}

export const questionTypesSelect = {
  1: 'Captcha',
  2: 'Roaming Capture',
  3: 'Appearing Capture',
  4: 'Trivia',
  5: 'Tinder',
  7: 'Image Bins',
  8: 'Sentence Gaps',
  9: 'Image Image Gaps',
  10: 'Image Text Gaps',
  11: 'Image Order',
  14: 'Columns',
  15: 'Wordsearch',
  16: 'U-Question',
};
