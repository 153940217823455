import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {ImageContentInput, LoaderSpinner, SharedWrapper} from 'common';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Text, Textarea,
  useToast
} from '@chakra-ui/react';
import {useUpdateLessonMutation} from 'services/lessonsService';
import {useParamId} from 'utils';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';

export const Dialogue = ({baseUrl, lessonId, lesson}: any) => {
  const history = useHistory();
  const toast = useToast();

  const id = useParamId();

  const dialogue = lesson?.dialogues?.find((elem: any) => +elem.id === +id) || lesson?.dialogues[id];

  const [inputLengthValue, setInputLengthValue] = useState(0);
  const maxLengthValue = 165;

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<any>();

  useEffect(() => {
    if (lesson) {
      const {phrase, icon, name} = dialogue ?? null;
      setValue('phrase', phrase);
      setValue('icon', icon);
      setValue('name', name);
      setInputLengthValue(phrase.length);
    }
  }, [lesson]);

  const [updateDialogues] = useUpdateLessonMutation();

  const onSubmit = async (data: any) => {
    const updatedDialogues = lesson.dialogues.map((elem: any, idx: number) => {
      if (+elem.id === +id || idx === +id) return {...elem, ...data};

      return elem;
    });

    const updatedLesson = {dialogues: updatedDialogues};
    const result = (await updateDialogues({lessonId, updatedLesson})) as any;
    if (result.data !== null) {
      toast({
        title: 'Success',
        description: result.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      history.push({
        pathname: baseUrl + '/theory',
        hash: '#dialogues',
      });
    }
  };
  if (!lesson) return <LoaderSpinner />;

  const isInvalidForm = !!errors.phrase || !!errors.icon;

  return (
    <>
      <SharedWrapper p="10">
        <Flex align="center" mb="5">
          <DetailsIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Dialogue details
          </Text>
        </Flex>

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="name" color="slate.400">
                  Speaker Name
                </FormLabel>

                <Input
                  id="name"
                  bg="blue.200"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                />
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="phrase" color="slate.400">
                  Phrase
                </FormLabel>

                <Textarea
                  rows={4}
                  id="phrase"
                  bg="blue.200"
                  {...register('phrase', {
                    required: 'Phrase is required',
                    maxLength: {value: maxLengthValue, message: 'Too long title'},
                    onChange: (e) => setInputLengthValue(e.target.value.length),
                  })}
                />
                <FormErrorMessage>{errors.phrase && errors.phrase.message}</FormErrorMessage>
                <Text>
                  {inputLengthValue} of {maxLengthValue} available characters
                </Text>
              </Box>

              <Box>
                <FormLabel htmlFor="icon" color="slate.400">
                  Icon
                </FormLabel>
                <Controller
                  name="icon"
                  control={control}
                  rules={{required: 'Icon is required'}}
                  render={({field: {value}}) => (
                    <ImageContentInput inputName="icon" value={value} setValue={setValue} imgLink={dialogue.icon} />
                  )}
                />

                <FormErrorMessage>{errors.icon && errors.icon.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
