import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

export const lessonsApi = createApi({
  reducerPath: 'lessonsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),
  tagTypes: ['Lessons', 'Keyfacts'],

  endpoints: (builder) => ({
    getLessonsByTopicId: builder.query({
      query: ({id, isArchived}) => {
        const filter = isArchived ? '&filter=archived' : '';

        return {
          url: `/educator/cm/lessons?topic_id=${id}&limit=100&offset=0${filter}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['Lessons'],
    }),
    getLessonById: builder.query({
      query: (id) => {
        return {
          url: `/educator/cm/lessons/${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['Lessons'],
    }),

    getKeyfactsLessonsById: builder.query({
      query: (id) => {
        return {
          url: `/educator/cm/lesson-key-facts?lesson_id=${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['Keyfacts'],
    }),
    getKeyfactLessonsById: builder.query({
      query: (id) => {
        return {
          url: `/educator/cm/lesson-key-facts/${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['Keyfacts'],
    }),

    updateLessonOrder: builder.mutation({
      query: ({topicId, updatedOrder}) => {
        return {
          url: `educator/cm/lessons/${topicId}`,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: updatedOrder,
        };
      },
      invalidatesTags: ['Lessons'],
    }),
    updateDialoguePhraseOrder: builder.mutation({
      query: ({lessonId, updatedOrder}) => {
        return {
          url: `educator/cm/lesson-dialogues/${lessonId}`,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: updatedOrder,
        };
      },
      invalidatesTags: ['Lessons'],
    }),
    deleteDialoguePhrase: builder.mutation({
      query: ({lessonId, phraseId}) => {
        return {
          url: `educator/cm/lesson-dialogues/${lessonId}?phrase_id=${phraseId}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['Lessons'],
    }),
    updateLesson: builder.mutation({
      query: ({lessonId, updatedLesson}) => {
        return {
          url: `educator/cm/lessons/${lessonId}`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: updatedLesson,
        };
      },
      invalidatesTags: ['Lessons'],
    }),
    createLesson: builder.mutation({
      query: ({newLesson}) => {
        return {
          url: 'educator/cm/lessons/',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: newLesson,
        };
      },
      invalidatesTags: ['Lessons'],
    }),
    deleteLevel: builder.mutation({
      query: ({level, topicId}) => {
        return {
          url: `educator/cm/levels/${level}?topic_id=${topicId}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['Lessons'],
    }),
    updateLessonKeyfact: builder.mutation({
      query: ({id, keyfactData}) => {
        return {
          url: `educator/cm/lesson-key-facts/${id}`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: keyfactData,
        };
      },
      invalidatesTags: ['Keyfacts'],
    }),
    createLessonKeyfact: builder.mutation({
      query: (keyfactData) => {
        return {
          url: '/educator/cm/lesson-key-facts',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: keyfactData,
        };
      },
      invalidatesTags: ['Keyfacts'],
    }),
    deleteLessonKeyfact: builder.mutation({
      query: (id) => {
        return {
          url: `/educator/cm/lesson-key-facts/${id}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['Keyfacts'],
    }),
    updateLessonKeyFactOrder: builder.mutation({
      query: ({id, order}) => {
        return {
          url: `educator/cm/lesson-key-facts/${id}`,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: order,
        };
      },
      invalidatesTags: ['Keyfacts'],
    }),
    archiveLesson: builder.mutation({
      query: (id) => {
        return {
          url: `educator/cm/lessons/${id}?cmd=archive`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['Lessons'],
    }),
    unArchiveLesson: builder.mutation({
      query: (id) => {
        return {
          url: `educator/cm/lessons/${id}?cmd=un_archive`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['Lessons'],
    }),
  }),
});

export const {
  useDeleteLevelMutation,
  useGetLessonsByTopicIdQuery,
  useGetLessonByIdQuery,
  useUpdateLessonOrderMutation,
  useDeleteLessonKeyfactMutation,
  useGetKeyfactsLessonsByIdQuery,
  useGetKeyfactLessonsByIdQuery,
  useUpdateLessonMutation,
  useUpdateDialoguePhraseOrderMutation,
  useDeleteDialoguePhraseMutation,
  useCreateLessonMutation,
  useCreateLessonKeyfactMutation,
  useUpdateLessonKeyFactOrderMutation,
  useUpdateLessonKeyfactMutation,
  useArchiveLessonMutation,
  useUnArchiveLessonMutation,
} = lessonsApi;
