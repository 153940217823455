import axios from 'axios';
import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'store';
import {CurrentRoutes} from 'models';

const initialState: CurrentRoutes = {
  currentCourseId: null,
  currentTopicId: null,
  currentLessonId: null,
  currentQuestionId: null,
  currentCourse: {title: '', url: ''},
  currentTopic: {title: '', url: ''},
  currentLesson: {title: '', url: ''},
  currentQuestion: {title: '', url: ''},
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    setCurrentCourseId(state, {payload}: PayloadAction<number>) {
      state.currentCourseId = payload;
    },
    setCurrentTopicId(state, {payload}: PayloadAction<number>) {
      state.currentTopicId = payload;
    },
    setCurrentLessonId(state, {payload}: PayloadAction<number>) {
      state.currentLessonId = payload;
    },
    setCurrentQuestionId(state, {payload}: PayloadAction<number>) {
      state.currentQuestionId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCourseData.fulfilled, (state, {payload}) => {
      state.currentCourse = {
        title: payload.title,
        url: '/course/' + payload.id,
      };
      state.currentCourseId = payload.id;
    });
    builder.addCase(fetchTopicData.fulfilled, (state, {payload}) => {
      state.currentTopic = {
        title: payload.title,
        url: '/topic/' + payload.id,
      };
      state.currentTopicId = payload.id;
    });
    builder.addCase(fetchLessonData.fulfilled, (state, {payload}) => {
      state.currentLesson = {
        title: payload.title,
        url: '/lesson/' + payload.id,
      };
      state.currentLessonId = payload.id;
    });
    builder.addCase(fetchQuestionData.fulfilled, (state, {payload}) => {
      state.currentQuestion = {
        title: payload.questionCondition,
        url: '/question/' + payload.id,
      };
      state.currentQuestionId = payload.id;
    });
  },
});

export const fetchCourseData = createAsyncThunk(
  'navigation/fetchCourseData',
  async (id: number) => {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_BASE_URL + `/educator/cm/courses/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  }
);
export const fetchTopicData = createAsyncThunk(
  'navigation/fetchTopicData',
  async (id: number) => {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_BASE_URL + `/educator/cm/topics/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  }
);
export const fetchLessonData = createAsyncThunk(
  'navigation/fetchLessonData',
  async (id: number) => {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_BASE_URL + `/educator/cm/lessons/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  }
);
export const fetchQuestionData = createAsyncThunk(
  'navigation/fetchQuestionData',
  async (id: number) => {
    const response = await axios({
      method: 'GET',
      url: process.env.REACT_APP_BASE_URL + `/educator/cm/questions/${id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
    return response.data;
  }
);

export const navigationReducer = navigationSlice.reducer;

export const {setCurrentCourseId} = navigationSlice.actions;
export const {setCurrentTopicId} = navigationSlice.actions;
export const {setCurrentLessonId} = navigationSlice.actions;
export const {setCurrentQuestionId} = navigationSlice.actions;

export const getCurrentCourse = (state: RootState) =>
  state.navigationReducer.currentCourse;
export const getCurrentCourseId = (state: RootState) =>
  state.navigationReducer.currentCourseId;
export const getCurrentTopic = (state: RootState) =>
  state.navigationReducer.currentTopic;
export const getCurrentTopicId = (state: RootState) =>
  state.navigationReducer.currentTopicId;
export const getCurrentLesson = (state: RootState) =>
  state.navigationReducer.currentLesson;
export const getCurrentLessonId = (state: RootState) =>
  state.navigationReducer.currentLessonId;
export const getCurrentQuestion = (state: RootState) =>
  state.navigationReducer.currentQuestion;
export const getCurrentQuestionId = (state: RootState) =>
  state.navigationReducer.currentQuestionId;
