import {Route, useRouteMatch} from 'react-router-dom';
import {Answer, Answers, Details, NewAnswer, Parameters} from './components';
import {useGetQuestionByIdQuery} from 'services/questionsService';
import {useParamId} from 'utils';

export const Wordsearch = () => {
  const {url} = useRouteMatch();
  const questionId = useParamId();
  const {data: question} = useGetQuestionByIdQuery(questionId);

  return (
    <>
      <Route exact path={url} render={() => <Details questionId={questionId} question={question} />} />

      <Route exact path={url + '/parameters'} component={Parameters} />

      <Route exact path={url + '/new-answer'} render={() => <NewAnswer questionId={questionId} baseUrl={url} />} />
      <Route exact path={url + '/answers'} render={() => <Answers questionId={questionId} baseUrl={url} />} />

      <Route exact path={url + '/answers/:id'} render={() => <Answer baseUrl={url} />} />
    </>
  );
};
