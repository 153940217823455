import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer, useToast} from '@chakra-ui/react';
import {LoaderSpinner, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {useParamId} from 'utils';

export const Gap = ({baseUrl, questionId, question}: any) => {
  const history = useHistory();
  const toast = useToast();

  const id = useParamId();

  const [updateQuestion] = useUpdateQuestionMutation();
  const gap = question.questionPayload.find(({Order}: any) => Order === id);

  if (!gap) return <LoaderSpinner />;

  const {LocationX: locationX, LocationY: locationY, Order: order} = gap;

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>({
    defaultValues: {locationX, locationY, order},
  });

  const isInvalidForm = !!errors.locationX || !!errors.locationY || !!errors.order;

  const onSubmit = async ({locationX, locationY}: any) => {
    const updatedGaps = question.questionPayload.map((elem: any) =>
      +elem.Order === +id
        ? {
          Order: elem.Order,
          LocationX: +locationX,
          LocationY: +locationY,
        }
        : elem
    );

    const updatedQuestion = {
      questionPayload: updatedGaps,
    };

    const result = (await updateQuestion({questionId, updatedQuestion})) as any;

    if (result.data.success) {
      toast({
        title: 'Success',
        description: 'New gap added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      history.push(baseUrl + '/parameters');
    }
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Gap" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="locationX" color="slate.400">
                locationX
              </FormLabel>

              <Input
                id="locationX"
                bg="blue.200"
                type="number"
                autoComplete="off"
                {...register('locationX', {
                  required: 'locationX is required',
                  valueAsNumber: true,
                })}
              />
              <FormErrorMessage>{errors.locationX && errors.locationX.message}</FormErrorMessage>
            </Box>
            <Box>
              <FormLabel htmlFor="locationY" color="slate.400">
                locationY
              </FormLabel>

              <Input
                id="locationY"
                bg="blue.200"
                type="number"
                autoComplete="off"
                {...register('locationY', {
                  required: 'locationY is required',
                  valueAsNumber: true,
                })}
              />
              <FormErrorMessage>{errors.locationY && errors.locationY.message}</FormErrorMessage>
            </Box>
          </FormControl>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting} mt="10">
            Save changes
          </Button>
        </Form>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
