import styled from '@emotion/styled';
import {Box} from '@chakra-ui/react';
import {ReactComponent as coursesIcon} from 'assets/svg/course.svg';

export const BorderBox = styled(Box)`
  border-radius: 4px;
  border: 1px solid rgba(46, 56, 77, 0.15);
`;

export const CoursesIcon = styled(coursesIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
