import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {useHistory} from 'react-router-dom';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer} from '@chakra-ui/react';
import {LoaderSpinner, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {useParamId} from 'utils';

export const Order = ({questionId, baseUrl, question}: any) => {
  const [updateQuestion] = useUpdateQuestionMutation();
  const history = useHistory();
  const id = useParamId();
  const orderObject = question.questionPayload.imgOrders.find(({Order}: any) => +Order === +id);

  if (!orderObject) return <LoaderSpinner />;

  const {Order: order} = orderObject;

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>({
    defaultValues: {order},
  });

  const isInvalidForm = !!errors.order;

  const onSubmit = async ({order}: any) => {
    const updatedOrders = question.questionPayload.imgOrders.map((elem: any) =>
      +elem.Order === +id
        ? {
          Order: +order,
        }
        : elem
    );

    const updatedQuestion = {
      // questionPayload: {...question.questionPayload, imgOrders: updatedOrders},
      questionPayload: {
        leftText: question.questionPayload.leftText,
        imgOrders: updatedOrders,
        rightText: question.questionPayload.rightText,
      }
    };

    await updateQuestion({questionId, updatedQuestion});
    history.push(baseUrl + '/parameters');
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Order" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="order" color="slate.400">
                Order
              </FormLabel>

              <Input
                id="order"
                bg="blue.200"
                type="number"
                autoComplete="off"
                {...register('order', {
                  required: 'order is required',
                  valueAsNumber: true,
                })}
              />
              <FormErrorMessage>{errors.order && errors.order.message}</FormErrorMessage>
            </Box>
          </FormControl>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting} mt="10">
            Save changes
          </Button>
        </Form>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
