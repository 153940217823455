import {useHistory} from 'react-router-dom';
import {Button, Flex, Td, Tr} from '@chakra-ui/react';
import {getDateTimeFromStr} from 'utils';
import {Educator} from 'models';

export const VersionItem = ({id, version, educator, createdAt, status}: VersionItemProps) => {
  const history = useHistory();
  const createdAtDate = getDateTimeFromStr(createdAt);

  const handleClick = () => {
    history.push(`/course/${id}`);
  };

  return (
    <Tr>
      <Td>{version}</Td>
      <Td>
        {educator.first_name} {educator.last_name}
      </Td>
      <Td>{createdAtDate}</Td>
      <Td>{status}</Td>

      <Td>
        <Flex justify="flex-end">
          <Button size="sm" variant="secondary" onClick={handleClick}>
            Edit
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
};

type VersionItemProps =
  | {
      id: number;
      version: number;
      educator: Educator;
      createdAt: string;
      status: string;
    }
  | any;
