import {Helmet} from 'react-helmet';

export const AppHelmet = () => {
  return (
    <Helmet>
      <title>{process.env.REACT_APP_SITE_TITLE}</title>
      <meta name="description" content="Haby Academy" />
      <meta name="theme-color" content="#2E5BFF" />
    </Helmet>
  );
};
