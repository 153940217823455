import {Td, Tr} from '@chakra-ui/react';

// eslint-disable-next-line no-unused-vars,no-shadow
export enum ErrType {
    // eslint-disable-next-line no-unused-vars
    WARNING = 'WARNING',
    // eslint-disable-next-line no-unused-vars
    ERROR = 'ERROR',
}

export interface CourseValidationErrorItemModel {
    id: number;
    item: string;
    type: ErrType;
    courseId: number;
    courseTitle: string;
    topicId: number | null;
    topicTitle: string | null;
    lessonId: number | null;
    lessonTitle: string | null;
    lessonTypeId: number | null;
    theoryType: string | null;
    lessonLevel: number | null;
    questionId: number | null;
    questionTypeId: number | null;
    mechanicName: string | null;
    answerId: number | null;
}

export const CourseValidationErrorItem = ({
  id,
  item,
  courseTitle,
  courseId,
  lessonTitle,
  lessonId,
  lessonLevel,
  lessonTypeId,
  questionTypeId,
  questionId,
  theoryType,
  type,
  topicTitle,
  topicId,
  mechanicName,
  answerId
}: CourseValidationErrorItemModel) => {

  return (
    <Tr>
      <Td> &#x2699; COURSE: <strong>{courseTitle}</strong> -ID: <i>{courseId}</i>
        <br/>
                → TOPIC: <strong>{topicTitle}</strong> - ID: <i>{topicId}</i>
        <br/>
                → LESSON: <strong>{lessonTitle}</strong>
        <br/>
                &nbsp; - ID: <i>{lessonId}</i>
        <br/>
                &nbsp; - LEVEL: <u>{lessonLevel}</u> | Theory: {theoryType} ({lessonTypeId})
        <br/>
                → QUESTION: {mechanicName} ({questionTypeId}) - ID <i>{questionId}</i>
        <br/>
                → ANSWER ID: <strong>{answerId}</strong>
      </Td>
      <Td>
        <strong style={{
          color: type === 'ERROR' ? 'red' : 'orange'
        }}>&#9881; {type}</strong>
      </Td>
      <Td><p style={{
        minHeight: '10vh',
        borderColor: type === 'ERROR' ? 'red' : 'orange',
        borderWidth: 1,
        padding: 10,
      }}>
        {item}
      </p></Td>
    </Tr>
  );
};
