import {useHistory} from 'react-router-dom';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Switch,
  useToast,
} from '@chakra-ui/react';
import {useCreateAnswerMutation} from 'services/questionsService';
import {SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const NewAnswer = ({baseUrl, questionId}: any) => {
  const toast = useToast();
  const history = useHistory();

  const [isCorrect, setIsCorrect] = useState(true);
  const isCorrectHandler = () => setIsCorrect((prev) => !prev);

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>();

  const [createAnswer] = useCreateAnswerMutation();

  const onSubmit = async (data: any) => {
    const createdAnswer = {
      questionId,
      orderIndex: 0,
      isCorrect,
      title: data.answer,
    };

    const result = (await createAnswer(createdAnswer)) as any;

    if (result.data.success) {
      toast({
        title: 'Success',
        description: result.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      history.push(baseUrl + '/answers');
    }
  };

  const isInvalidForm = !!errors.answer || !!errors.imageContent;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Answer details" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>

                <Input
                  id="questionCondition"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('answer', {
                    required: 'Answer is required',
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
              </Box>

              <Box>
                <Flex justify="space-between" align="baseline">
                  <FormLabel htmlFor="isCorrect" color="slate.400">
                    Is correct?
                  </FormLabel>

                  <Switch id="isCorrect" isChecked={isCorrect} onChange={isCorrectHandler} />
                </Flex>

                <FormErrorMessage>{errors.isCorrect && errors.isCorrect.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
