import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer, Text, useToast} from '@chakra-ui/react';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {nanoidInt} from 'utils';
import {useState} from 'react';

export const NewElement = ({baseUrl, questionId, question}: any) => {
  const toast = useToast();
  const history = useHistory();

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>();

  const isInvalidForm = !!errors.value;

  const [inputLengthValue, setInputLengthValue] = useState(0);
  const maxLengthValue = 40;

  const [updateQuestion] = useUpdateQuestionMutation();

  const onSubmit = async ({value}: any) => {
    const elements = question.questionPayload?.elements ?? [];

    const updatedElements = [
      ...elements,
      {
        value,
        id: elements.length === 0 ? 0 : elements.length,
      },
    ];

    const updatedQuestion = {
      questionPayload: {...question.questionPayload, elements: updatedElements},
    };

    const result = (await updateQuestion({questionId, updatedQuestion})) as any;
    if (result.data.success) {
      toast({
        title: 'Success',
        description: 'New gap added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      history.push(baseUrl + '/parameters');
    }
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="First Column Element" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="value" color="slate.400">
                Title
              </FormLabel>

              <Input
                id="value"
                bg="blue.200"
                autoComplete="off"
                {...register('value', {
                  required: 'Value is required',
                  maxLength: {value: maxLengthValue, message: 'Too long value'},
                  onChange: (e) => setInputLengthValue(e.target.value.length),
                })}
              />
              <FormErrorMessage>{errors.value && errors.value.message}</FormErrorMessage>
              <Text>
                {inputLengthValue} of {maxLengthValue} available characters
              </Text>
            </Box>
          </FormControl>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting} mt="10">
            Save changes
          </Button>
        </Form>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
