import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const useGetFdlUrlApi = createApi({
  reducerPath: 'useGetFdlUrlApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),
  tagTypes: ['fdlUrl'],

  endpoints: (builder) => ({
    getFdlUrl: builder.mutation({
      query: ({topicId, id}) => {
        return {
          url: `/learner/jump-2-gen-url/?topic_id=${topicId}&lesson_id=${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['fdlUrl'],
    }),
  }),
});

export const {
  useGetFdlUrlMutation,
} = useGetFdlUrlApi;
