import {useHistory, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer} from '@chakra-ui/react';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const Bin = ({baseUrl, questionId, question}: any) => {
  const history = useHistory();
  const {id} = useParams<{id: string}>();

  const bin = question.questionPayload.Bins.find((elem: any) => +elem.Id === +id) || question.questionPayload.Bins[+id];

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>({
    defaultValues: {title: bin.Title},
  });

  const [updateQuestion] = useUpdateQuestionMutation();

  const onSubmit = async ({title}: any) => {
    const updatedBins = question.questionPayload.Bins.map((elem: any) =>
      +elem.Id === +id ? {Id: +id, Title: title} : elem
    );

    const updatedQuestion = {
      questionPayload: {
        Bins: updatedBins,
      },
    };
    await updateQuestion({questionId, updatedQuestion});

    history.push(baseUrl + '/parameters');
  };

  const isInvalidForm = !!errors.title;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Bin" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="title" color="slate.400">
                Title
              </FormLabel>

              <Input
                id="title"
                bg="blue.200"
                autoComplete="off"
                {...register('title', {
                  required: 'Title is required',
                })}
              />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </Box>
          </FormControl>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting} mt="10">
            Save changes
          </Button>
        </Form>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
