import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useGetQuestionByIdQuery} from 'services/questionsService';
import {Header, LoaderSpinner, setCurrentQuestionId} from 'common';
import {Menu, QuestionTypes} from './common';
import {
  AppearingCapture,
  Captcha,
  Columns,
  ImageBins,
  ImageGaps,
  ImageOrder,
  RoamingCapture,
  SentenceGaps,
  TextGaps,
  Tinder,
  Trivia,
  UQuestion,
  Wordsearch,
} from './questionsTypes';
import {useParamId} from 'utils';

export const Question = () => {
  const dispatch = useDispatch();

  const questionId = useParamId();
  const {data: question, isLoading: isLoadingQuestion} = useGetQuestionByIdQuery(questionId);

  useEffect(() => {
    dispatch(setCurrentQuestionId(questionId));
  }, []);

  if (isLoadingQuestion) return <LoaderSpinner />;

  const currentQuestionType = question.questionTypeId;

  return (
    <>
      <Header isCourse isTopic isLesson isQuestion />
      <Menu />

      {currentQuestionType === QuestionTypes.Captcha && <Captcha />}
      {currentQuestionType === QuestionTypes.RoamingCapture && <RoamingCapture />}
      {currentQuestionType === QuestionTypes.AppearingCapture && <AppearingCapture />}
      {currentQuestionType === QuestionTypes.Trivia && <Trivia />}
      {currentQuestionType === QuestionTypes.Tinder && <Tinder />}
      {currentQuestionType === QuestionTypes.ImageBins && <ImageBins />}
      {currentQuestionType === QuestionTypes.SentenceGaps && <SentenceGaps />}
      {currentQuestionType === QuestionTypes.ImageGaps && <ImageGaps />}
      {currentQuestionType === QuestionTypes.TextGaps && <TextGaps />}
      {currentQuestionType === QuestionTypes.ImageOrder && <ImageOrder />}
      {currentQuestionType === QuestionTypes.Columns && <Columns />}
      {currentQuestionType === QuestionTypes.Wordsearch && <Wordsearch />}
      {currentQuestionType === QuestionTypes.UQuestion && <UQuestion />}
    </>
  );
};
