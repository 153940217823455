import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {Menu, Header, LearnerMenu} from './components';
import {LoaderSpinner} from '../../common/LoaderSpinner';
import {useParamId} from 'utils';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import {SharedWrapper, IsErrorMessage} from 'common';
import {ReactComponent as levelIcon} from 'assets/svg/book.svg';
import {useGetLearnerQuery} from 'services/learnersService';

export const Learner = () => {
  const id = useParamId();
  const history = useHistory();

  const {
    data,
    error,
    isLoading
  } = useGetLearnerQuery(id);

  if (isLoading) {
    return <LoaderSpinner />;
  }

  return (
    <>
      <Header isAvatars={true} />
      <LearnerMenu id={id} />
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <LevelIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Learner
          </Text>
        </Flex>

        {!data && (
          <>
            <IsErrorMessage error={error} />
          </>
        )}
      </SharedWrapper>
    </>
  );
};

const LevelIcon = styled(levelIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
