import {Flex, Table, Tbody, Text, Th, Thead, Tr} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { IsErrorMessage, LoaderSpinner, SharedWrapper } from 'common';
import { useGetFeedbackListQuery } from 'services/feedbackService';
import { UserFeedbackListItem, UserFeedbackListItemModel } from './UserFeedbackListItem';
import {ReactComponent as clockIcon} from '../../assets/svg/clock.svg';
import { useHistory } from 'react-router-dom';

export const UserFeedbackList = () => {
  const history = useHistory();
  const {data, isLoading, error} = useGetFeedbackListQuery('');
  
  return (
    <>
      <SharedWrapper my="5">
        <Text fontWeight="medium" fontSize="16" color="slate.900" decoration={'underline'} onClick={() => {
          history.push('/courses');
        }}>
                              RETURN BACK
        </Text>
        <Flex mx="4" mt="4" mb="6" align="center">
          <ClockIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
                          LEVEL FEEDBACK
          </Text>
        </Flex>
  
        {isLoading && <LoaderSpinner />}
  
        <IsErrorMessage error={error} />
  
        {!isLoading && !error && data && (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>DISPLAY NAME</Th>
                  <Th>TOPIC ID</Th>
                  <Th>LESSON LEVEL</Th>
                  <Th>DATA</Th>
                  <Th>DATE CREATED</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {data.map(({id, topicId, lessonLevel, displayName, message, createdAt}: UserFeedbackListItemModel) => (
                  <UserFeedbackListItem
                    key={id}
                    id={id}
                    topicId={topicId}
                    lessonLevel={lessonLevel}
                    displayName={displayName}
                    message={message}
                    createdAt={createdAt}
                  />
                ))}
              </Tbody>
            </Table>
  
            {!data.length && (
              <Text color="slate.800" p={5}>
                                  No data yet
              </Text>
            )}
          </>
        )}
      </SharedWrapper>
    </>
  );
};
  
const ClockIcon = styled(clockIcon)`
    height: 16px;
    width: auto;
    padding-right: 5px;
  `;