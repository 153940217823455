import styled from '@emotion/styled';
import {ReactComponent as sortableIcon} from 'assets/svg/sortable.svg';

export const DeleteLink = styled.a`
  color: red;
`;

export const SortableIcon = styled(sortableIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
