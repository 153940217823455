import {useHistory} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Spacer, useToast} from '@chakra-ui/react';
import {QuestionHeader} from 'pages/question/common';
import {ImageContentInput, SharedWrapper} from 'common';
import {useCreateAnswerMutation} from 'services/questionsService';

export const NewAnswer = ({baseUrl, question, questionId}: any) => {
  const toast = useToast();
  const history = useHistory();

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    control,
    setValue,
  } = useForm<any>();

  const isInvalidForm = !!errors.answer || !!errors.imageContent;

  const [createAnswer] = useCreateAnswerMutation();

  const bins = question.questionPayload?.Bins;

  const bin = bins && bins[0]?.Id;

  const onSubmit = async (data: any) => {
    const bin =
      question.questionPayload.Bins.find((elem: any) => +elem.Id === +data.correspondingBin) ||
      question.questionPayload.Bins[data.correspondingBin];

    const createdAnswer = {
      questionId,
      orderIndex: 0,
      title: data.answer,
      assetURL: data.imageContent,
      payload: {
        Id: +data.correspondingBin,
        Title: bin.Title,
      },
    };
    const result = (await createAnswer(createdAnswer)) as any;

    if (result.data.success) {
      toast({
        title: 'Success',
        description: result.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      history.push(baseUrl + '/answers');
    }
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Answer details" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>

                <Input
                  id="questionCondition"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('answer', {
                    required: 'Answer is required',
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="correspondingBin" color="slate.400">
                  Corresponding Bin
                </FormLabel>

                <Select
                  id="correspondingBin"
                  bg="blue.200"
                  defaultValue={bin?.Id}
                  {...register('correspondingBin', {
                    required: 'Corresponding Bin is required',
                    valueAsNumber: true,
                  })}
                >
                  {question.questionPayload?.Bins?.map(({Title: title, Id: id}: any) => {
                    return (
                      <option key={id} value={id}>
                        {title}
                      </option>
                    );
                  })}
                </Select>
              </Box>

              <Box>
                <FormLabel htmlFor="imageContent" color="slate.400">
                  Image content
                </FormLabel>
                <Controller
                  name="imageContent"
                  control={control}
                  render={({field: {value}}) => (
                    <ImageContentInput value={value} inputName="imageContent" setValue={setValue} />
                  )}
                />

                <FormErrorMessage>{errors.imageContent && errors.imageContent.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
