import {ReactNode} from 'react';
import {BorderBox} from './shared.styles';
import {BoxProps} from '@chakra-ui/react';

export const SharedWrapper = ({
  children,
  ...props
}: {
  children?: ReactNode;
} & BoxProps) => {
  return (
    <BorderBox
      width="80%"
      mx="auto"
      background="white"
      border="2px solid"
      borderColor="bg"
      borderRadius="4px"
      {...props}
    >
      {children}
    </BorderBox>
  );
};
