import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

export const filesApi = createApi({
  reducerPath: 'filesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),

  endpoints: (builder) => ({
    sendImage: builder.mutation({
      query: ({file}) => {
        return {
          url: 'educator/file_processor',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: file,
        };
      },
    }),
    sendVideo: builder.mutation({
      query: ({topicId, lessonId, file}) => {
        const formData = new FormData();
        formData.append('file', file as any);
        return {
          url: `educator/file_processor?type=video&src_format=mp4&dest_format=default&cmd=merge&t=${topicId}&l=${lessonId}`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: formData,
        };
      },
    }),
    sendAudio: builder.mutation({
      query: ({topicId, lessonId, file}) => {
        const formData = new FormData();
        formData.append('file', file as any);
        return {
          url: `educator/file_processor?type=audio&src_format=mp3&dest_format=default&cmd=merge&t=${topicId}&l=${lessonId}`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: formData,
        };
      },
    }),
    sendAudioTranscription: builder.mutation({
      query: ({topicId, lessonId, file}) => {
        const formData = new FormData();
        formData.append('file', file as any);
        return {
          url: `educator/file_processor?type=audio&src_format=txt&dest_format=default&cmd=merge&t=${topicId}&l=${lessonId}`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: formData,
        };
      },
    }),
    sendVideoTranscription: builder.mutation({
      query: ({topicId, lessonId, file}) => {
        const formData = new FormData();
        formData.append('file', file as any);
        return {
          url: `educator/file_processor?type=video&src_format=txt&dest_format=default&cmd=merge&t=${topicId}&l=${lessonId}`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: formData,
        };
      },
    }),
    sendVideoSubtitles: builder.mutation({
      query: ({topicId, lessonId, file}) => {
        const formData = new FormData();
        formData.append('file', file as any);
        return {
          url: `educator/file_processor?type=video&src_format=srt&dest_format=default&cmd=merge&t=${topicId}&l=${lessonId}`,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: formData,
        };
      },
    }),
  }),
});

export const {
  useSendImageMutation,
  useSendVideoMutation,
  useSendAudioMutation,
  useSendAudioTranscriptionMutation,
  useSendVideoTranscriptionMutation,
  useSendVideoSubtitlesMutation,
} = filesApi;
