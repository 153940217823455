export const COORDS = {
  1: {answerId: 1, size: 228, locationX: 114, locationY: 114},
  2: {answerId: 2, size: 228, locationX: 342, locationY: 114},
  3: {answerId: 3, size: 228, locationX: 570, locationY: 114},
  4: {answerId: 4, size: 228, locationX: 114, locationY: 342},
  5: {answerId: 5, size: 228, locationX: 342, locationY: 342},
  6: {answerId: 6, size: 228, locationX: 570, locationY: 342},
  7: {answerId: 7, size: 228, locationX: 114, locationY: 570},
  8: {answerId: 8, size: 228, locationX: 342, locationY: 570},
  9: {answerId: 9, size: 228, locationX: 570, locationY: 570},
};

export type AnswerId = keyof typeof COORDS;
