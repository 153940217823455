import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Skeleton,
} from '@chakra-ui/react';

export const Breadcrumbs = ({
  group,
  isAvatars,
  avatar,
}: any) => {

  return (
    <Breadcrumb fontSize="sm">
      <BreadcrumbItem
        color="slate.400"
        _last={{
          fontWeight: 'medium',
          fontSize: '14',
          color: 'slate.900',
        }}
      >
        <BreadcrumbLink href={'/avatar-groups'}>Groups</BreadcrumbLink>
      </BreadcrumbItem>

      {group && (
        <BreadcrumbItem
          color="slate.400"
          _last={{
            fontWeight: 'medium',
            fontSize: '14',
            color: 'slate.900',
          }}
        >
          <BreadcrumbLink href={'/create-group/' + group.id}>
            {group.label}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {(group && isAvatars) && (
        <BreadcrumbItem
          color="slate.400"
          _last={{
            fontWeight: 'medium',
            fontSize: '14',
            color: 'slate.900',
          }}
        >
          <BreadcrumbLink href={'/create-group/' + group.id + '/avatars'}>
            Avatars
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
      {(group && avatar) && (
        <BreadcrumbItem
          color="slate.400"
          _last={{
            fontWeight: 'medium',
            fontSize: '14',
            color: 'slate.900',
          }}
        >
          <BreadcrumbLink href={'/create-group/' + group.id + '/avatars/' + avatar.id}>
            {avatar.label}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
};
