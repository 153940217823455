import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import {RouteProps} from 'react-router';

import {Login, Register} from 'pages/auth';
import {Courses} from 'pages/courses';
import {
  AvatarGroups,
  AvatarGroupCreate,
  AvatarGroupEdit,
  Avatars,
  AvatarCreate,
  AvatarEdit,
} from 'pages/avatar-groups';
import {
  Notifications,
  NotificationCreate,
  NotificationEdit,
  NotificationsCreate,
} from 'pages/notifications';
import {
  Learners,
  Learner
} from 'pages/learners';
import {Course, NewCourse} from 'pages/course';
import {Topic} from 'pages/topic';
import {Lesson} from 'pages/lesson';
import {NewQuestion, Question} from 'pages/question';
import { UserFeedbackList } from 'pages/feedback/UserFeedbackList';

export const Routes = () => {
  const RouteIfNotAuth = (props: RouteProps) => {
    if (localStorage.getItem('token')) return <Redirect to={'/courses'} />;

    return <Route {...props} />;
  };

  const RouteIfAuth = (props: RouteProps) => {
    if (!localStorage.getItem('token')) return <Redirect to={'/login'} />;

    return <Route {...props} />;
  };

  const Logout = () => {
    localStorage.removeItem('token');

    return <Redirect to={'/login'} />;
  };

  const RedirectToHome = () => {
    if (localStorage.getItem('token')) return <Redirect to={'/courses'} />;

    return <Redirect to={'/login'} />;
  };

  return (
    <Router>
      <Switch>
        <RouteIfNotAuth exact path={'/login'} component={Login} />
        <RouteIfNotAuth exact path={'/register'} component={Register} />

        <Route exact path={'/logout'} render={Logout} />

        <RouteIfAuth exact path={'/courses'} component={Courses} />
        <RouteIfAuth exact path={'/courses-archived'} render={() => <Courses isArchived />} />

        <RouteIfAuth exact path={'/level-feedback'} render={() => <UserFeedbackList />} />

        <RouteIfAuth path={'/course/:id'} component={Course} />
        <RouteIfAuth exact path={'/create-course'} component={NewCourse} />

        <RouteIfAuth path={'/topic/:id'} component={Topic} />

        <Route exact path={'/lesson/:id/new-question'} component={NewQuestion} />
        <RouteIfAuth path={'/lesson/:id'} component={Lesson} />

        <RouteIfAuth path={'/question/:id'} component={Question} />

        <RouteIfAuth exact path={'/avatar-groups'} component={AvatarGroups} />
        <RouteIfAuth exact path={'/create-group'} component={AvatarGroupCreate} />
        <RouteIfAuth exact path={'/create-group/:id'} component={AvatarGroupEdit} />

        <RouteIfAuth exact path={'/create-group/:id/avatars'} component={Avatars} />
        <RouteIfAuth exact path={'/create-group/:id/create-avatar'} component={AvatarCreate} />
        <RouteIfAuth exact path={'/create-group/:id/create-avatar/:avatarId'} component={AvatarEdit} />

        <RouteIfAuth exact path={'/learners'} component={Learners} />
        <RouteIfAuth exact path={'/learners/:id'} component={Learner} />
        <RouteIfAuth exact path={'/learners/:id/notifications'} component={Notifications} />
        <RouteIfAuth
          exact
          path={'/learners/:learnerId/create-notification/:notificationId'}
          component={NotificationEdit} />
        <RouteIfAuth
          exact
          path={'/learners/:id/create-notification'}
          component={NotificationCreate} />
        <RouteIfAuth exact path={'/create-notification'} component={NotificationsCreate} />

        <Route path={'/'} render={RedirectToHome} />
      </Switch>
    </Router>
  );
};
