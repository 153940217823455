import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer, useToast} from '@chakra-ui/react';
import {useCreateAnswerMutation} from 'services/questionsService';
import {SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const NewAnswer = ({baseUrl, questionId}: any) => {
  const toast = useToast();
  const history = useHistory();

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>();

  const [createAnswer] = useCreateAnswerMutation();

  const onSubmit = async (data: any) => {
    const createdAnswer = {
      title: data.answer, // .toUpperCase(),
      questionId,
    };
    const result = (await createAnswer(createdAnswer)) as any;

    if (result.data.success) {
      toast({
        title: 'Success',
        description: result.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      history.push(baseUrl + '/answers');
    }
  };

  const isInvalidForm = !!errors.answer;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Answer details" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>

                <Input
                  id="answer"
                  bg="blue.200"
                  // textTransform="uppercase"
                  autoComplete="off"
                  {...register('answer', {
                    required: 'Answer is required',
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
