import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spacer,
  Text,
  useToast,
} from '@chakra-ui/react';
import {useCreateAnswerMutation, useGetQuestionByIdQuery} from 'services/questionsService';
import {SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const NewAnswer = ({baseUrl, questionId}: any) => {
  const toast = useToast();
  const history = useHistory();

  const {data: question} = useGetQuestionByIdQuery(questionId);

  const [inputLengthValue, setInputLengthValue] = useState(0);
  const maxLengthValue = 40;

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>();

  const [createAnswer] = useCreateAnswerMutation();

  const onSubmit = async (data: any) => {
    const createdAnswer = {
      questionId,
      title: data.answer,
      payload: {firstColumnElementId: data.element},
    };
    const result = (await createAnswer(createdAnswer)) as any;

    if (result.data.success) {
      toast({
        title: 'New answer added',
        description: result.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      history.push(baseUrl + '/answers');
    }
  };
  const isInvalidForm = !!errors.answer || !!errors.questionType;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Answer details" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>

                <Input
                  id="answer"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('answer', {
                    required: 'Answer is required',
                    maxLength: {value: maxLengthValue, message: 'Too long answer'},
                    onChange: (e) => setInputLengthValue(e.target.value.length),
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
                <Text>
                  {inputLengthValue} of {maxLengthValue} available characters
                </Text>
              </Box>

              <Box>
                <FormLabel htmlFor="element" color="slate.400">
                  corresponding element
                </FormLabel>

                <Select
                  id="element"
                  bg="blue.200"
                  {...register('element', {
                    required: 'Question Type is required',
                    valueAsNumber: true,
                  })}
                >
                  {question.questionPayload.elements.map(({id, value}: any) => {
                    return (
                      <option key={id} value={id}>
                        {value}
                      </option>
                    );
                  })}
                </Select>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
