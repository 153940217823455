import { useCallback, useEffect, useState } from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {
  useGetLessonByIdQuery,
  useUpdateDialoguePhraseOrderMutation,
  useUpdateLessonMutation
} from 'services/lessonsService';
import {LoaderSpinner, SharedWrapper} from 'common';
import {AudioPathInput, AudioTranscriptInput, VideoPathInput, VideoSubInput, VideoTranscriptInput} from './components';
import {ReactComponent as theoryIcon} from 'assets/svg/tablet.svg';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates, verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { useDebounce } from 'use-debounce';
import { DialogueItem } from './components/Dialogues/DialogueItem';
import * as R from 'ramda';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, ContentState, convertFromHTML } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';

import 'draft-js/dist/Draft.css';
import { stateToHTML } from 'draft-js-export-html';
import { Editor } from '@tinymce/tinymce-react';

export const Theory = ({lessonId, baseUrl}: any) => {
  const history = useHistory();
  const {data: lesson, isLoading} = useGetLessonByIdQuery(lessonId);
  const [items, setItems] = useState<any>(null);
  // const [editorState, setEditorState] = useState(
  //   () => EditorState.createWithContent(ContentState.createFromText('Hello')),
  // );
  const [htmlContent, setHtmlContent] = useState('');
  const [value] = useDebounce(items, 200);

  const [updateLesson] = useUpdateLessonMutation();
  const [updateDialoguePhraseOrder] = useUpdateDialoguePhraseOrderMutation();


  let sortedData: any[] = [];
  if (!isLoading && lesson) {
    if (lesson.dialogues.length > 0) {
      sortedData = lesson.dialogues && [...lesson.dialogues].sort((a: any, b: any) => a.order - b.order);
    }
  }



  const updateOrder = useCallback(
    (items, value) => {
      if (items) {
        const isChanged = !R.equals(items, sortedData);
        // console.log(sortedData);
        if (value && isChanged) {
          // const orderCombination = value.reduce(
          //   (acc: any, item: any, idx: number) => [...acc, {keyFactId: item.id, orderIndex: idx}],
          //   []
          // );
          // console.log(value);
          updateDialoguePhraseOrder({lessonId, updatedOrder: {value}});
        }
      }
    },
    [items, value]
  );

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<any>();


  useEffect(() => {
    if (!isLoading && lesson) {
      const {articleObject, audioObject, videoObject} = lesson;
      
      setValue('articleBody', articleObject.articleBody);
      setValue('articleSource', articleObject.source);
      setValue('articleAuthor', articleObject.author);
      setValue('audioPath', audioObject.audioPath);
      setValue('audioTranscription', audioObject.transcriptionPath);
      setValue('audioSource', audioObject.source);
      setValue('audioAuthor', audioObject.author);
      setValue('videoPath', videoObject.videoPath);
      setValue('videoSubtitles', videoObject.subtitlesPath);
      setValue('videoTranscription', videoObject.transcriptionPath);
      setValue('videoSource', videoObject.source);
      setValue('videoAuthor', videoObject.author);

      setValue('lessonTypeId', lesson.lessonTypeId ?? 1);
      if (!Object.prototype.hasOwnProperty.call(articleObject, 'articleBodyHtml')) {
        if (articleObject.articleBody === null) {
          // setEditorState(() => EditorState.createWithContent(ContentState.createFromText('')));
          setHtmlContent('<p>This is the initial content of the editor</p>');
        } else {
          // setEditorState(() => EditorState.createWithContent(ContentState.createFromText(articleObject.articleBody)));
          setHtmlContent(articleObject.articleBody);
        }
        
      } else {
        if (articleObject.articleBodyHtml !== null) {
          setValue('articleBodyHtml', articleObject.articleBodyHtml);
          // const blocksFromHTML = convertFromHTML(articleObject.articleBodyHtml);
          // setEditorState(() => EditorState.createWithContent(ContentState.createFromBlockArray(
          //   blocksFromHTML.contentBlocks,
          //   blocksFromHTML.entityMap,
          // )));
          setHtmlContent(articleObject.articleBodyHtml);
        }
      }
    }
  }, [lesson]);


  /*
  *
  * */
  useEffect(() => {
    if (!isLoading && lesson) {
      if (lesson.dialogues.length > 0) {
        // const sortedData = lesson.dialogues && [...lesson.dialogues].sort((a: any, b: any) => a.order - b.order);
        setItems(sortedData);
      }
    }

    return () => {
      setItems(null);
    };
  }, [isLoading, lesson]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    updateOrder(items, value);

    return () => {
      updateOrder(items, value);
    };
  }, [value]);


  const submitHandler = async (data: any) => {
    const {
      articleBody,
      articleAuthor,
      articleSource,
      audioPath,
      audioTranscription,
      audioSource,
      audioAuthor,
      videoPath,
      videoTranscription,
      videoSubtitles,
      videoSource,
      videoAuthor,
      lessonTypeId,
    } = data;

    const updatedLesson = {
      audioObject: {
        author: audioAuthor,
        audioPath: typeof audioPath === 'undefined' ? null : audioPath,
        source: audioSource,
        transcriptionPath: audioTranscription,
      },
      videoObject: {
        author: videoAuthor,
        source: videoSource,
        videoPath,
        subtitlesPath: videoSubtitles,
        transcriptionPath: videoTranscription,
      },
      articleObject: {
        author: articleAuthor, 
        source: articleSource, 
        articleBody: htmlContent.replace(/<\/?[^>]+(>|$)/g, ''), // stateToHTML(editorState.getCurrentContent()).replace(/<\/?[^>]+(>|$)/g, ''),
        articleBodyHtml: htmlContent, // stateToHTML(editorState.getCurrentContent()),
      },
      lessonTypeId,
    };

    await updateLesson({lessonId, updatedLesson});
  };

  const createDialogueHandler = () => {
    history.push(baseUrl + '/new-dialogue');
  };

  const deleteHandler = async (id: any) => {
    const updatedDialogues = lesson.dialogues.filter((elem: any) => +elem.id !== +id);
    const updatedLesson = {dialogues: updatedDialogues};

    await updateLesson({lessonId, updatedLesson});
  };


  const isInvalidForm = false;

  const lessonTypes = [
    {value: 1, type: 'Article'},
    {value: 2, type: 'Video'},
    {value: 3, type: 'Audio'},
    {value: 4, type: 'Dialogue'},
  ];

  if (isLoading) return <LoaderSpinner />;

  return (
    <>
      <SharedWrapper p="10">
        <Flex align="center" justify="space-between">
          <Flex align="center" mb="5">
            <TheoryIcon />

            <Text fontWeight="medium" fontSize="16" color="slate.900">
              Course details
            </Text>
          </Flex>
        </Flex>

        <Box>
          <Form onSubmit={handleSubmit(submitHandler)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="articleBody" color="slate.400">
                  Article
                </FormLabel>
                {/* <Textarea id="articleBody" bg="blue.200" minHeight="200px" {...register('articleBody')} /> */}
                {/* <Editor
                  editorState={editorState}
                  // onChange={setEditorState}
                  toolbarClassName="toolbarClassName"
                  // toolbar={}
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={(i) => {
                    setEditorState(i);
                    // console.log(stateToHTML(i.getCurrentContent()));
                    // console.log(stateToHTML(i.getCurrentContent()).replace(/<\/?[^>]+(>|$)/g, ''));
                  }}
                /> */}
                <Editor
                  apiKey='3wt5m7l8vjy61jh8f0cjyczk4rv4cvh3lagjlhcwu5g6lvet'
                  value={htmlContent}
                  init={{
                    // plugins: 'link image code',
                    // toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                    plugins: [
                      'advlist', 'autolink', 'lists', 'link', 'image', 'charmap',
                      'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                      'insertdatetime', 'media', 'table', 'preview', 'help', 'wordcount'
                    ],
                    toolbar: 'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | help',
                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
          
                  }}
                  
                  onEditorChange={(i) => {
                    setHtmlContent(i);
                    // setHtmlContent(i.target.getContent());
                  }}
                />
                <FormErrorMessage>{errors.articleBody && errors.articleBody.message}</FormErrorMessage>
              </Box>

              <Flex justify="space-between">
                <Box flexBasis="45%">
                  <FormLabel htmlFor="articleSource" color="slate.400">
                    Article (source)
                  </FormLabel>
                  <Input id="articleSource" bg="blue.200" {...register('articleSource')} />
                  <FormErrorMessage>{errors.articleSource && errors.articleSource.message}</FormErrorMessage>
                </Box>

                <Box flexBasis="45%">
                  <FormLabel htmlFor="articleAuthor" color="slate.400">
                    Article (author)
                  </FormLabel>
                  <Input id="articleAuthor" bg="blue.200" {...register('articleAuthor')} />
                  <FormErrorMessage>{errors.articleAuthor && errors.articleAuthor.message}</FormErrorMessage>
                </Box>
              </Flex>

              <Divider orientation="horizontal" width="100%" height="5" my="5" />

              <Box>
                <Box>
                  <FormLabel htmlFor="audioPath" color="slate.400">
                    Audio
                  </FormLabel>
                  <Controller
                    name="audioPath"
                    control={control}
                    render={({field: {value}}: any) => (
                      <AudioPathInput
                        value={value}
                        setValue={setValue}
                        mediaPath={lesson.audioObject.audioPath}
                        lessonId={lesson.id}
                        topicId={lesson.topicId}
                        onChange={submitHandler}
                      />
                    )}
                  />
                  <FormErrorMessage>{errors.audioPath && errors.audioPath.message}</FormErrorMessage>
                </Box>

                <Box>
                  <FormLabel htmlFor="audioTranscription" color="slate.400">
                    Audio (transription)
                  </FormLabel>
                  <Controller
                    name="audioTranscription"
                    control={control}
                    render={({field: {value}}: any) => (
                      <AudioTranscriptInput
                        value={value}
                        setValue={setValue}
                        mediaPath={lesson.audioObject.transcriptionPath}
                        lessonId={lesson.id}
                        topicId={lesson.topicId}
                        onChange={submitHandler}
                      />
                    )}
                  />
                  <FormErrorMessage>{errors.audioTranscription && errors.audioTranscription.message}</FormErrorMessage>
                </Box>

                <Flex justify="space-between">
                  <Box flexBasis="45%">
                    <FormLabel htmlFor="audioSource" color="slate.400">
                      Audio (source)
                    </FormLabel>
                    <Input id="audioSource" bg="blue.200" {...register('audioSource')} />
                    <FormErrorMessage>{errors.audioSource && errors.audioSource.message}</FormErrorMessage>
                  </Box>

                  <Box flexBasis="45%">
                    <FormLabel htmlFor="audioAuthor" color="slate.400">
                      Audio (author)
                    </FormLabel>
                    <Input id="audioAuthor" bg="blue.200" {...register('audioAuthor')} />
                    <FormErrorMessage>{errors.audioAuthor && errors.audioAuthor.message}</FormErrorMessage>
                  </Box>
                </Flex>
              </Box>

              <Divider orientation="horizontal" width="100%" height="5" my="{id}5" />

              <Box>
                <FormLabel htmlFor="videoPath" color="slate.400">
                  Video
                </FormLabel>
                <Controller
                  name="videoPath"
                  control={control}
                  render={({field: {value}}: any) => (
                    <VideoPathInput
                      value={value}
                      setValue={setValue}
                      onChange={submitHandler}
                      mediaPath={lesson.videoObject.videoPath}
                      lessonId={lesson.id}
                      topicId={lesson.topicId}
                    />
                  )}
                />

                <FormErrorMessage>{errors.videoPath && errors.videoPath.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="videoTranscription" color="slate.400">
                  Video (transcription)
                </FormLabel>
                <Controller
                  name="videoTranscription"
                  control={control}
                  render={({field: {value}}: any) => (
                    <VideoTranscriptInput
                      value={value}
                      setValue={setValue}
                      onChange={submitHandler}
                      mediaPath={lesson.videoObject.transcriptionPath}
                      lessonId={lesson.id}
                      topicId={lesson.topicId}
                    />
                  )}
                />

                <FormErrorMessage>{errors.videoTranscription && errors.videoTranscription.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="videoSubtitles" color="slate.400">
                  Video (subtitles)
                </FormLabel>
                <Controller
                  name="videoSubtitles"
                  control={control}
                  render={({field: {value}}: any) => (
                    <VideoSubInput
                      value={value}
                      setValue={setValue}
                      onChange={submitHandler}
                      mediaPath={lesson.videoObject.subtitlesPath}
                      lessonId={lesson.id}
                      topicId={lesson.topicId}
                    />
                  )}
                />
                <FormErrorMessage>{errors.videoSub && errors.videoSub.message}</FormErrorMessage>
              </Box>

              <Flex justify="space-between">
                <Box flexBasis="45%">
                  <FormLabel htmlFor="videoSource" color="slate.400">
                    Video (source)
                  </FormLabel>
                  <Input id="videoSource" bg="blue.200" {...register('videoSource')} />
                  <FormErrorMessage>{errors.videoSource && errors.videoSource.message}</FormErrorMessage>
                </Box>

                <Box flexBasis="45%">
                  <FormLabel htmlFor="videoAuthor" color="slate.400">
                    Video (author)
                  </FormLabel>
                  <Input id="videoAuthor" bg="blue.200" {...register('videoAuthor')} />
                  <FormErrorMessage>{errors.videoAuthor && errors.videoAuthor.message}</FormErrorMessage>
                </Box>
              </Flex>
            </FormControl>

            <Spacer />

            <Box>
              <FormLabel htmlFor="lessonTypeId" color="slate.400">
                Lesson type
              </FormLabel>
              <Select
                id="lessonTypeId"
                bg="blue.200"
                {...register('lessonTypeId', {
                  required: 'Lesson type is required',
                  valueAsNumber: true,
                })}
              >
                {lessonTypes.map(({type, value}: any) => {
                  return (
                    <option key={value} value={value}>
                      {type}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>{errors.statusTypeId && errors.statusTypeId.message}</FormErrorMessage>
            </Box>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>

      <SharedWrapper my="10" p="10" id="#dialogues">
        <Flex align="center" justify="space-between">
          <Flex align="center" mb="5">
            <TheoryIcon />

            <Text fontWeight="medium" fontSize="16" color="slate.900">
              Dialogue
            </Text>
          </Flex>
        </Flex>

        <Box mb="5">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th></Th>
                <Th>Phrase</Th>
                <Th>Speaker name</Th>
                <Th />
              </Tr>
            </Thead>
            <Tbody>
              {!isLoading && items && (
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={items}
                    strategy={verticalListSortingStrategy}
                  >
                    {/*{lesson.dialogues?.map(({phrase, name, id}: any) => (*/}
                    {items?.map(({phrase, name, id}: any) => (
                      <DialogueItem key={id} phrase={phrase} name={name} id={id} baseUrl={baseUrl} lessonId={lessonId} />
                    ))}
                  </SortableContext>
                </DndContext>)}
            </Tbody>
          </Table>
        </Box>

        <Button onClick={createDialogueHandler}>Create new dialogue</Button>
      </SharedWrapper>
    </>
  );
  function handleDragEnd(event: any) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items: any) => {
        const oldIndex = items.findIndex(({id}: any) => id === active.id);
        const newIndex = items.findIndex(({id}: any) => id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
};


const TheoryIcon = styled(theoryIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;

