import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import styled from '@emotion/styled';
import {useParamId} from 'utils';
import {Menu, Header} from './components';
import {LoaderSpinner} from '../../common/LoaderSpinner';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Td,
  Thead,
  Tr
} from '@chakra-ui/react';
import {SharedWrapper, IsErrorMessage} from 'common';
import {ReactComponent as levelIcon} from 'assets/svg/book.svg';
import {
  useGetNotificationsQuery,
} from 'services/notificationsService';
import {NotificationProps} from 'models';

export const Notifications = () => {
  const id = useParamId();
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [page, setPage] = useState(
    query.get('page') !== null
      ? query.get('page') : 1
  );

  const {
    data,
    error,
    isLoading
  } = useGetNotificationsQuery({page, learnerId: id});

  useEffect(() => {
    window.scrollTo(0, 0);

    return;
  }, [page]);

  if (isLoading) {
    return <LoaderSpinner />;
  }

  const createNotificationHandler = () => {
    history.push('/learners/' + String(id) + '/create-notification');
  };

  const editNotificationHandler = (event: any, notificationId: number) => {
    history.push('/learners/' + String(id) + '/create-notification/' + String(notificationId));
  };
  
  const deleteNotificationHandler = (event: any, notificationId: number) => {
    return;
  };

  const nextPage = () => {
    const nextPageNumber = Number(page) + 1;

    setPage(nextPageNumber);
    history.push('/learners/' + String(id) + '/notifications?page=' + String(nextPageNumber));
  };

  const prevPage = () => {
    const prevPageNumber = Number(page) - 1;

    if (prevPageNumber > 0) {
      setPage(prevPageNumber);
      history.push('/learners/' + String(id) + '/notifications?page=' + String(prevPageNumber));
    }
  };

  const getRawNumber = (index: number): number => {
    const realIndex = index + 1;
    const itemsCount = data.data.length;
    const pageNumber = Number(page);

    return realIndex + ((pageNumber * itemsCount) - itemsCount);
  };

  return (
    <>
      <Header isAvatars={true} />
      <Menu />
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <LevelIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Notifications
          </Text>
        </Flex>

        {!data && (
          <>
            <IsErrorMessage error={error} />
          </>
        )}

        {data && (
          <>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>Method</Th>
                  <Th>Type</Th>
                  <Th>Status</Th>
                  <Th>Created At</Th>
                  <Th>Read At</Th>
                  {/* <Th />
                  <Th /> */}
                </Tr>
              </Thead>

              <Tbody>
                {data.data.map((nitification: NotificationProps, index: number) => {
                  return (
                    <Tr key={nitification.id}>
                      <Td>{getRawNumber(index)}</Td>
                      <Td>{nitification.method}</Td>
                      <Td>{nitification.status}</Td>
                      <Td>{nitification.type}</Td>
                      <Td>{nitification.created_at}</Td>
                      <Td>{nitification.read_at}</Td>
                      {/* <Td>
                      <ButtonGroup>
                        <Button
                          size="sm"
                          variant="secondary"
                          onClick={(event: any) => { editNotificationHandler(event, nitification.id); }}>
                            Edit
                        </Button>
                      </ButtonGroup>
                    </Td>
                    <Td>
                      <ButtonGroup>
                        <Button
                          size="sm"
                          variant="secondaryWarning"
                          onClick={(event: any) => { deleteNotificationHandler(event, nitification.id); }}>
                            Delete
                        </Button>
                      </ButtonGroup>
                    </Td> */}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </>
        )}
        <Box m="4">
          <ButtonGroup>
            <Button onClick={prevPage}>Prev Page</Button>
            <Button onClick={nextPage}>Next Page</Button>
          </ButtonGroup><br /><br />
          <ButtonGroup>
            <Button onClick={createNotificationHandler}>Send new notification</Button>
          </ButtonGroup>
        </Box>
      </SharedWrapper>
    </>
  );
};

const LevelIcon = styled(levelIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
