import {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {Controller, useForm} from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useGetExplanationTypesQuery, useUpdateQuestionMutation} from 'services/questionsService';
import {ImageContentInput, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const Parameters = ({question, questionId}: any) => {
  const {data, isLoading} = useGetExplanationTypesQuery('');
  const explanationTypes = data?.filter(({type}: any) => type === 'common');

  const [isBackgroundColor, setBackgroundColor] = useState(Boolean(question.questionPayload?.BackgroundColor));
  const backgroundColorHandler = () => setBackgroundColor((prev) => !prev);

  const [movementSpeed, setMovementSpeed] = useState(question.questionPayload?.ObjectMovementSpeed ?? 3);
  const [generatingSpeed, setGeneratingSpeed] = useState(question.questionPayload?.ObjectGeneratingSpeed ?? 2);

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    setValue,
    register,
  } = useForm<any>();

  const [updateParameters] = useUpdateQuestionMutation();

  const onSubmit = async ({backgroundColor, background}: any) => {
    await updateParameters({
      questionId,
      updatedQuestion: {
        questionPayload: {
          BackgroundColor: isBackgroundColor ? backgroundColor : null,
          BackgroundImage: background,
          ObjectGeneratingSpeed: generatingSpeed,
          ObjectMovementSpeed: movementSpeed,
        },
      },
    });
  };

  const defaultBackgroundColor = question.questionPayload?.BackgroundColor;
  const defaultBackgroundImage = question.questionPayload?.BackgroundImage;

  useEffect(() => {
    if (defaultBackgroundColor) {
      setValue('backgroundColor', defaultBackgroundColor);
    }

    if (defaultBackgroundImage) {
      setValue('background', defaultBackgroundImage);
    }
  }, []);

  const editObjectMovementSpeed = async () => {
    const value = prompt('ObjectMovementSpeed') as string;
    !Number.isInteger(+value) ? setMovementSpeed(0) : setMovementSpeed(+value);
    try {
      updateParameters({
        questionId,
        updatedQuestion: {
          questionPayload: {
            ...question.questionPayload,
            ObjectMovementSpeed: movementSpeed,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const editObjectGeneratingSpeed = () => {
    const value = prompt('ObjectGeneratingSpeed') as string;
    !Number.isInteger(+value) ? setGeneratingSpeed(0) : setGeneratingSpeed(+value);
    try {
      updateParameters({
        questionId,
        updatedQuestion: {
          questionPayload: {
            ...question.questionPayload,
            ObjectGeneratingSpeed: generatingSpeed,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const isInvalidForm = !!errors.backgroundColor || !!errors.backgroundImage;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Explanations" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th>Case</Th>
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              explanationTypes.map(({explanation_type_description, title}: any) => (
                <Tr key={title}>
                  <Td>{explanation_type_description}</Td>
                  <Td>{title}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Constants" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Constants</Th>
              <Th>Value</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>ObjectGeneratingSpeed</Td>
              <Td>{generatingSpeed}</Td>
              <Td>
                <Flex justify="end">
                  <ButtonEdit onClick={editObjectGeneratingSpeed}>Edit</ButtonEdit>
                </Flex>
              </Td>
            </Tr>

            <Tr>
              <Td>ObjectMovementSpeed</Td>
              <Td>{movementSpeed}</Td>
              <Td>
                <Flex justify="end">
                  <ButtonEdit onClick={editObjectMovementSpeed}>Edit</ButtonEdit>
                </Flex>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Other" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <Flex justify="space-between" align="baseline">
                  <FormLabel htmlFor="backgroundColor" color="slate.400">
                    Background Color
                  </FormLabel>

                  <Switch id="isTimeLimit" isChecked={isBackgroundColor} onChange={backgroundColorHandler} />
                </Flex>

                <Input
                  id="backgroundColor"
                  bg="blue.200"
                  autoComplete="off"
                  disabled={!isBackgroundColor}
                  {...register('backgroundColor', {
                    required: isBackgroundColor ? 'Background color is required' : false,
                  })}
                />
                <FormErrorMessage>{errors.backgroundColor && errors.backgroundColor.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="background" color="slate.400">
                  Background Image
                </FormLabel>
                <Controller
                  name="background"
                  control={control}
                  render={({field: {value}}) => (
                    <ImageContentInput
                      inputName="background"
                      setValue={setValue}
                      value={value}
                      imgLink={defaultBackgroundImage}
                    />
                  )}
                />
                <FormErrorMessage>{errors.background && errors.background.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Button type="submit" isLoading={isSubmitting} mt="4">
              Save
            </Button>
          </Form>
        </Box>
      </SharedWrapper>

      <Box h="10" />
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;

const ButtonEdit = styled(Button)``;
