import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spacer,
  Switch,
  useToast,
} from '@chakra-ui/react';
import {LoaderSpinner, SharedWrapper} from 'common';
import {useGetAnswerIdQuery, useUpdateAnswerMutation} from 'services/questionsService';
import {QuestionHeader} from 'pages/question/common';
import {useParamId} from 'utils';
import {useHistory} from 'react-router-dom';

export const Answer = ({question, baseUrl}: any) => {
  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,

    setValue,
  } = useForm<any>();

  const id = useParamId();
  const toast = useToast();
  const history = useHistory();

  const {data: answer, isLoading} = useGetAnswerIdQuery(id);
  const [updateAnswer] = useUpdateAnswerMutation();

  const [isCorrect, setIsCorrect] = useState(false);

  useEffect(() => {
    if (!isLoading && answer) {
      setValue('answer', answer.title);
      setValue('imageContent', answer.assetURL);
      setValue('order', answer.payload?.Order);
      setIsCorrect(!!answer.isCorrect);
    }
  }, [answer]);

  const onSubmit = async (data: any) => {
    const order = question.questionPayload.find(({Order}: any) => +Order === +data.order);

    const updatedAnswer = {
      title: data.answer,
      payload: order,
      isCorrect,
    };
    const result = (await updateAnswer({answerId: id, updatedAnswer})) as any;

    if (result.data.success) {
      toast({
        title: 'Answer edited',
        description: result.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      history.push(baseUrl + '/answers');
    }
  };

  const isCorrectHandler = () => setIsCorrect((prev) => !prev);

  if (isLoading) return <LoaderSpinner />;

  const isInvalidForm = !!errors.answer || !!errors.imageContent;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title={'Answer details'} />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>

                <Input
                  id="answer"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('answer', {
                    required: 'Answer is required',
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="order" color="slate.400">
                  Order
                </FormLabel>

                <Select
                  id="order"
                  bg="blue.200"
                  {...register('order', {
                    required: 'order is required',
                    valueAsNumber: true,
                  })}
                >
                  {question &&
                    question.questionPayload.map(({Order}: any) => {
                      return (
                        <option key={Order} value={Order}>
                          {Order}
                        </option>
                      );
                    })}
                </Select>
              </Box>

              <Box>
                <Flex justify="space-between" align="baseline">
                  <FormLabel htmlFor="isCorrect" color="slate.400">
                    Is correct?
                  </FormLabel>

                  <Switch id="isCorrect" isChecked={isCorrect} onChange={isCorrectHandler} />
                </Flex>

                <FormErrorMessage>{errors.isCorrect && errors.isCorrect.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
