import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {NewUser, User} from 'models';

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),

  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (user: NewUser) => {
        return {
          url: '/educator/auth/register',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: user,
        };
      },
    }),
    authUser: builder.mutation({
      query: (user: User) => {
        return {
          url: '/educator/auth/login',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: user,
        };
      },
    }),
    authMe: builder.query({
      query: () => {
        return {
          method: 'GET',
          url: process.env.REACT_APP_BASE_URL + '/educator/account/me',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),

    logoutUser: builder.mutation({
      query: () => {
        return {
          url: '/educator/account/log-out',
          method: 'DELETE',
        };
      },
    }),
  }),
});

export const {
  useCreateUserMutation,
  useAuthUserMutation,
  useLogoutUserMutation,
  useAuthMeQuery,
} = authApi;
