import {
  useGetAvatarsQuery,
  useGetGroupQuery,
} from 'services/avatarService';
import {MenuEdit, AvatarsList, Header} from './components';
import {LoaderSpinner} from 'common';
import {useParamId} from 'utils';

export const Avatars = () => {
  const id = useParamId();

  const {
    data,
    error,
    isLoading
  } = useGetAvatarsQuery(id);

  const {
    data: group,
    error: groupError,
    isLoading: isLoadingGroup
  } = useGetGroupQuery(id);

  if (isLoading || isLoadingGroup) {
    return <LoaderSpinner />;
  }

  return (
    <>
      <Header isAvatars={true} group={group} />
      <MenuEdit id={id} isParent={id} />

      {!isLoading && !error && data && (
        <AvatarsList avatars={data} group={group} error={error} groupError={groupError} />
      )}
    </>
  );
};
