import styled from '@emotion/styled';
import {useForm} from 'react-hook-form';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Table,
  Tbody,
  Text,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useGetExplanationTypesQuery, useUpdateQuestionMutation} from 'services/questionsService';
import {SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const Parameters = ({question, questionId}: any) => {
  const {data, isLoading} = useGetExplanationTypesQuery('');
  const explanationTypes = data?.filter(({type}: any) => type === 'common');
  const [updateSentence] = useUpdateQuestionMutation();

  const {originalSentenceText, sentence} = question.questionPayload || '';

  const onSubmit = async ({originalSentenceText}: any) => {
    await updateSentence({
      questionId,
      updatedQuestion: {
        questionPayload: {originalSentenceText},
      },
    });
  };

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>({
    defaultValues: {originalSentenceText},
  });

  const isInvalidForm = !!errors.originalSentenceText;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Explanations" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th>Case</Th>
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              explanationTypes.map(({explanation_type_description, title}: any) => (
                <Tr key={title}>
                  <Td>{explanation_type_description}</Td>
                  <Td>{title}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Sentence" />
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="originalSentenceText" color="slate.400">
                Original sentence
              </FormLabel>

              <Input
                id="originalSentenceText"
                bg="blue.200"
                autoComplete="off"
                {...register('originalSentenceText', {
                  required: 'Sentence is required',
                })}
              />
              <FormErrorMessage>{errors.originalSentenceText && errors.originalSentenceText.message}</FormErrorMessage>
            </Box>
          </FormControl>

          <Spacer />

          <Box>
            <FormLabel color="slate.400">Sentence with gaps</FormLabel>
            <Text>{sentence}</Text>
          </Box>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting} mt="10">
            Save changes
          </Button>
        </Form>
      </SharedWrapper>

      <Box h="10" />
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
