import { useHistory } from 'react-router-dom';
import { Box, Button, ButtonGroup, Flex, Td, Tr } from '@chakra-ui/react';
import {
  useArchiveQuestionMutation,
  useGetQuestionsTypesQuery,
  useUnArchiveQuestionMutation
} from 'services/questionsService';
import { getDateTimeFromStr } from 'utils';
import { useSortable } from '@dnd-kit/sortable';
import styled from '@emotion/styled';
import { ReactComponent as sortableIcon } from '../../../../assets/svg/sortable.svg';
import { CSS } from '@dnd-kit/utilities';
import { useEffect } from 'react';

export const QuestionItem = ({
  id,
  questionCondition,
  questionTypeId,
  createdAt,
  isArchived
}: any) => {
  const history = useHistory();
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const { data: questionsTypes } = useGetQuestionsTypesQuery('');
  const questionType =
    questionsTypes && questionsTypes[questionTypeId - 1]?.question_type_name;
  const createdAtDate = getDateTimeFromStr(createdAt);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };
  useEffect(() => {
    return () => {
      setNodeRef(null);
    };
  }, []);

  const editHandler = () => {
    history.push(`/question/${id}`);
  };

  return (
    // <Box style={style} {...attributes} w="100%" px="4" my="1">
    //   <Flex align="center" w="100%" border="2px solid" p="2" borderColor="blue.200">
    //   </Flex>
    // </Box>
    <Tr style={style} {...attributes} w="100%" px="4" my="1">
      <Td>
        <Box ref={setNodeRef} {...listeners} flexBasis="10%">
          <SortableIcon />
        </Box>
      </Td>
      <Td>{id}</Td>
      <Td>{questionCondition}</Td>
      <Td>{questionType}</Td>
      <Td>{createdAtDate}</Td>
      <Td>
        <Flex justify="flex-end">
          <ButtonGroup>
            <Button size="sm" variant="secondary" onClick={editHandler}>
              Edit
            </Button>
            {isArchived ? (
              <UnarchiveButton id={id} />
            ) : (
              <ArchiveButton id={id} />
            )}
          </ButtonGroup>
        </Flex>
      </Td>
    </Tr>
  );
};

const ArchiveButton = ({ id }: { id: number }) => {
  const [archiveQuestion] = useArchiveQuestionMutation();

  const archiveHandler = async () => {
    await archiveQuestion(id);
  };

  return (
    <Button size="sm" variant="secondaryWarning" onClick={archiveHandler}>
      Archive
    </Button>
  );
};
const UnarchiveButton = ({ id }: { id: number }) => {
  const [unarchiveQuestion] = useUnArchiveQuestionMutation();

  const unarchiveHandler = async () => {
    await unarchiveQuestion(id);
  };

  return (
    <Button size="sm" variant="secondaryWarning" onClick={unarchiveHandler}>
      Unarchive
    </Button>
  );
};

const SortableIcon = styled(sortableIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
