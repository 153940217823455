import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  fetchCourseData,
  fetchLessonData,
  fetchQuestionData,
  fetchTopicData,
  getCurrentCourseId,
  getCurrentLessonId,
  getCurrentQuestionId,
  getCurrentTopicId,
} from 'common/SharedHeader/navigationSlice';

export const UpdateNavigation = () => {
  const dispatch = useDispatch();

  const currentTopicId = useSelector(getCurrentTopicId);
  const currentCourseId = useSelector(getCurrentCourseId);
  const currentLessonId = useSelector(getCurrentLessonId);
  const currentQuestionId = useSelector(getCurrentQuestionId);

  useEffect(() => {
    if (currentCourseId) dispatch(fetchCourseData(currentCourseId));
  }, [currentCourseId]);

  useEffect(() => {
    if (currentTopicId) dispatch(fetchTopicData(currentTopicId));
  }, [currentTopicId]);

  useEffect(() => {
    if (currentLessonId) dispatch(fetchLessonData(currentLessonId));
  }, [currentLessonId]);

  useEffect(() => {
    if (currentQuestionId) dispatch(fetchQuestionData(currentQuestionId));
  }, [currentQuestionId]);

  return <></>;
};
