import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from '@chakra-ui/react';
import {SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {AnswerId, COORDS} from './constants';

export const NewGap = ({questionId, question}: any) => {
  const [updateQuestion] = useUpdateQuestionMutation();

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    reset,
  } = useForm<any>();

  const isInvalidForm = !!errors.locationX || !!errors.locationY || !!errors.order || !!errors.size;

  const gaps = question.questionPayload;
  const toast = useToast();

  const onSubmit = async ({answerId}: {answerId: AnswerId}) => {
    const newOrderElem = COORDS[answerId];

    const lastElemOrder = (gaps && gaps.slice(-1)[0]?.Order) ?? 0;

    const newElemUpperCased = {
      Size: newOrderElem.size,
      LocationX: newOrderElem.locationX,
      LocationY: newOrderElem.locationY,
      Order: lastElemOrder + 1,
    };

    const updatedGaps = gaps ? [...gaps, newElemUpperCased] : [newElemUpperCased];

    const updatedQuestion = {
      questionPayload: updatedGaps,
    };

    const result = (await updateQuestion({questionId, updatedQuestion})) as any;

    if (result.data.success)
      toast({
        title: 'Success',
        description: 'New gap added',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    reset();
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Coordinates constants" />
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Answer Id</Th>
                <Th>Size</Th>
                <Th>Location X</Th>
                <Th>Location Y</Th>
              </Tr>
            </Thead>

            <Tbody>
              {Object.values(COORDS).map((coord) => {
                const {size, locationX, locationY, answerId} = coord;

                return (
                  <Tr key={answerId}>
                    <Td>{answerId}</Td>
                    <Td>{size}</Td>
                    <Td>{locationX}</Td>
                    <Td>{locationY}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Answer ID" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="answerId" color="slate.400">
                Answer ID
              </FormLabel>

              <Select
                id="answerId"
                bg="blue.200"
                {...register('answerId', {
                  required: 'answerId is required',
                  valueAsNumber: true,
                })}
              >
                {Object.keys(COORDS).map((answerId) => {
                  return (
                    <option key={answerId} value={answerId}>
                      Answer: {answerId}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>{errors.answerId && errors.answerId.message}</FormErrorMessage>
            </Box>
          </FormControl>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting} mt="10">
            Create
          </Button>
        </Form>
      </SharedWrapper>

      <Box h="10" />
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
