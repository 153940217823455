import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {CSS} from '@dnd-kit/utilities';
import {useSortable} from '@dnd-kit/sortable';
import { Box, Button, Flex, Td, Text, Tr } from '@chakra-ui/react';
import {ReactComponent as sortableIcon} from 'assets/svg/sortable.svg';
import { useDeleteDialoguePhraseMutation } from '../../../../../../services/lessonsService';

export const DialogueItem = ({id, order, phrase, icon, name, baseUrl, lessonId}: any) => {
  const history = useHistory();
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [deleteDialoguePhrase] = useDeleteDialoguePhraseMutation();

  useEffect(() => {
    return () => {
      setNodeRef(null);
    };
  }, []);


  const deleteHandler = async (phraseId: string | number) => {
    // const updatedDialogues = lesson.dialogues.filter((elem: any) => +elem.id !== +id);
    // const updatedLesson = {dialogues: updatedDialogues};

    // await updateLesson({lessonId, updatedLesson});
    await deleteDialoguePhrase({ lessonId, phraseId });
  };

  return (
    <Tr style={style} {...attributes}>
      <Td>
        <Flex align="center" w="100%" border="2px solid" p="2" borderColor="blue.200">
          <Box ref={setNodeRef} {...listeners} flexBasis="10%">
            <SortableIcon />
          </Box>
        </Flex>
      </Td>
      <Td>
        <Text flexBasis="70%">{phrase}</Text>
      </Td>
      <Td>{name}</Td>
      <Td>
        <Flex justify="flex-end">
          <EditButton id={id} baseUrl={baseUrl} />
          <Button size="sm" variant="secondaryWarning" onClick={() => deleteHandler(id)}>
              Delete
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
};

const EditButton = ({id, baseUrl}: any) => {
  const history = useHistory();

  const editHandler = () => {
    history.push(`${baseUrl}/dialogue/${id}`);
  };

  return (
    <Button size="sm" onClick={editHandler} mr="3">
      Edit
    </Button>
  );
};

const SortableIcon = styled(sortableIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
