import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {SharedWrapper} from 'common';
import {useCreateLessonMutation} from 'services/lessonsService';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Text,
  Textarea,
} from '@chakra-ui/react';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';
import {useParamId} from 'utils';

export const NewLesson = ({location}: any) => {
  const history = useHistory();
  const {level} = location.state;

  const [createLesson] = useCreateLessonMutation();
  const topicId = useParamId();

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>();

  const onSubmit = async (data: any) => {
    const {title, description} = data;

    const newLesson = {topicId, lessonTypeId: 1, level, title, description};

    const result = await createLesson({newLesson});
    const resultData = {result} as any;

    history.push(`/lesson/${resultData.result.data.id}`);
  };

  const isInvalidForm = !!errors.title || !!errors.description;

  return (
    <>
      <SharedWrapper p="10">
        <Flex align="center" mb="5">
          <DetailsIcon />

          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Lesson details
          </Text>
        </Flex>

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="title" color="slate.400">
                  Title
                </FormLabel>

                <Input
                  id="title"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('title', {
                    required: 'Title is required',
                  })}
                />
                <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="description" color="slate.400">
                  Description
                </FormLabel>
                <Textarea id="description" bg="blue.200" autoComplete="off" {...register('description')} />
                <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
