import {useHistory, useRouteMatch} from 'react-router-dom';
import {Box, Button, Flex, Table, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import {useDeleteAnswerMutation, useGetAnswersByQuestionIdQuery} from 'services/questionsService';
import {SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const Answers = ({questionId, baseUrl}: any) => {
  const history = useHistory();

  const {data: answers, isLoading} = useGetAnswersByQuestionIdQuery(questionId);

  const createAnswerHandler = () => {
    history.push(`${baseUrl}/new-answer`);
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title={'Answers'} />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Answer</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              answers.map(({title, id}: any) => (
                <Tr key={id}>
                  <Td>{title}</Td>
                  <Td>
                    <Flex justify="flex-end">
                      <EditButton id={id} />
                      <DeleteButton id={id} />
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box width="80%" mx="auto" mt="20px" mb="40px">
        <Button onClick={createAnswerHandler}>Create new answer</Button>
      </Box>
    </>
  );
};

const EditButton = ({id}: any) => {
  const history = useHistory();
  const {url} = useRouteMatch();

  const editHandler = () => {
    history.push(`${url}/${id}`);
  };

  return (
    <Button size="sm" onClick={editHandler} mr="3">
      Edit
    </Button>
  );
};
const DeleteButton = ({id}: any) => {
  const [deleteAnswer] = useDeleteAnswerMutation();

  const deleteHandler = () => {
    deleteAnswer({id});
  };

  return (
    <Button size="sm" variant="secondaryWarning" onClick={deleteHandler}>
      Delete
    </Button>
  );
};
