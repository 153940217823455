import axios from 'axios';
import {ChangeEvent, useEffect, useRef, useState} from 'react';
import {
  Box,
  Button,
  Flex,
  Link,
  Image,
  Text,
  useToast,
  ButtonGroup,
  FormLabel,
  Input,
  FormErrorMessage,
  Switch,
} from '@chakra-ui/react';
import {getFilenameFromInput, stopPropagation, valueIsImage} from 'utils';

type ImageContentProps = {
  imgLink?: string;
  inputName?: string;
  setValue: (name: any, value: string | null, config?: Record<string, boolean>) => void;
  value?: string;
};

export const ImageContentInput = ({setValue, inputName, value}: ImageContentProps) => {
  const toast = useToast();
  const inputRef = useRef<HTMLInputElement>(null);
  const [imgName, setImgName] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const [w, setW] = useState(0);
  const [isAlpha, setAlpha] = useState(false);

  useEffect(() => {
    if (value) {
      setImgName(value);
    } else {
      setImgName('');
    }
  }, [value]);

  const inputHandler = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const deleteHandler = () => {
    setValue(inputName, null);
    setImgName('');
  };

  const uploadHanlder = async (event: any) => {
    if (event.target.files) {
      const newImg: File = event.target.files[0];

      if (valueIsImage(newImg)) {
        setSubmitting(true);
        const formData = new FormData();
        formData.append('file', newImg as any);
        formData.append('w', w.toString() || '0');
        formData.append('isAlpha', isAlpha.toString());

        const result = (await axios({
          method: 'POST',
          url: process.env.REACT_APP_BASE_URL + '/educator/upload-image',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: formData,
        })) as any;

        if (result.data.success) {
          setSubmitting(false);
          setValue(inputName, result.data.path);
          setImgName(result.data.path);
          
          toast({
            title: 'Image uploaded',
            status: 'success',
            duration: 2000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: 'Something went wrong',
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box my="2">
      <Flex justify="space-between" align="center">
        <Image
          boxSize="100px"
          objectFit="contain"
          fallbackSrc="https://via.placeholder.com/100"
          src={imgName}
          alt={imgName}
        />

        <Flex justify="center" align="center" mb="10">
          <Box mr="5">
            <Box>
              <FormLabel htmlFor="width" color="slate.400">
                Width (px)
              </FormLabel>
              <Input
                id="width"
                bg="blue.200"
                type="number"
                autoComplete="off"
                value={w}
                onChange={(e: any) => setW(e.target.value)}
              />
            </Box>
          </Box>

          <Box>
            <Flex justify="space-between" align="flex-end">
              <FormLabel htmlFor="isTransparent" color="slate.400">
                Is transparent?
              </FormLabel>

              <Switch id="isTransparent" isChecked={isAlpha} onChange={() => setAlpha((prev) => !prev)} />
            </Flex>
          </Box>
        </Flex>

        <ButtonGroup>
          <Button onClick={inputHandler} isLoading={isSubmitting}>
            Upload
          </Button>
          {imgName && (
            <Button onClick={deleteHandler} variant="secondaryWarning" isLoading={isSubmitting}>
              Delete
            </Button>
          )}
        </ButtonGroup>
      </Flex>

      <input type="file" ref={inputRef} id="icon" name="icon" onChange={uploadHanlder} style={{display: 'none'}} />
    </Box>
  );
};
