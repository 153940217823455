import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {Box, Button, Flex, Table, Tbody, Text, Th, Thead, Tr} from '@chakra-ui/react';
import {useGetTopicsByCourseIdQuery} from 'services/topicsService';
import {IsErrorMessage, LoaderSpinner, SharedWrapper} from 'common';
import {TopicsItem} from './TopicsItem';
import {TopicProps} from 'models';
import {ReactComponent as topicsIcon} from 'assets/svg/clock.svg';

type TopicsProps = {id: string; isArchived?: boolean};

export const Topics = ({isArchived, id}: TopicsProps) => {
  const history = useHistory();

  const {data, isLoading, error} = useGetTopicsByCourseIdQuery({
    id,
    isArchived,
  });

  const handleClick = () => {
    history.push(`/course/${id}/create-topic`);
  };

  return (
    <>
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <TopicsIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            {isArchived ? 'Archived' : 'Topics'}
          </Text>
        </Flex>

        {isLoading && <LoaderSpinner />}

        <IsErrorMessage error={error} />

        {!isLoading && !error && data && (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>ID</Th>
                  <Th>TITLE</Th>
                  <Th>LESSONS</Th>
                  <Th>IS PRO</Th>
                  <Th>GROUP</Th>
                  <Th>CREATED</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {data.map(({id, title, createdAt, lessonCount, isProContent, groupType}: TopicProps) => (
                  <TopicsItem
                    isProContent={isProContent}
                    groupType={groupType}
                    key={id}
                    createdAt={createdAt}
                    id={id}
                    lessonCount={lessonCount}
                    title={title}
                    isArchived={isArchived}
                  />
                ))}
              </Tbody>
            </Table>
            {!data.length && (
              <Text color="slate.800" p={5}>
                No {isArchived ? 'archived' : 'created'} topics
              </Text>
            )}
          </>
        )}
      </SharedWrapper>

      <Box width="80%" mx="auto" mb="20">
        <Button onClick={handleClick}>Create new topic</Button>
      </Box>
    </>
  );
};

const TopicsIcon = styled(topicsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
