import {Route, useRouteMatch} from 'react-router-dom';
import {Details, Dialogue, KeyFact, KeyFacts, Menu, NewDialogue, NewKeyFact, Questions, Theory} from './components';
import {Header} from 'common';
import {useGetLessonByIdQuery} from 'services/lessonsService';
import {useParamId} from 'utils';

export const Lesson = () => {
  const id = useParamId();
  const {path, url} = useRouteMatch();

  const {data: lesson} = useGetLessonByIdQuery(id);

  return (
    <>
      <Header isCourse isTopic isLesson />
      <Menu />
      <Route exact path={path} render={() => <Details id={id} />} />

      <Route exact path={path + '/keyfacts'} render={() => <KeyFacts lessonId={id} baseUrl={url} />} />

      <Route exact path={path + '/keyfact/:id'} render={() => <KeyFact lessonId={id} />} />

      <Route exact path={path + '/create-keyfact'} render={() => <NewKeyFact lessonId={id} baseUrl={url} />} />

      <Route exact path={path + '/theory'} render={() => <Theory lessonId={id} baseUrl={url} />} />

      <Route exact path={path + '/questions'} render={() => <Questions lessonId={id} baseUrl={url} />} />

      <Route
        exact
        path={path + '/dialogue/:id'}
        render={() => <Dialogue lessonId={id} baseUrl={url} lesson={lesson} />}
      />

      <Route
        exact
        path={path + '/new-dialogue'}
        render={() => <NewDialogue lessonId={id} baseUrl={url} lesson={lesson} />}
      />

      <Route
        exact
        path={path + '/questions-archived'}
        render={() => <Questions lessonId={id} baseUrl={url} isArchived />}
      />
    </>
  );
};
