import {ReactComponent as Menu} from 'assets/svg/menu.svg';

export const MenuIcon = ({isActive}: any) => {
  return (
    <>
      <Menu style={{
        width: '3%'
      }} />
    </>
  );
};
