import {useRouteMatch} from 'react-router-dom';
import {SharedMenu} from 'common';

const links = [
  {
    title: 'Question Details',
    value: '',
  },
  {
    title: 'Parameters',
    value: '/parameters',
  },
  {
    title: 'answers',
    value: '/answers',
  },
];

export const Menu = () => {
  const {url} = useRouteMatch();

  return <SharedMenu baseUrl={url} separatorAfterElement={2} links={links} />;
};
