import {useEffect, useState} from 'react';
import {valueIsNumber} from 'utils';
import styled from '@emotion/styled';
import {Controller, useForm} from 'react-hook-form';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {QuestionHeader} from 'pages/question/common';
import {ImageContentInput, SharedWrapper} from 'common';
import {useGetExplanationTypesQuery, useUpdateQuestionMutation} from 'services/questionsService';

export const Parameters = ({question, questionId}: any) => {
  const {data, isLoading} = useGetExplanationTypesQuery('');
  const explanationTypes = data?.filter(({type}: any) => type === 'common');

  const [isBackgroundColor, setBackgroundColor] = useState(Boolean(question.questionPayload?.BackgroundColor));
  const backgroundColorHandler = () => setBackgroundColor((prev) => !prev);

  const [dissapearingSpeed, setDissapearingSpeed] = useState(
    (question.questionPayload?.ObjectDissapearingSpeed || question.questionPayload?.ObjectDisapearingSpeed) ?? 3
  );
  const [generatingFrequency, setGeneratingFrequency] = useState(
    question.questionPayload?.ObjectGeneratingFrequency ?? 2
  );

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    setValue,
    register,
  } = useForm<any>();

  const [updateParameters] = useUpdateQuestionMutation();

  const onSubmit = async ({backgroundColor, background}: any) => {
    await updateParameters({
      questionId,
      updatedQuestion: {
        questionPayload: {
          BackgroundColor: isBackgroundColor ? backgroundColor : null,
          BackgroundImage: background,
          ObjectDisapearingSpeed: dissapearingSpeed,
          ObjectGeneratingFrequency: generatingFrequency,
        },
      },
    });
  };

  const defaultBackgroundColor = question.questionPayload?.BackgroundColor;
  const defaultBackgroundImage = question.questionPayload?.BackgroundImage;

  useEffect(() => {
    if (defaultBackgroundColor) {
      setValue('backgroundColor', defaultBackgroundColor);
    }

    if (defaultBackgroundImage) {
      setValue('background', defaultBackgroundImage);
    }
  }, []);

  const editObjectDisapearingSpeed = () => {
    const value = prompt('ObjectDisapearingSpeed') as string;
    setDissapearingSpeed(valueIsNumber(value));
    try {
      updateParameters({
        questionId,
        updatedQuestion: {
          questionPayload: {
            ...question.questionPayload,
            ObjectDisapearingSpeed: dissapearingSpeed,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const editObjectGeneratingFrequency = () => {
    const value = prompt('ObjectGeneratingFrequency') as string;
    setGeneratingFrequency(valueIsNumber(value));
    try {
      updateParameters({
        questionId,
        updatedQuestion: {
          questionPayload: {
            ...question.questionPayload,
            ObjectGeneratingFrequency: generatingFrequency,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Explanations" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th>Case</Th>
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              explanationTypes.map(({explanation_type_description, title}: any) => (
                <Tr key={title}>
                  <Td>{explanation_type_description}</Td>
                  <Td>{title}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>
      <Box h="10" />
      <SharedWrapper p="10">
        <QuestionHeader title="Constants" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Constants</Th>
              <Th>Value</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>ObjectDisapearingSpeed</Td>
              <Td>{dissapearingSpeed}</Td>
              <Td>
                <Flex justify="end">
                  <ButtonEdit onClick={editObjectDisapearingSpeed}>Edit</ButtonEdit>
                </Flex>
              </Td>
            </Tr>

            <Tr>
              <Td>ObjectGeneratingFrequency</Td>
              <Td>{generatingFrequency}</Td>
              <Td>
                <Flex justify="end">
                  <ButtonEdit onClick={editObjectGeneratingFrequency}>Edit</ButtonEdit>
                </Flex>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Other" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl>
            <Box>
              <Flex justify="space-between" align="baseline">
                <FormLabel htmlFor="backgroundColor" color="slate.400">
                  Background Color
                </FormLabel>

                <Switch id="isTimeLimit" isChecked={isBackgroundColor} onChange={backgroundColorHandler} />
              </Flex>

              <Input
                id="backgroundColor"
                bg="blue.200"
                autoComplete="off"
                disabled={!isBackgroundColor}
                {...register('backgroundColor', {
                  required: isBackgroundColor ? 'Background color is required' : false,
                })}
              />
              <FormErrorMessage>{errors.backgroundColor && errors.backgroundColor.message}</FormErrorMessage>
            </Box>

            <Box>
              <FormLabel htmlFor="background" color="slate.400">
                Background Image
              </FormLabel>
              <Controller
                name="background"
                control={control}
                render={({field: {value}}) => (
                  <ImageContentInput
                    value={value}
                    inputName="background"
                    setValue={setValue}
                    imgLink={defaultBackgroundImage}
                  />
                )}
              />

              <FormErrorMessage>{errors.background && errors.background.message}</FormErrorMessage>
            </Box>
          </FormControl>

          <Button type="submit" mt="4" isLoading={isSubmitting}>
            Save
          </Button>
        </Form>
      </SharedWrapper>

      <Box h="10" />
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;

const ButtonEdit = styled(Button)``;
