import {useParams} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Text,
  Spacer,
} from '@chakra-ui/react';
import {
  useGetGroupQuery,
  useGetAvatarQuery,
  useUpdateAvatarMutation,
} from 'services/avatarService';
import {MenuEdit, Header} from './components';
import {IsErrorMessage, LoaderSpinner, SharedWrapper, ImageContentInput} from 'common';
import {AvatarProps} from 'models';
import {ReactComponent as bookIcon} from 'assets/svg/book.svg';

export const AvatarEdit = () => {
  const {id, avatarId} = useParams<{id: string; avatarId : string;}>();
  const {
    data: avatarData,
    error: avatarError,
    isLoading: isAvatarLoading
  } = useGetAvatarQuery({groupId: id, avatarId});
  const {data: group, error: groupError, isLoading: isLoadingGroup} = useGetGroupQuery(id);
  const [updateAvatar, {data: createdAvatar, isSuccess}] = useUpdateAvatarMutation();

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<AvatarProps>();

  const onSubmit = async (avatarData: AvatarProps) => {
    await updateAvatar({avatarData, groupId: id, avatarId});
  };

  const isInvalidForm = !!errors.label;

  if (isAvatarLoading || isLoadingGroup) return <LoaderSpinner />;

  return (
    <>
      <Header isAvatars={true} group={group} avatar={avatarData} />
      <MenuEdit id={id} isParent={id} />

      <SharedWrapper my="40px" p="40px">
        <Flex mx="4" mt="4" mb="6" align="center">
          <BookIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Update Avatar
          </Text>
        </Flex>

        <IsErrorMessage error={avatarError} />
        <IsErrorMessage error={groupError} />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="label" color="slate.400">
                    Label
                </FormLabel>

                <Input
                  id="label"
                  bg="blue.200"
                  {...register('label', {
                    required: 'Label is required',
                  })}
                  defaultValue={avatarData.label}
                />
                <FormErrorMessage>
                  {errors.label && errors.label.message}
                </FormErrorMessage>
              </Box>
              <Box>
                <FormLabel htmlFor="thumbnail_url" color="slate.400">
                    Thumbnail
                </FormLabel>
                <Controller
                  name="thumbnail_url"
                  control={control}
                  defaultValue={avatarData.thumbnail_url}
                  render={({field: {value}}) => (
                    <ImageContentInput value={value} inputName="thumbnail_url" setValue={setValue} />
                  )}
                />

                <FormErrorMessage>{errors.thumbnail_url && errors.thumbnail_url.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
                Update
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const BookIcon = styled(bookIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
