import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer, useToast} from '@chakra-ui/react';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const NewOrder = ({questionId, question}: any) => {
  const toast = useToast();
  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    reset,
  } = useForm<any>();
  const [updateQuestion] = useUpdateQuestionMutation();

  const isInvalidForm = !!errors.order;

  const imgOrders = question.questionPayload?.imgOrders ?? [];

  const onSubmit = async ({order}: any) => {
    const isOrderExist = Boolean(imgOrders.find(({Order}: any) => +Order === +order));

    if (!isOrderExist) {
      const newOrder = {Order: +order};
      const updatedOrders = [...imgOrders, newOrder];
      const updatedQuestion = {
        // questionPayload: {...question.questionPayload, imgOrders: updatedOrders},
        questionPayload: {
          leftText: question.questionPayload.leftText,
          imgOrders: updatedOrders,
          rightText: question.questionPayload.rightText
        }
      };
      const result = (await updateQuestion({questionId, updatedQuestion})) as any;
      if (result.data !== null)
        toast({
          title: 'Success',
          description: 'Order created',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      reset();
    } else {
      toast({
        title: 'Error',
        description: 'Order already exists',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Order" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="order" color="slate.400">
                Order
              </FormLabel>

              <Input
                id="order"
                bg="blue.200"
                type="number"
                autoComplete="off"
                {...register('order', {
                  required: 'order is required',
                  valueAsNumber: true,
                })}
              />
              <FormErrorMessage>{errors.order && errors.order.message}</FormErrorMessage>
            </Box>
          </FormControl>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting} mt="10">
            Save changes
          </Button>
        </Form>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
