import {useHistory, useLocation} from 'react-router-dom';
import {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {Menu, Header} from './components';
import {LoaderSpinner} from '../../common/LoaderSpinner';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Grid,
  GridItem,
  Table,
  Tbody,
  Text,
  Th,
  Td,
  Thead,
  Tr,
  Input
} from '@chakra-ui/react';
import {SharedWrapper, IsErrorMessage} from 'common';
import {ReactComponent as levelIcon} from 'assets/svg/book.svg';
import {useGetLearnersQuery} from 'services/learnersService';
import {LearnerProps} from 'models';

export const Learners = () => {
  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const [page, setPage] = useState(
    Number(query.get('page'))
      ? query.get('page') : 1
  );
  const [lastPageNumber, setLastPageNumber] = useState(null);
  const [searchQueryString, setSearchQueryString] = useState(
    query.get('search')
      ? query.get('search') : ''
  );

  const {
    data,
    error,
    isLoading
  } = useGetLearnersQuery({page, search: searchQueryString});

  useEffect(() => {
    window.scrollTo(0, 0);

    return;
  }, [page]);

  useEffect(() => {
    const meta = data?.meta;

    if (meta) {
      const {pagination} = meta;
      const metaLastPage = pagination.pages;
  
      setLastPageNumber(metaLastPage);
    }
    return;
  }, [data]);

  if (isLoading) {
    return <LoaderSpinner />;
  }

  const openLearnerHandler = (event: any, id: number) => {
    history.push('/learners/' + String(id));
  };

  const nextPage = () => {
    const nextPageNumber = Number(page) + 1;

    if (nextPageNumber <= Number(lastPageNumber)) {
      setPage(nextPageNumber);
      history.push('/learners' + buildQueryString(String(nextPageNumber), String(searchQueryString)));
    }
  };

  const prevPage = () => {
    const prevPageNumber = Number(page) - 1;

    if (prevPageNumber > 0) {
      setPage(prevPageNumber);
      history.push('/learners' + buildQueryString(String(prevPageNumber), String(searchQueryString)));
    }
  };

  const lastPage = () => {
    if (lastPageNumber !== null && Number(lastPageNumber) > 0) {
      setPage(lastPageNumber);
      history.push('/learners' + buildQueryString(String(lastPageNumber), String(searchQueryString)));
    }
  };

  const firstPage = () => {
    const firstPage = 1;

    setPage(firstPage);
    history.push('/learners' + buildQueryString(String(firstPage), String(searchQueryString)));
  };

  const onChangeSearchInput = (event: any) => {
    if (event.key === 'Enter') {
      const value = event.currentTarget?.value;
      const firstPage = 1;

      if (value && String(value).length > 0) {
        setSearchQueryString(value);

        if (page !== firstPage) {
          setPage(firstPage);
        }

        history.push('/learners' + buildQueryString(String(firstPage), String(value)));
      } else {
        setSearchQueryString('');
        history.push('/learners' + buildQueryString(String(firstPage), ''));
      }
    }
  };

  const onChangePage = (event: any) => {
    if (event.key === 'Enter') {
      const value = Number(event.currentTarget?.value);

      if (value > 0 && value <= Number(lastPageNumber)) {
        setPage(value);
        history.push('/learners' + buildQueryString(String(value), String(searchQueryString)));
      }
    }
  };

  const buildQueryString = (page: string | null, search: string | null): string => {
    const queryParams = [];

    if (search !== '' && search !== null) {
      queryParams.push('search=' + search);
    }

    if (page !== '' && page !== null) {
      queryParams.push('page=' + page);
    }

    return '?' + String(queryParams.join('&'));
  };

  const createNotificationHandler = () => {
    history.push('/create-notification');
  };

  const getRawNumber = (index: number): number => {
    const realIndex = index + 1;
    const itemsCount = data.data.length;
    const pageNumber = Number(page);

    return realIndex + ((pageNumber * itemsCount) - itemsCount);
  };

  return (
    <>
      <Header isAvatars={true} />
      <Menu />
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <LevelIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Learners
          </Text>
        </Flex>
        <Grid templateColumns='repeat(2, 1fr)' gap={2} p="10px">
          <GridItem w='100%'>
            <Box>
              <ButtonGroup>
                <Button onClick={createNotificationHandler}>Send new notifications</Button>
              </ButtonGroup>
            </Box>
          </GridItem>
          <GridItem w='100%'>
            <Box>
              <Box>
                <Input
                  id="search"
                  bg="blue.200"
                  placeholder="Search..."
                  height="46px"
                  onKeyDown={onChangeSearchInput}
                  defaultValue={String(searchQueryString)}
                />
              </Box>
            </Box>
          </GridItem>
        </Grid>

        {!data && (
          <>
            <IsErrorMessage error={error} />
          </>
        )}

        {data && (
          <>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>#</Th>
                  <Th>ID</Th>
                  <Th>Learner Name / ID</Th>
                  <Th>Created At</Th>
                  <Th />
                </Tr>
              </Thead>

              <Tbody>
                {data.data.map((learner: LearnerProps, index: number) => {
                  return (
                    <Tr key={learner.id}>
                      <Td>{getRawNumber(index)}</Td>
                      <Td>{learner.id}</Td>
                      <Td>{learner.display_name}</Td>
                      <Td>{learner.created_at}</Td>
                      <Td>
                        <ButtonGroup>
                          <Button
                            size="sm"
                            variant="secondary"
                            onClick={(event: any) => { openLearnerHandler(event, learner.id); }}>
                          Show
                          </Button>
                        </ButtonGroup>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </>
        )}

        <Box m="4">
          <Grid templateColumns='repeat(2, 1fr)' gap={2} p="10px">
            <GridItem w='100%'>
              <ButtonGroup>
                <Button onClick={prevPage}>Prev Page</Button>
                <Input
                  id="page"
                  bg="blue.200"
                  placeholder="Search..."
                  height="46px"
                  width="60px"
                  type="number"
                  onKeyDown={onChangePage}
                  defaultValue={String(page)}
                />
                <Button onClick={nextPage}>Next Page</Button>
              </ButtonGroup>
            </GridItem>
            <GridItem w='100%'>
              <ButtonGroup>
                <Button onClick={firstPage}>First Page</Button>
                <Button onClick={lastPage}>Last Page</Button>
              </ButtonGroup>
            </GridItem>
          </Grid>
        </Box>
      </SharedWrapper>
    </>
  );
};

const LevelIcon = styled(levelIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
