import {Link as RouterLink, useLocation} from 'react-router-dom';
import {Link, Text} from '@chakra-ui/react';

type NavItemProps = {
  title: string;
  link: string;
};

export const MenuItem = ({title, link}: NavItemProps) => {
  const {pathname} = useLocation();
  const active = pathname === link;

  return (
    <Link
      as={RouterLink}
      to={link}
      color={active ? 'blue.700' : 'slate.400'}
      _hover={{color: active ? 'blue.700' : 'slate.800'}}
      role="group"
    >
      <Text
        display="inline-block"
        fontWeight="500"
        fontSize="16"
        textTransform="uppercase"
        position="relative"
        _after={
          active
            ? {
              content: '""',
              bg: 'blue.700',
              position: 'absolute',
              w: '100%',
              h: '2px',
              left: '0',
              bottom: '-2px',
            }
            : {}
        }
      >
        {title}
      </Text>
    </Link>
  );
};
