import { Td, Tr } from '@chakra-ui/react';

export interface UserFeedbackListItemModel {
    id: number;
    displayName: string;
    topicId: number;
    lessonLevel: number;
    message: string;
    createdAt: string;
}

export const UserFeedbackListItem = ({
  id,
  displayName,
  topicId,
  lessonLevel,
  message,
  createdAt,
}: UserFeedbackListItemModel) => {
  
  return (
    <Tr>
      <Td>
        {id} 
      </Td>
      <Td>
        {displayName} 
      </Td>
      <Td>
        {topicId} 
      </Td>
      <Td>
        {lessonLevel} 
      </Td>
      <Td>
        <p>
          {message} 
        </p>
      </Td>
      <Td>
        {createdAt} 
      </Td>
    </Tr>
  );
};
  