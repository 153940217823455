import {createStandaloneToast} from '@chakra-ui/react';
import {isRejectedWithValue, Middleware} from '@reduxjs/toolkit';
import {mainTheme} from 'theme';

const toast = createStandaloneToast({theme: mainTheme});

export const rtkQueryErrorLogger: Middleware = () => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    toast({
      title: 'Error!',
      description: action.payload.data.error,
      status: 'error',
    });
  }

  return next(action);
};
