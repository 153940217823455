import {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {useHistory} from 'react-router-dom';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Box, Button, ButtonGroup, createStandaloneToast, Flex, Td, Tr} from '@chakra-ui/react';
import {useArchiveLessonMutation, useUnArchiveLessonMutation} from 'services/lessonsService';
import {getDateFromStr} from 'utils';
import {ReactComponent as sortableIcon} from 'assets/svg/sortable.svg';
import {useGetFdlUrlMutation} from '../../../../services/getFldUrlService';
import {mainTheme} from '../../../../theme';

export interface ILessonItem {
    title: string;
    createdAt: string;
    questionCount: number;
    id: string | number | any;
    isArchived?: boolean;
    orderIndex: number;
    topicId: number;
}

export const LessonItem = ({
  title,
  createdAt,
  questionCount,
  id,
  isArchived,
  topicId,
}: ILessonItem) => {
  const history = useHistory();
  const {attributes, listeners, setNodeRef, transform, transition} =
        useSortable({id});

  const toast = createStandaloneToast({theme: mainTheme});

  const [getFdlUrl] = useGetFdlUrlMutation({});
  const [fdlUrl, setFdlUrl] = useState(null);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  useEffect(() => {
    return () => {
      setNodeRef(null);
    };
  }, []);

  const editHandler = () => {
    history.push(`/lesson/${id}`);
  };

  const getFdlUrlHandler = async () => {
    const fldUrlResult: any = (await getFdlUrl({topicId, id}));
    setFdlUrl(fldUrlResult.data.fdlUrl);
    toast({
      title: 'Done!',
      description: 'Fdl URL generated: click to copy',
      status: 'success',
    });
  };

  const copyToCB = (fdlUrl: string) => {
    navigator.clipboard.writeText(fdlUrl).then();
    toast({
      title: ':)',
      description: 'Successfully copied to clipboard',
      status: 'success',
    });
  };

  return (
    <Tr style={style} {...attributes}>
      <Td>
        <Flex ref={setNodeRef} {...listeners}>
          <Box>
            <SortableIcon/>
          </Box>
          {title}
        </Flex>
      </Td>
      <Td>{id}</Td>
      <Td>{questionCount}</Td>
      <Td>{getDateFromStr(createdAt)}</Td>
      <Td>
        <Flex justify="flex-end" flexBasis="20%">
          <ButtonGroup>
            {fdlUrl === null ? (
              <Button size="sm" variant="secondary" onClick={getFdlUrlHandler}>
                      GET FDL
              </Button>
            ) : (
              <Button size="sm" variant="secondary" onClick={() => {
                copyToCB(fdlUrl);
              }}>
                      COPY FDL
              </Button>
            )}

            <Button size="sm" variant="secondary" onClick={editHandler}>
                            Edit
            </Button>
            {isArchived ? (
              <UnarchiveButton id={id}/>
            ) : (
              <ArchiveButton id={id}/>
            )}
          </ButtonGroup>
        </Flex>
      </Td>
    </Tr>
  );
};

const ArchiveButton = ({id}: { id: number }) => {
  const [archiveLesson] = useArchiveLessonMutation();

  const archiveHandler = async () => {
    await archiveLesson(id);
  };

  return (
    <Button size="sm" variant="secondaryWarning" onClick={archiveHandler}>
            Archive
    </Button>
  );
};
const UnarchiveButton = ({id}: { id: number }) => {
  const [unarchiveLesson] = useUnArchiveLessonMutation();

  const unarchiveHandler = async () => {
    await unarchiveLesson(id);
  };

  return (
    <Button size="sm" variant="secondaryWarning" onClick={unarchiveHandler}>
            Unarchive
    </Button>
  );
};

const SortableIcon = styled(sortableIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
