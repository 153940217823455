import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {Controller, useForm} from 'react-hook-form';
import {CreatableSelect} from 'chakra-react-select';
import styled from '@emotion/styled';
import {
  Box,
  Button, ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer, Switch,
  Text,
  Textarea, useToast
} from '@chakra-ui/react';
import {
  useCreateBVersionMutation,
  useGetTopicByIdQuery,
  useGetTopicsTagsQuery,
  useUpdateTopicMutation
} from 'services/topicsService';
import {ImageContentInput, LoaderSpinner, setCurrentTopicId, SharedWrapper} from 'common';
import { OptionType, Tag, TopicBSuccessResponseModel, TopicDetailsDataModel, TopicDetailsFormModel } from 'models';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';

type TopicDetails = {
  id: number;
}

export const Details = ({id}: TopicDetails) => {
  const toast = useToast();
  const dispatch = useDispatch();

  const {data: topicsTags, isLoading: isLoadingTopicsTags} = useGetTopicsTagsQuery('');

  const {data, isLoading, error} = useGetTopicByIdQuery(id);
  const [updateTopic] = useUpdateTopicMutation();
  const [createBVersion] = useCreateBVersionMutation();

  const [inputLength, setInputLength] = useState(0);

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<TopicDetailsFormModel>();

  useEffect(() => {
    if (!isLoading && !error && data) {
      const {title, description, emoji, background, tags, subtitle, isProContent} = data;
      setValue('title', title);
      setValue('emoji', emoji);
      setValue('description', description);
      setValue('subtitle', subtitle);
      setValue('background', background);
      setValue('tags', tags);
      setValue('isProContent', isProContent);

      dispatch(setCurrentTopicId(+id));

      setInputLength(subtitle.length);
    }
  }, [data]);

  if (isLoading || isLoadingTopicsTags) return <LoaderSpinner />;

  const onSubmit = async (topicData: TopicDetailsDataModel) => {
    await updateTopic({id, topicData});
  };
  const selectedTags =
    data.tags?.map((tag: string) => ({
      label: tag,
      value: tag,
    })) ?? [];

  const topicsTagsFiltered =
    topicsTags ??
    []
      .filter((tag: Tag) => tag.is_enabled)
      .map(({tag_value}: Tag) => ({
        label: tag_value,
        value: tag_value,
      }));

  const isInvalidForm =
    !!errors.title ||
    !!errors.description ||
    !!errors.background ||
    !!errors.subtitle ||
    !!errors.emoji ||
    !! errors.isProContent ||
    !!errors.tags;

  const createBVerisonHandler = async () => {
    const newVersion = (await createBVersion({id})) as TopicBSuccessResponseModel;
    // eslint-disable-next-line no-console
    console.log(newVersion);
    if (newVersion.data.success) {
      toast({
        title: 'Success',
        description: newVersion.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <SharedWrapper p="10">
        <Flex align="center" mb="5">
          <DetailsIcon />

          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Topic details
          </Text>
        </Flex>
        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>

              <Box>
                <Flex justify="space-between" align="baseline">
                  <FormLabel htmlFor="isProContent" color="slate.400">
                    Is it premium?
                  </FormLabel>
                  <Switch id="isProContent" defaultIsChecked={false} {...register('isProContent')} />
                </Flex>
                <FormErrorMessage>{errors.isProContent && errors.isProContent.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="title" color="slate.400">
                  Title
                </FormLabel>

                <Input
                  id="title"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('title', {
                    required: 'Title is required',
                  })}
                />
                <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="emoji" color="slate.400">
                  Emoji
                </FormLabel>

                <Input
                  id="emoji"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('emoji', {
                    required: 'emoji is required',
                  })}
                />
                <FormErrorMessage>{errors.emoji && errors.emoji.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="subtitle" color="slate.400">
                  Subtitle
                </FormLabel>
                <Textarea id="subtitle" bg="blue.200" autoComplete="off" {...register('subtitle', {
                  required: 'Subtitle is required',
                  maxLength: {value: 80, message: 'Too long subtitle'},
                  onChange: (e) => setInputLength(e.target.value.length),
                })} />
                <Text py="2" fontSize="16">
                  {inputLength} of 80 available characters
                </Text>
                <FormErrorMessage>{errors.subtitle && errors.subtitle.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="description" color="slate.400">
                  Description
                </FormLabel>
                <Textarea id="description" bg="blue.200" autoComplete="off" {...register('description')} />
                <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="background" color="slate.400">
                  Background
                </FormLabel>
                <Controller
                  name="background"
                  control={control}
                  render={({field: {value}}) => (
                    <ImageContentInput
                      value={value}
                      inputName="background"
                      setValue={setValue}
                      imgLink={data.background}
                    />
                  )}
                />

                <FormErrorMessage>{errors.background && errors.background.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="tags" color="slate.400">
                  Tags
                </FormLabel>
                <Controller
                  name="tags"
                  control={control}
                  render={({field: {onChange, onBlur}}) => (
                    <CreatableSelect
                      options={topicsTagsFiltered}
                      placeholder={'Choose...'}
                      isMulti
                      onChange={(options: OptionType[]) => onChange(options?.map((option: OptionType) => option.value))}
                      onBlur={onBlur}
                      defaultValue={selectedTags}
                    />
                  )}
                />
                <FormErrorMessage>{errors.tags && 'Tags is required'}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <ButtonGroup>
              <Button type="submit" isLoading={isSubmitting} mt="10">
                Save changes
              </Button>
              <Button isLoading={isSubmitting} mt="10" variant="secondary" onClick={createBVerisonHandler}>
                Convert to A/B version
              </Button>
            </ButtonGroup>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
