import {Redirect} from 'react-router-dom';
import styled from '@emotion/styled';
import {Menu, Header} from './components';
import {Controller, useForm} from 'react-hook-form';
import {CreatableSelect} from 'chakra-react-select';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  Spacer,
  Input,
  Textarea,
} from '@chakra-ui/react';
import {
  useCreateNotificationMutation,
  useGetNotificationTypesQuery
} from 'services/notificationsService';
import {NotificationProps} from 'models';
import {SharedWrapper, IsErrorMessage} from 'common';
import {ReactComponent as levelIcon} from 'assets/svg/book.svg';

export const NotificationsCreate = () => {
  const {data: types, error, isLoading} = useGetNotificationTypesQuery(null);
  const [sendNotification, {data: createdAvatar, isSuccess}] = useCreateNotificationMutation();

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<NotificationProps>();

  const typesFiltered = (types ?? []).map((type: string) => {
    return {
      label: type,
      value: type,
    };
  });

  if (isSuccess) {
    return <Redirect to={'/learners'} />;
  }

  const isInvalidForm = !!errors.title;

  const onSubmit = async (notificationData: NotificationProps) => {
    await sendNotification(notificationData);
  };

  return (
    <>
      <Header isAvatars={true} />
      <Menu />

      <SharedWrapper my="40px" p="40px">
        <Flex mx="4" mt="4" mb="6" align="center">
          <LevelIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Create Notification
          </Text>
        </Flex>

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="title" color="slate.400">
                  Title
                </FormLabel>

                <Input
                  id="title"
                  bg="blue.200"
                  {...register('title', {
                    required: 'Label is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.title && errors.title.message}
                </FormErrorMessage>
              </Box>
              <Box>
                <FormLabel htmlFor="text" color="slate.400">
                  Extended text
                </FormLabel>
                <Textarea
                  id="description"
                  bg="blue.200"
                  {...register('text', {
                    required: 'Description is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.text && errors.text.message}
                </FormErrorMessage>
              </Box>
              <Box>
                <FormLabel htmlFor="parent_id" color="slate.400">
                  Parent Group
                </FormLabel>
                <Controller
                  name="type"
                  control={control}
                  rules={{}}
                  render={({field: {onChange, onBlur}}) => (
                    <CreatableSelect
                      isClearable
                      options={typesFiltered}
                      placeholder={'Choose...'}
                      onChange={(option: any) =>
                        onChange(option.value)
                      }
                      onBlur={onBlur}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.type && 'Tags is required'}
                </FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Create
            </Button>
          </Form>
        </Box>

      </SharedWrapper>
    </>
  );
};

const LevelIcon = styled(levelIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
