import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

export const questionsApi = createApi({
  reducerPath: 'questionsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),
  tagTypes: ['questions', 'answers'],

  endpoints: (builder) => ({
    getQuestions: builder.query({
      query: ({lessonId, isArchived}) => {
        const filter = isArchived ? '&filter=archived' : '';
        return {
          url: `educator/cm/questions?lesson_id=${lessonId}&limit=100&offset=0${filter}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['questions'],
    }),

    getQuestionById: builder.query({
      query: (questionId) => {
        return {
          url: `educator/cm/questions/${questionId}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['questions'],
    }),
    createQuestion: builder.mutation({
      query: (question) => {
        return {
          url: 'educator/cm/questions',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: question,
        };
      },
      invalidatesTags: ['questions'],
    }),
    getAnswersByQuestionId: builder.query({
      query: (questionId) => {
        return {
          url: `educator/cm/answers?question_id=${questionId}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['answers'],
    }),
    getAnswerId: builder.query({
      query: (answerId) => {
        return {
          url: `educator/cm/answers/${answerId}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['answers'],
    }),

    updateQuestion: builder.mutation({
      query: ({questionId, updatedQuestion}) => {
        return {
          url: `educator/cm/questions/${questionId}`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: updatedQuestion,
        };
      },
      invalidatesTags: ['answers', 'questions'],
    }),
    updateQuestionOrder: builder.mutation({
      query: ({id, order}) => {
        return {
          url: `educator/cm/questions/${id}`,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: order,
        };
      },
      invalidatesTags: ['questions'],
    }),
    createAnswer: builder.mutation({
      query: (createdAnswer) => {
        return {
          url: '/educator/cm/answers',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: createdAnswer,
        };
      },
      invalidatesTags: ['questions', 'answers'],
    }),
    deleteAnswer: builder.mutation({
      query: ({id}) => {
        return {
          url: `/educator/cm/answers/${id}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['answers', 'questions'],
    }),
    updateAnswer: builder.mutation({
      query: ({answerId, updatedAnswer}) => {
        return {
          url: `/educator/cm/answers/${answerId}`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: updatedAnswer,
        };
      },
      invalidatesTags: ['answers', 'questions'],
    }),

    getQuestionsTypes: builder.query({
      query: () => {
        return {
          url: '/educator/cm/question-types',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
    getExplanationTypes: builder.query({
      query: () => {
        return {
          url: '/educator/cm/explanation-types',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),

    archiveQuestion: builder.mutation({
      query: (questionId) => {
        return {
          url: `/educator/cm/questions/${questionId}?cmd=archive`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['questions'],
    }),
    unArchiveQuestion: builder.mutation({
      query: (questionId) => {
        return {
          url: `/educator/cm/questions/${questionId}?cmd=un_archive`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['questions'],
    }),
  }),
});

export const {
  useGetQuestionsQuery,
  useGetQuestionByIdQuery,
  useCreateQuestionMutation,
  useUpdateQuestionOrderMutation,
  useGetAnswersByQuestionIdQuery,
  useGetExplanationTypesQuery,
  useGetAnswerIdQuery,
  useUpdateQuestionMutation,
  useUpdateAnswerMutation,
  useCreateAnswerMutation,
  useDeleteAnswerMutation,
  useGetQuestionsTypesQuery,
  useArchiveQuestionMutation,
  useUnArchiveQuestionMutation,
} = questionsApi;
