import {Redirect} from 'react-router-dom';
import styled from '@emotion/styled';
import {Controller, useForm} from 'react-hook-form';
import {CreatableSelect} from 'chakra-react-select';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Text,
  Textarea,
} from '@chakra-ui/react';
import {useCreateTopicMutation, useGetTopicsTagsQuery} from 'services/topicsService';
import {ImageContentInput, LoaderSpinner, SharedWrapper} from 'common';
import {useParamId} from 'utils';
import {OptionType, Tag} from 'models';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';
import { useState } from 'react';

export const NewTopic = () => {
  const id = useParamId();

  const {data: topicsTags, isLoading} = useGetTopicsTagsQuery('');
  const [createTopic, {data: createdTopicData, isSuccess}] = useCreateTopicMutation();

  const [inputLength, setInputLength] = useState(0);

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<any>();

  if (isSuccess) {
    return <Redirect to={`/topic/${createdTopicData.data.id}`} />;
  }

  if (isLoading) return <LoaderSpinner />;

  const topicsTagsFiltered =
    topicsTags ??
    []
      .filter((tag: Tag) => tag.is_enabled)
      .map(({tag_value}: Tag) => ({
        label: tag_value,
        value: tag_value,
      }));

  const onSubmit = async (topicData: any) => {
    await createTopic({courseId: +id, ...topicData});
  };

  const isInvalidForm =
    !!errors.title ||
    !!errors.subtitle ||
    !!errors.description ||
    !!errors.background ||
    !!errors.emoji ||
    !!errors.tags;

  return (
    <>
      <SharedWrapper p="10">
        <Flex align="center" mb="5">
          <DetailsIcon />

          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Create new topic
          </Text>
        </Flex>
        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="title" color="slate.400">
                  Title
                </FormLabel>

                <Input
                  id="title"
                  autoComplete="off"
                  bg="blue.200"
                  {...register('title', {
                    required: 'Title is required',
                  })}
                />
                <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="emoji" color="slate.400">
                  Emoji
                </FormLabel>

                <Input
                  id="emoji"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('emoji', {
                    required: 'emoji is required',
                  })}
                />
                <FormErrorMessage>{errors.emoji && errors.emoji.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="subtitle" color="slate.400">
                  Subtitle
                </FormLabel>
                <Textarea id="subtitle" bg="blue.200" autoComplete="off" {...register('subtitle', {
                  required: 'Subtitle is required',
                  maxLength: {value: 80, message: 'Too long subtitle'},
                  onChange: (e) => setInputLength(e.target.value.length),
                })} />
                <Text py="2" fontSize="16">
                  {inputLength} of 80 available characters
                </Text>
                <FormErrorMessage>{errors.subtitle && errors.subtitle.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="description" color="slate.400">
                  Description
                </FormLabel>
                <Textarea id="description" bg="blue.200" autoComplete="off" {...register('description')} />
                <FormErrorMessage>{errors.description && errors.description.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="background" color="slate.400">
                  Background
                </FormLabel>
                <Controller
                  name="background"
                  control={control}
                  defaultValue={''}
                  render={({field: {value}}) => (
                    <ImageContentInput value={value} inputName="background" setValue={setValue} />
                  )}
                />

                <FormErrorMessage>{errors.background && errors.background.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="tags" color="slate.400">
                  Tags
                </FormLabel>
                <Controller
                  name="tags"
                  control={control}
                  render={({field: {onChange, onBlur}}) => (
                    <CreatableSelect
                      options={topicsTagsFiltered}
                      placeholder={'Choose...'}
                      isMulti
                      onChange={(options: OptionType[]) => onChange(options?.map((option: OptionType) => option.value))}
                      onBlur={onBlur}
                    />
                  )}
                />
                <FormErrorMessage>{errors.tags && 'Tags is required'}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Create
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
