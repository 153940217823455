import {useEffect, useState} from 'react';
import styled from '@emotion/styled';
import {Controller, useForm} from 'react-hook-form';
import {Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Input, Spacer, Text} from '@chakra-ui/react';
import {useGetKeyfactLessonsByIdQuery, useUpdateLessonKeyfactMutation} from 'services/lessonsService';
import {ImageContentInput, LoaderSpinner, SharedWrapper} from 'common';
import {LessonKeyFact} from 'models';
import {useParamId} from 'utils';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';

export interface KeyFactModel {
  lessonId: number;
  id?: number;
  title?: string;
  icon?: string | null;
  orderIndex?: number;
  createdAt?: Date;
  updatedAt?: Date;
  archivedAt?: Date | null;
}

export const KeyFact = ({lessonId}: KeyFactModel) => {
  const id = useParamId();

  const [updateKeyfact] = useUpdateLessonKeyfactMutation();

  const {data, isLoading} = useGetKeyfactLessonsByIdQuery(id);
  const [inputLength, setInputLength] = useState(0);

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<LessonKeyFact>();

  useEffect(() => {
    if (!isLoading && data) {
      const {title, icon} = data;

      setValue('title', title);
      setValue('icon', icon);
      setInputLength(title.length);
    }
  }, [data]);

  if (isLoading) return <LoaderSpinner />;

  const isInvalidForm = !!errors.title || !!errors.icon;

  const onSubmit = async (data: LessonKeyFact) => {
    const keyfactData = {lessonId: +lessonId, ...data};
    await updateKeyfact({id, keyfactData});
  };

  return (
    <>
      <SharedWrapper p="10">
        <Flex align="center" mb="5">
          <DetailsIcon />

          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Key fact details
          </Text>
        </Flex>
        <Box>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="title" color="slate.400">
                  Title
                </FormLabel>

                <Input
                  id="title"
                  bg="blue.200"
                  {...register('title', {
                    required: 'Title is required',
                    maxLength: {value: 100, message: 'Too long title'},
                    onChange: (e) => setInputLength(e.target.value.length),
                  })}
                />
                <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
                <Text>{inputLength} of 100 available characters</Text>
              </Box>

              <Box>
                <FormLabel htmlFor="icon" color="slate.400">
                  Icon
                </FormLabel>
                <Controller
                  name="icon"
                  control={control}
                  rules={{required: 'icon is required'}}
                  render={({field: {value}}) => (
                    <ImageContentInput inputName="icon" value={value} setValue={setValue} imgLink={data.icon} />
                  )}
                />

                <FormErrorMessage>{errors.icon && errors.icon.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
