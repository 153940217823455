import {useHistory, useRouteMatch} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useGetExplanationTypesQuery, useUpdateQuestionMutation} from 'services/questionsService';
import {ImageContentInput, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const Parameters = ({question, questionId, baseUrl}: any) => {
  const history = useHistory();
  const {data, isLoading} = useGetExplanationTypesQuery('');
  const explanationTypes = data?.filter(({type}: any) => type === 'common');

  const [updateQuestion] = useUpdateQuestionMutation();

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    setValue,
  } = useForm<any>({defaultValues: {imageContent: question.assetURL}});

  const newElemHandler = () => {
    history.push(baseUrl + '/parameters/new-element');
  };

  const deleteHandler = async (e: any) => {
    const {id} = e.target;
    const updatedElements = question.questionPayload.elements.filter((elem: any) => +elem.id !== +id);
    const updatedQuestion = {
      questionPayload: {...question.questionPayload, elements: updatedElements},
    };

    await updateQuestion({questionId, updatedQuestion});
  };

  const onSubmit = async ({imageContent}: any) => {
    await updateQuestion({
      questionId,
      updatedQuestion: {assetURL: imageContent},
    });
  };

  const {elements} = question.questionPayload ?? [];

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Explanations" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th>Case</Th>
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              explanationTypes.map(({explanation_type_description, title}: any) => (
                <Tr key={title}>
                  <Td>{explanation_type_description}</Td>
                  <Td>{title}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />
      <SharedWrapper p="10">
        <QuestionHeader title="First Column Elements" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Object</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            {elements &&
              elements.map(({value, id}: any) => (
                <Tr key={id}>
                  <Td>{value}</Td>
                  <Td>
                    <Flex justify="flex-end">
                      <EditButton id={id} />
                      <Button size="sm" variant="secondaryWarning" id={id} onClick={deleteHandler}>
                        Delete
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>

        <Button onClick={newElemHandler} my="3">
          Create new element
        </Button>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Other" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl>
              <FormLabel htmlFor="imageContent" color="slate.400">
                Image content
              </FormLabel>
              <Controller
                name="imageContent"
                control={control}
                render={({field: {value}}) => (
                  <ImageContentInput
                    setValue={setValue}
                    value={value}
                    inputName="imageContent"
                    imgLink={question.assetURL}
                  />
                )}
              />

              <FormErrorMessage>{errors.imageContent && errors.imageContent.message}</FormErrorMessage>
            </FormControl>

            <Button type="submit" isLoading={isSubmitting}>
              Save
            </Button>
          </Form>
        </Box>
      </SharedWrapper>

      <Box h="10" />
    </>
  );
};

const EditButton = ({id}: any) => {
  const history = useHistory();
  const {url} = useRouteMatch();

  const editHandler = () => {
    history.push(`${url}/elements/${id}`);
  };

  return (
    <Button size="sm" onClick={editHandler} mr="3">
      Edit
    </Button>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
