import {Breadcrumb, BreadcrumbItem, BreadcrumbLink, Skeleton} from '@chakra-ui/react';
import {useSelector} from 'react-redux';
import {getCurrentCourse, getCurrentLesson, getCurrentQuestion, getCurrentTopic} from './navigationSlice';
import {BreadcrumbsProps} from 'models';

export const Breadcrumbs = ({
  isCourse,
  isTopic,
  isLesson,
  isQuestion,
}: BreadcrumbsProps) => {
  const currentCourse = useSelector(getCurrentCourse);
  const currentTopic = useSelector(getCurrentTopic);
  const currentLesson = useSelector(getCurrentLesson);
  const currentQuestion = useSelector(getCurrentQuestion);

  return (
    <Breadcrumb fontSize="sm">
      <BreadcrumbItem
        color="slate.400"
        _last={{
          fontWeight: 'medium',
          fontSize: '14',
          color: 'slate.900',
        }}
      >
        <BreadcrumbLink href={'/courses'}>My Courses</BreadcrumbLink>
      </BreadcrumbItem>

      {isCourse && (
        <BreadcrumbItem
          color="slate.400"
          isCurrentPage={!isTopic}
          _last={{
            fontWeight: 'medium',
            fontSize: '14',
            color: 'slate.900',
          }}
        >
          <BreadcrumbLink href={currentCourse.url}>
            {currentCourse.title || <Skeleton>Course loading...</Skeleton>}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {isTopic && (
        <BreadcrumbItem
          color="slate.400"
          _last={{
            fontWeight: 'medium',
            fontSize: '14',
            color: 'slate.900',
          }}
        >
          <BreadcrumbLink href={currentTopic.url}>
            {currentTopic.title || <Skeleton>Topic loading...</Skeleton>}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {isLesson && (
        <BreadcrumbItem
          color="slate.400"
          _last={{
            fontWeight: 'medium',
            fontSize: '14',
            color: 'slate.900',
          }}
        >
          <BreadcrumbLink href={currentLesson.url}>
            {currentLesson.title || <Skeleton>Lesson loading...</Skeleton>}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}

      {isQuestion && (
        <BreadcrumbItem
          color="slate.400"
          _last={{
            fontWeight: 'medium',
            fontSize: '14',
            color: 'slate.900',
          }}
        >
          <BreadcrumbLink href={currentQuestion.url}>
            {currentQuestion.title || <Skeleton>Question loading...</Skeleton>}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )}
    </Breadcrumb>
  );
};
