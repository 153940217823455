import {SharedMenu} from 'common';
import {useRouteMatch} from 'react-router-dom';

const links = [
  {
    title: 'details',
    value: '',
  },
  {
    title: 'Key Facts',
    value: '/keyfacts',
  },
  {
    title: 'Theory',
    value: '/theory',
  },
  {
    title: 'Questions',
    value: '/questions',
  },

  {
    title: 'Archived',
    value: '/questions-archived',
  },
];

export const Menu = () => {
  const {url} = useRouteMatch();

  return <SharedMenu baseUrl={url} separatorAfterElement={3} links={links} />;
};
