import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {LoaderSpinner, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {AnswerId, COORDS} from './constants';
import {useParamId} from 'utils';

export const Gap = ({questionId, question}: any) => {
  const [updateQuestion] = useUpdateQuestionMutation();
  const id = useParamId();

  const gaps = question.questionPayload;
  const gap = gaps.find(({Order}: any) => Order === id);

  if (!gap) return <LoaderSpinner />;

  const defaultAnswerId =
    gap.answerId ??
    Object.values(COORDS).find(({locationX, locationY}: any) => {
      return locationX === gap.LocationX && locationY === gap.LocationY;
    })?.answerId;

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>({
    defaultValues: {answerId: defaultAnswerId},
  });

  const isInvalidForm = !!errors.locationX || !!errors.locationY || !!errors.order || !!errors.size;

  const onSubmit = async ({answerId}: {answerId: AnswerId}) => {
    const newOrderElem = COORDS[answerId];

    const newElemUpperCased = {
      Size: newOrderElem.size,
      LocationX: newOrderElem.locationX,
      LocationY: newOrderElem.locationY,
      Order: gap.Order,
      answerId,
    };

    const updatedGaps = gaps.map((elem: any) => (elem.Order === id ? newElemUpperCased : elem));

    const updatedQuestion = {
      questionPayload: updatedGaps,
    };

    await updateQuestion({questionId, updatedQuestion});
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Coordinates constants" />
        <TableContainer>
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Answer Id</Th>
                <Th>Size</Th>
                <Th>Location X</Th>
                <Th>Location Y</Th>
              </Tr>
            </Thead>

            <Tbody>
              {Object.values(COORDS).map((coord) => {
                const {size, locationX, locationY, answerId} = coord;

                return (
                  <Tr key={answerId}>
                    <Td>{answerId}</Td>
                    <Td>{size}</Td>
                    <Td>{locationX}</Td>
                    <Td>{locationY}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </SharedWrapper>
      <Box h="10" />
      <SharedWrapper p="10">
        <QuestionHeader title="Answer ID" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="answerId" color="slate.400">
                Answer ID
              </FormLabel>

              <Select
                id="answerId"
                bg="blue.200"
                {...register('answerId', {
                  required: 'answerId is required',
                  valueAsNumber: true,
                })}
              >
                {Object.keys(COORDS).map((answerId) => {
                  return (
                    <option key={answerId} value={answerId}>
                      Answer: {answerId}
                    </option>
                  );
                })}
              </Select>
              <FormErrorMessage>{errors.answerId && errors.answerId.message}</FormErrorMessage>
            </Box>
          </FormControl>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting} mt="10">
            Save changes
          </Button>
        </Form>
      </SharedWrapper>

      <Box h="10" />
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
