import {useEffect} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useGetExplanationTypesQuery, useUpdateQuestionMutation} from 'services/questionsService';
import {ImageContentInput, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const Parameters = ({question, questionId, baseUrl}: any) => {
  const history = useHistory();
  const {data, isLoading} = useGetExplanationTypesQuery('');
  const explanationTypes = data?.filter(({type}: any) => type === 'common');

  const [updateQuestion] = useUpdateQuestionMutation();

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    setValue,
  } = useForm<any>({
    defaultValues: {imageContent: question.assetURL},
  });

  const onSubmit = async ({imageContent}: any) => {
    const updatedQuestion = {assetURL: imageContent};

    await updateQuestion({
      questionId,
      updatedQuestion,
    });
  };

  const deleteHandler = async (e: any) => {
    const {id} = e.target;
    const updatedBins = question.questionPayload.filter((elem: any) => elem.Order !== +id);

    const updatedQuestion = {
      questionPayload: updatedBins,
    };
    await updateQuestion({questionId, updatedQuestion});
  };

  useEffect(() => {
    if (!isLoading && data) {
      setValue('imageContent', question.assetURL);
    }
  }, [isLoading]);

  const newGapHandler = () => {
    history.push(baseUrl + '/parameters/new-gap');
  };

  const gaps = question.questionPayload;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Explanations" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th>Case</Th>
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              explanationTypes.map(({explanation_type_description, title}: any) => (
                <Tr key={title}>
                  <Td>{explanation_type_description}</Td>
                  <Td>{title}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />
      <SharedWrapper p="10">
        <QuestionHeader title="Gaps" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Object</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            {gaps &&
              gaps.map(({Order: order}: any) => (
                <Tr key={order}>
                  <Td>{order}</Td>
                  <Td>
                    <Flex justify="flex-end">
                      <EditButton id={order} />
                      <Button size="sm" variant="secondaryWarning" id={order} onClick={deleteHandler}>
                        Delete
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        <Button onClick={newGapHandler} my="3">
          Create new gap
        </Button>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Other" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl>
              <FormLabel htmlFor="imageContent" color="slate.400">
                Image content
              </FormLabel>
              <Controller
                name="imageContent"
                control={control}
                render={({field: {value}}) => (
                  <ImageContentInput
                    value={value}
                    inputName="imageContent"
                    setValue={setValue}
                    imgLink={question.assetURL}
                  />
                )}
              />

              <FormErrorMessage>{errors.imageContent && errors.imageContent.message}</FormErrorMessage>
            </FormControl>

            <Button type="submit" isLoading={isSubmitting}>Save</Button>
          </Form>
        </Box>
      </SharedWrapper>

      <Box h="10" />
    </>
  );
};

const EditButton = ({id}: any) => {
  const history = useHistory();
  const {url} = useRouteMatch();

  const editHandler = () => {
    history.push(`${url}/gaps/${id}`);
  };

  return (
    <Button size="sm" onClick={editHandler} mr="3">
      Edit
    </Button>
  );
};


const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
