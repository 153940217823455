import {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {CSS} from '@dnd-kit/utilities';
import {useSortable} from '@dnd-kit/sortable';
import {Box, Button, ButtonGroup, Flex, Text} from '@chakra-ui/react';
import {useDeleteKeyfactMutation} from 'services/topicsService';
import {ReactComponent as sortableIcon} from 'assets/svg/sortable.svg';

export const KeyFactsItem = ({baseUrl, title, id}: any) => {
  const history = useHistory();
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [deleteKeyfact] = useDeleteKeyfactMutation();

  useEffect(() => {
    return () => {
      setNodeRef(null);
    };
  }, []);

  const editHandler = () => {
    history.push(`${baseUrl}/keyfact/${id}`);
  };

  const deleteHandler = async () => {
    await deleteKeyfact(id);
  };

  return (
    <Box style={style} {...attributes} w="100%" px="4" my="1">
      <Flex align="center" w="100%" border="2px solid" p="2" borderColor="blue.200">
        <Box ref={setNodeRef} {...listeners} flexBasis="10%">
          <SortableIcon />
        </Box>
        <Text flexBasis="70%">{title}</Text>

        <Flex justify="flex-end" flexBasis="20%">
          <ButtonGroup>
            <Button size="sm" variant="secondary" onClick={editHandler}>
              Edit
            </Button>
            <Button size="sm" variant="secondaryWarning" onClick={deleteHandler}>
              Delete
            </Button>
          </ButtonGroup>
        </Flex>
      </Flex>
    </Box>
  );
};

const SortableIcon = styled(sortableIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
