import {useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Text, Textarea,
  useToast
} from '@chakra-ui/react';
import {ImageContentInput, SharedWrapper} from 'common';
import {useGetLessonByIdQuery, useUpdateLessonMutation} from 'services/lessonsService';
import {nanoidInt} from 'utils';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';
import {useHistory} from 'react-router-dom';

export const NewDialogue = ({baseUrl, lessonId}: any) => {
  const toast = useToast();
  const history = useHistory();

  const {data: lesson} = useGetLessonByIdQuery(lessonId);

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<any>();

  const [inputLengthValue, setInputLengthValue] = useState(0);
  const maxLengthValue = 165;

  const [updateDialogues] = useUpdateLessonMutation();

  const onSubmit = async ({phrase, name, icon}: any) => {
    const dialogues = lesson?.dialogues ?? [];
    const lastElemOrder = (dialogues && dialogues.slice(-1)[0]?.order) ?? 0;

    const newDialogue = {id: Number(nanoidInt()), order: lastElemOrder + 1, phrase, name, icon};
    const updatedLesson = {dialogues: [...dialogues, newDialogue]};
    const result = (await updateDialogues({updatedLesson, lessonId})) as any;

    if (result) {
      toast({
        title: 'Success',
        description: 'Dialogue created',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      history.push({
        pathname: baseUrl + '/theory',
      });
    }
  };

  const isInvalidForm = !!errors.phrase || !!errors.icon;

  return (
    <>
      <SharedWrapper p="10">
        <Flex align="center" mb="5">
          <DetailsIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Dialogue details
          </Text>
        </Flex>
        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="name" color="slate.400">
                  Speaker Name
                </FormLabel>

                <Input
                  id="name"
                  bg="blue.200"
                  {...register('name', {
                    required: 'Name is required',
                  })}
                />
                <FormErrorMessage>{errors.name && errors.name.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="phrase" color="slate.400">
                  Phrase
                </FormLabel>

                <Textarea
                  id="phrase"
                  rows={4}
                  bg="blue.200"
                  {...register('phrase', {
                    required: 'Phrase is required',
                    maxLength: {value: maxLengthValue, message: 'Too long title'},
                    onChange: (e) => setInputLengthValue(e.target.value.length),
                  })}
                />
                <FormErrorMessage>{errors.phrase && errors.phrase.message}</FormErrorMessage>
                <Text>
                  {inputLengthValue} of {maxLengthValue} available characters
                </Text>
              </Box>

              <Box>
                <FormLabel htmlFor="icon" color="slate.400">
                  Icon
                </FormLabel>
                <Controller
                  name="icon"
                  control={control}
                  rules={{required: 'Icon is required'}}
                  render={({field: {value}}) => (
                    <ImageContentInput inputName="icon" value={value} setValue={setValue} />
                  )}
                />

                <FormErrorMessage>{errors.icon && errors.icon.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
