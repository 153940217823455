import React from 'react';
import {Alert, AlertDescription, AlertIcon, AlertTitle} from '@chakra-ui/react';

export const IsErrorMessage = ({error}: any) => {
  if (error && 'status' in error && 'error' in error) {
    return (
      <Alert status="error">
        <AlertIcon />
        <AlertTitle mr={2}>{error.status}</AlertTitle>
        <AlertDescription>{error.error}</AlertDescription>
      </Alert>
    );
  }

  return null;
};
