import {Route, useRouteMatch} from 'react-router-dom';
import {useGetQuestionByIdQuery} from 'services/questionsService';
import {Answer, Answers, Bin, Details, NewAnswer, NewBin, Parameters} from './components';
import {useParamId} from 'utils';

export const ImageBins = () => {
  const {url} = useRouteMatch();
  const questionId = useParamId();
  const {data: question} = useGetQuestionByIdQuery(questionId);

  return (
    <>
      <Route exact path={url} render={() => <Details questionId={questionId} question={question} />} />

      <Route
        exact
        path={url + '/parameters'}
        render={() => <Parameters question={question} baseUrl={url} questionId={questionId} />}
      />

      <Route
        exact
        path={url + '/new-answer'}
        render={() => <NewAnswer questionId={questionId} question={question} baseUrl={url} />}
      />

      <Route
        exact
        path={url + '/parameters/new-bin'}
        render={() => <NewBin questionId={questionId} question={question} baseUrl={url} />}
      />

      <Route
        exact
        path={url + '/parameters/bins/:id'}
        render={() => <Bin questionId={questionId} question={question} baseUrl={url} />}
      />

      <Route exact path={url + '/answers'} render={() => <Answers questionId={questionId} baseUrl={url} />} />

      <Route exact path={url + '/answers/:id'} render={() => <Answer question={question} baseUrl={url} />} />
    </>
  );
};
