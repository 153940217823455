import {extendTheme} from '@chakra-ui/react';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';

export const colors = {
  white: '#FFFFFF',
  bg: '#F2F2F2',
  slate: {
    200: '#E0E4EA',
    400: '#B0BAC9',
    600: '#808AA0',
    800: '#555F75',
    900: '#2E384D',
  },
  blue: {
    100: '#F4F6FC',
    200: '#EEF2FF',
    400: '#E0E7FF',
    700: '#2E5BFF',
  },
  red: {
    200: '#FFEEF0',
    700: '#D63649',
  },
};

const Button = {
  baseStyle: {
    borderRadius: '4px',
    fontWeight: 'medium',
  },
  sizes: {
    md: {
      fontSize: 16,
      px: '30px',
      py: '21px',
    },
    sm: {
      fontSize: 14,
      px: '20px',
      py: '6px',
    },
  },
  variants: {
    solid: {
      border: '2px solid',
      bg: 'blue.700',
      color: 'white',
      borderColor: 'blue.700',
      '&:hover': {
        background: 'blue.400',
        color: 'blue.700',
      },
    },
    solidWarning: {
      border: '2px solid',
      bg: 'red.700',
      color: 'white',
      borderColor: 'red.700',
      '&:hover': {
        background: 'red.200',
        color: 'red.700',
      },
    },
    outline: {
      border: '2px solid',
      borderColor: 'slate.900',
      color: 'slate.900',
      bg: 'transparent',
    },
    secondary: {
      bg: 'blue.400',
      color: 'blue.700',
      border: '2px solid',
      borderColor: 'blue.400',
      '&:hover': {
        background: 'blue.700',
        color: 'white',
        border: '2px solid',
        borderColor: 'blue.700',
      },
    },
    secondaryWarning: {
      border: '2px solid',
      bg: 'red.200',
      color: 'red.700',
      borderColor: 'red.200',
      '&:hover': {
        background: 'red.700',
        color: 'white',
        border: '2px solid',
        borderColor: 'red.700',
      },
    },
    dark: {border: '2px solid', bg: 'slite.900', color: 'white'},
  },
  defaultProps: {
    size: 'md',
    variant: 'solid',
  },
};

const Text = {
  baseStyle: {
    fontWeight: 'normal',
    fontSize: 14,
  },
};

const Link = {
  baseStyle: {
    _focus: {boxShadow: 'none'},
  },
};
const BreadcrumbLink = {
  baseStyle: {
    _focus: {boxShadow: 'inset 5em 1em gold'},
  },
};

const FormLabel = {
  baseStyle: {
    fontSize: 12,
    lineHeight: 16,
    letterSpacing: '1px',
    textTransform: 'uppercase',
    mb: '5px',
    mt: '15px',
  },
};

export const mainTheme = extendTheme({
  components: {BreadcrumbLink, Button, FormLabel, Link, Text},
  fonts: {
    body: 'Rubik, sans-serif',
    heading: 'Rubik, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontSizes: {
    12: '12px',
    14: '14px',
    16: '16px',
  },
  lineHeights: {
    16: '16px',
    20: '20px',
    24: '24px',
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },
  colors,
  styles: {
    global: {
      body: {
        background: 'bg',
      },
    },
  },
});
