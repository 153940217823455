import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {Box, Button, ButtonGroup, createStandaloneToast, Flex, Td, Tr} from '@chakra-ui/react';
import {getDateFromStr} from 'utils';
import {mainTheme} from '../../../../theme';
import {useDeleteAvatarMutation} from 'services/avatarService';
import {SortableIcon} from '../../../../theme/styles';

export const AvatarGroupItem = ({
  label,
  id,
  created_at,
  order,
  parent_id,
  updated_at,
  thumbnail_url,
  children,
  isParent,
}: any) => {
  const history = useHistory();
  const {attributes, listeners, setNodeRef, transform, transition} = useSortable({id});
  const [deleteAvatar] = useDeleteAvatarMutation();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const parentStyle = {
    transform: CSS.Transform.toString(transform),
    transition,
    background: '#f5f5f5',
    boxShadow: '0px 2px 2px rgb(0 0 0 / 50%)',
  };

  useEffect(() => {
    return () => {
      setNodeRef(null);
    };
  }, []);

  const editGroupHandler = (event: any, avatarId: number) => {
    event.preventDefault();

    history.push('/create-group/' + id);
  };

  const deleteGroupHandler = (event: any, avatarId: number) => {
    event.preventDefault();

    if (confirm('Do you want to delete the avatar?')) {
      deleteAvatar({groupId: id});
    }
  };

  return (
    <>
      <Tr style={isParent ? parentStyle : style} {...attributes}>
        <Td>
          <Flex ref={setNodeRef} {...listeners}>
            <Box>
              <SortableIcon/>
            </Box>
            {label}
          </Flex>
        </Td>
        <Td>{id}</Td>
        <Td>{getDateFromStr(created_at)}</Td>
        <Td>
          <Flex justify="flex-end">
            <ButtonGroup>
              <Button
                size="sm"
                variant="secondary"
                onClick={(event) => { editGroupHandler(event, id); }}>
                Edit
              </Button>
            </ButtonGroup>
          </Flex>
        </Td>
        <Td>
          <ButtonGroup>
            <Button
              size="sm"
              variant="secondaryWarning"
              onClick={(event) => { deleteGroupHandler(event, id); }}>
                Delete
            </Button>
          </ButtonGroup>
        </Td>
      </Tr>
    </>
  );
};
