import {useHistory, useParams} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer} from '@chakra-ui/react';
import {ImageContentInput, SharedWrapper} from 'common';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {QuestionHeader} from 'pages/question/common';

export const Bin = ({baseUrl, questionId, question}: any) => {
  const history = useHistory();

  const {id} = useParams<{id: string}>();

  const bin = question.questionPayload.Bins.find((elem: any) => +elem.Id === +id) || question.questionPayload.Bins[+id];

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    control,
    setValue,
  } = useForm<any>({
    defaultValues: {
      title: bin.Title,
      imageContent: bin.assetURL,
    },
  });

  const [updateQuestion] = useUpdateQuestionMutation();

  const onSubmit = async ({title, imageContent}: any) => {
    const updatedBins = question.questionPayload?.Bins?.map((elem: any) =>
      +elem.Id === +id ? {Id: +id, Title: title, assetURL: imageContent} : elem
    );

    const updatedQuestion = {
      questionPayload: {...question.questionPayload, Bins: updatedBins},
    };
    await updateQuestion({questionId, updatedQuestion});

    history.push(baseUrl + '/parameters');
  };

  const isInvalidForm = !!errors.title;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Bin" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="title" color="slate.400">
                Title
              </FormLabel>

              <Input
                id="title"
                bg="blue.200"
                autoComplete="off"
                {...register('title', {
                  required: 'Title is required',
                })}
              />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </Box>

            <Spacer />

            <Box>
              <FormLabel htmlFor="imageContent" color="slate.400">
                Image content
              </FormLabel>
              <Controller
                name="imageContent"
                control={control}
                render={({field: {value}}) => (
                  <ImageContentInput
                    setValue={setValue}
                    value={value}
                    inputName="imageContent"
                    imgLink={bin.assetURL}
                  />
                )}
              />

              <FormErrorMessage>{errors.imageContent && errors.imageContent.message}</FormErrorMessage>
            </Box>
            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </FormControl>
        </Form>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
