import {useHistory} from 'react-router-dom';
import {Button, ButtonGroup, Flex, Td, Tr} from '@chakra-ui/react';
import {useArchiveTopicMutation, useUnArchiveTopicMutation} from 'services/topicsService';
import {TopicProps} from 'models';
import {getDateFromStr} from 'utils';

export const TopicsItem = ({
  title,
  createdAt,
  lessonCount,
  id,
  isArchived, isProContent, groupType
}: TopicProps) => {
  const history = useHistory();


  const handleClick = () => {
    history.push(`/topic/${id}`);
  };

  const createdAtDate = getDateFromStr(createdAt);

  return (
    <Tr>
      <Td>{id}</Td>
      <Td>{title}</Td>
      <Td>{lessonCount}</Td>
      <Td>{isProContent ? 'YES' : 'NO'}</Td>
      <Td>{groupType === null ? 'A (NORMAL)' : groupType}</Td>
      <Td>{createdAtDate}</Td>

      <Td>
        <Flex justify="flex-end">
          <ButtonGroup>
            <Button size="sm" variant="secondary" onClick={handleClick}>
              Edit
            </Button>
            {isArchived ? (
              <UnarchiveButton id={id} />
            ) : (
              <ArchiveButton id={id} />
            )}
          </ButtonGroup>
        </Flex>
      </Td>
    </Tr>
  );
};

const ArchiveButton = ({id}: {id: number}) => {
  const [archiveTopic] = useArchiveTopicMutation();

  const archiveHandler = async () => {
    await archiveTopic(id);
  };

  return (
    <Button size="sm" variant="secondaryWarning" onClick={archiveHandler}>
      Archive
    </Button>
  );
};
const UnarchiveButton = ({id}: {id: number}) => {
  const [unarchiveTopic] = useUnArchiveTopicMutation();

  const unarchiveHandler = async () => {
    await unarchiveTopic(id);
  };

  return (
    <Button size="sm" variant="secondaryWarning" onClick={unarchiveHandler}>
      Unarchive
    </Button>
  );
};
