import {useHistory, useRouteMatch} from 'react-router-dom';
import {Box, Button, Flex, Table, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import {useGetExplanationTypesQuery, useUpdateQuestionMutation} from 'services/questionsService';
import {QuestionHeader} from 'pages/question/common';
import {SharedWrapper} from 'common';

export const Parameters = ({question, questionId, baseUrl}: any) => {
  const history = useHistory();
  const {data, isLoading} = useGetExplanationTypesQuery('');
  const explanationTypes = data?.filter(({type}: any) => type === 'common');
  const [updateQuestion] = useUpdateQuestionMutation();

  const bins = question.questionPayload?.Bins;

  const createBinHandler = () => {
    history.push(baseUrl + '/parameters/new-bin');
  };

  const deleteHandler = async (e: any) => {
    const {id} = e.target;
    const updatedBins = question.questionPayload.Bins.filter((elem: any) => +elem.Id !== +id);
    const updatedQuestion =
      updatedBins.length > 0
        ? {
          questionPayload: {...question.questionPayload, Bins: updatedBins},
        }
        : {
          questionPayload: {},
        };
    await updateQuestion({questionId, updatedQuestion});
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Explanations" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th>Case</Th>
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              explanationTypes.map(({explanation_type_description, title}: any) => (
                <Tr key={title}>
                  <Td>{explanation_type_description}</Td>
                  <Td>{title}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />
      <SharedWrapper p="10">
        <QuestionHeader title="Bins" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Object</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            {bins &&
              bins.map(({Title: title, Id: id}: any) => (
                <Tr key={id}>
                  <Td>{title}</Td>
                  <Td>
                    <Flex justify="flex-end">
                      <EditButton id={id} />
                      <Button size="sm" variant="secondaryWarning" id={id} onClick={deleteHandler}>
                        Delete
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>

        <Button onClick={createBinHandler} mt="3">
          Create new bin
        </Button>
      </SharedWrapper>

      <Box h="10" />
    </>
  );
};

const EditButton = ({id}: any) => {
  const history = useHistory();
  const {url} = useRouteMatch();

  const editHandler = () => {
    history.push(`${url}/bins/${id}`);
  };

  return (
    <Button size="sm" onClick={editHandler} mr="3">
      Edit
    </Button>
  );
};
