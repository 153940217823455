import {MenuItem} from 'common';
import {Box, Flex} from '@chakra-ui/react';

const links = [
  {
    title: 'MY COURSES',
    value: '/courses',
  },
  {
    title: 'ARCHIVED',
    value: '/courses-archived',
  },
  {
    title: 'AVATAR GROUPS',
    value: '/avatar-groups',
  },
] as const;

export const MenuEdit = ({id, isParent}: any) => {
  return (
    <>
      <Flex align="center" px="10vw" py="15">
        {links.map(({value, title}) => {
          return (
            <Box mr="5" key={title}>
              <MenuItem title={title} link={value} />
            </Box>
          );
        })}

        {((isParent) => {
          if (isParent) {
            return (
              <Box mr="5" key={'Avatars'}>
                <MenuItem title={'Avatars'} link={`/create-group/${id}/avatars`} />
              </Box>
            );
          }
        })(isParent)}
      </Flex>
    </>
  );
};
