import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

export const learnersApi = createApi({
  reducerPath: 'learnersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),
  tagTypes: ['learners'],
  endpoints: (builder) => ({
    getLearners: builder.query({
      query: ({page, search}) => {
        return {
          url: `/educator/learner?page=${page}&search=${search}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['learners']
    }),
    getLearner: builder.query({
      query: (learnerId) => {
        return {
          url: `/educator/learner/${learnerId}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['learners']
    }),
  }),
});

export const {
  useGetLearnerQuery,
  useGetLearnersQuery,
} = learnersApi;