import {Route, Switch, useParams, useRouteMatch} from 'react-router-dom';
import {useGetCourseByIdQuery} from 'services/coursesService';
import {Details, Menu, StatusChanges, Topics, Versions} from './components';
import {NewTopic} from 'pages/topic';
import {Header, LoaderSpinner} from 'common';
import {CourseValidationErrorList} from './components/CourseValidationErrors/CourseValidationErrorList';

export const Course = () => {
  const {id} = useParams<{id: string; version?: string}>();
  const {path} = useRouteMatch();

  const {data: course, isLoading} = useGetCourseByIdQuery({id});

  if (isLoading) return <LoaderSpinner />;

  return (
    <>
      <Header isCourse />

      <Menu version={course.version} />

      <Switch>
        <Route exact path={path} render={() => <Details id={course.id} versionEntityId={course.versionEntityId} />} />
        <Route exact path={`${path}/course-errors`} render={() => <CourseValidationErrorList courseId={course.id} />} />
        <Route exact path={`${path}/status-changes`} render={() => <StatusChanges courseId={course.id} />} />
        <Route exact path={`${path}/versions`} render={() => <Versions courseId={course.versionEntityId} />} />
        <Route exact path={`${path}/topics`} render={() => <Topics id={course.id} />} />
        <Route exact path={`${path}/topics-archived`} render={() => <Topics id={course.id} isArchived />} />
        <Route exact path={`${path}/create-topic`} component={NewTopic} />
      </Switch>
    </>
  );
};
