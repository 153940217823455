import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {Header} from 'common';
import {Details, KeyFact, KeyFacts, Lessons, Menu, NewKeyFact} from './components';
import {NewLesson} from 'pages/lesson';
import {useParamId} from 'utils';

export const Topic = () => {
  const {path, url} = useRouteMatch();
  const id = useParamId();

  return (
    <>
      <Header isCourse isTopic />

      <Menu />

      <Switch>
        <Route exact path={path} render={() => <Details id={id} />} />

        <Route exact path={`${path}/keyfacts`} render={() => <KeyFacts id={id} baseUrl={url} />} />
        <Route exact path={`${path}/keyfact/:id/`} render={() => <KeyFact topicId={id} baseUrl={url} />} />

        <Route exact path={`${path}/create-keyfact`} render={() => <NewKeyFact baseUrl={url} id={id} />} />

        <Route exact path={`${path}/lessons`} render={() => <Lessons baseUrl={url} topicId={id} />} />
        <Route
          exact
          path={`${path}/lessons-archived`}
          render={() => <Lessons baseUrl={url} topicId={id} isArchived />}
        />

        <Route exact path={`${path}/new-lesson`} component={NewLesson} />
      </Switch>
    </>
  );
};
