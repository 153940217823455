import {useState} from 'react';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spacer,
  Switch,
  Text,
} from '@chakra-ui/react';
import {SharedWrapper} from 'common';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {QuestionHeader, questionTypesSelect} from 'pages/question/common';

export const Details = ({questionId, question}: any) => {
  const {questionCondition, acceptability, timeLimit, isMandatory, questionTypeId} = question;

  const [inputLengthCondition, setInputLengthCondition] = useState(question.questionCondition.length);
  const maxLengthCondition = 65;

  const [isTimeLimit, setTimeLimit] = useState(Boolean(timeLimit));
  const timeLimitHandler = () => setTimeLimit((prev) => !prev);

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<any>({
    defaultValues: {
      questionCondition,
      acceptability,
      timeLimit,
      questionTypeId,
    },
  });

  const [updateQuestion] = useUpdateQuestionMutation();

  const onSubmit = async ({acceptability, questionCondition, isMandatory, questionTypeId, timeLimit}: any) => {
    const updatedQuestion = {
      acceptability,
      questionCondition,
      isMandatory,
      questionTypeId,
      timeLimit: isTimeLimit ? timeLimit : null,
    };
    await updateQuestion({questionId, updatedQuestion});
  };

  const setConditionHandler = () => {
    setValue('questionCondition', 'Fill in the gap');
    setInputLengthCondition(15);
  };

  const isInvalidForm = !!errors.questionCondition || !!errors.acceptability;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Details" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="questionTypeId" color="slate.400">
                  Question Types
                </FormLabel>

                <Select
                  id="questionTypeId"
                  bg="blue.200"
                  {...register('questionTypeId', {
                    required: 'Question Types is required',
                    valueAsNumber: true,
                  })}
                >
                  {Object.entries(questionTypesSelect).map((questionType) => {
                    return (
                      <option key={questionType[0]} value={questionType[0]}>
                        {questionType[1]}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>{errors.questionTypes && errors.questionTypes.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="questionCondition" color="slate.400">
                  Condition
                </FormLabel>

                <Input
                  id="questionCondition"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('questionCondition', {
                    required: 'Condition is required',
                    maxLength: {value: maxLengthCondition, message: 'Too long title'},
                    onChange: (e) => setInputLengthCondition(e.target.value.length),
                  })}
                />
                <FormErrorMessage>{errors.questionCondition && errors.questionCondition.message}</FormErrorMessage>
                <Text>
                  {inputLengthCondition} of {maxLengthCondition} available characters
                </Text>
              </Box>

              <Button onClick={setConditionHandler}>Set &quot;Fill in the gap&quot; condition</Button>

              <Box>
                <FormLabel htmlFor="acceptability" color="slate.400">
                  Acceptability
                </FormLabel>

                <Input
                  type="number"
                  id="acceptability"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('acceptability', {
                    required: 'Acceptability is required',
                    valueAsNumber: true,
                  })}
                />
                <FormErrorMessage>{errors.acceptability && errors.acceptability.message}</FormErrorMessage>
              </Box>
              <Box>
                <Flex justify="space-between" align="baseline">
                  <FormLabel htmlFor="timeLimit" color="slate.400">
                    Time limit
                  </FormLabel>

                  <Switch id="isTimeLimit" isChecked={isTimeLimit} onChange={timeLimitHandler} />
                </Flex>

                <Input
                  type="number"
                  id="timeLimit"
                  bg="blue.200"
                  autoComplete="off"
                  disabled={!isTimeLimit}
                  {...register('timeLimit', {
                    required: 'Time limit is required',
                    valueAsNumber: true,
                  })}
                />
                <FormErrorMessage>{errors.timeLimit && errors.timeLimit.message}</FormErrorMessage>
              </Box>

              <Box>
                <Flex justify="space-between" align="baseline">
                  <FormLabel htmlFor="isMandatory" color="slate.400">
                    Is it mandatory?
                  </FormLabel>
                  <Switch id="isMandatory" defaultIsChecked={isMandatory} {...register('isMandatory')} />
                </Flex>
                <FormErrorMessage>{errors.isMandatory && errors.isMandatory.message}</FormErrorMessage>
              </Box>

              <Spacer />
            </FormControl>
            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
