import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDebounce} from 'use-debounce';
import * as R from 'ramda';
import styled from '@emotion/styled';
import {Box, Button, Flex, Text} from '@chakra-ui/react';
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import {useGetKeyfactsLessonsByIdQuery, useUpdateLessonKeyFactOrderMutation} from 'services/lessonsService';
import {KeyFactsItem} from './KeyFactsItem';
import {IsErrorMessage, LoaderSpinner, SharedWrapper} from 'common';
import {ReactComponent as keyFactsIcon} from 'assets/svg/clock.svg';

export const KeyFacts = ({lessonId, baseUrl}: any) => {
  const history = useHistory();
  const {data, isLoading, error} = useGetKeyfactsLessonsByIdQuery(lessonId);
  const [items, setItems] = useState<any>(null);
  const [updateLessonKeyFactOrder] = useUpdateLessonKeyFactOrderMutation();

  const sortedData =
    data && [...data].sort((a: any, b: any) => a.orderIndex - b.orderIndex);

  const [value] = useDebounce(items, 200);

  const updateOrder = useCallback(
    (items, value) => {
      if (items) {
        const isChanged = !R.equals(items, sortedData);

        if (value && isChanged) {
          const orderCombination = value.reduce(
            (acc: any, item: any, idx: number) => [
              ...acc,
              {keyFactId: item.id, orderIndex: idx},
            ],
            []
          );

          updateLessonKeyFactOrder({id: lessonId, order: {orderCombination}});
        }
      }
    },
    [items, value]
  );
  useEffect(() => {
    if (!isLoading && data) {
      setItems(sortedData);
    }

    return () => {
      setItems(null);
    };
  }, [isLoading, data]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    updateOrder(items, value);

    return () => {
      updateOrder(items, value);
    };
  }, [value]);

  if (isLoading) return <LoaderSpinner />;

  const createKeyfactHandler = () => {
    history.push(`${baseUrl}/create-keyfact`);
  };

  return (
    <>
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <KeyFactsIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Key facts
          </Text>
        </Flex>

        <IsErrorMessage error={error} />
        {!isLoading && items && (
          <>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {items.map(({id, title}: any) => (
                  <KeyFactsItem
                    key={id}
                    id={id}
                    title={title}
                    baseUrl={baseUrl}
                  />
                ))}
              </SortableContext>
            </DndContext>

            {!data.length && (
              <Text color="slate.800" p={5}>
                No keyfacts yet
              </Text>
            )}
          </>
        )}
      </SharedWrapper>

      <Box width="80%" mx="auto" mt="20px" mb="40px">
        <Button onClick={createKeyfactHandler}>Create new keyfact</Button>
      </Box>
    </>
  );
  function handleDragEnd(event: any) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items: any) => {
        const oldIndex = items.findIndex(({id}: any) => id === active.id);
        const newIndex = items.findIndex(({id}: any) => id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
};

const KeyFactsIcon = styled(keyFactsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
