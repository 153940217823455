import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

export const avatarsApi = createApi({
  reducerPath: 'avatarsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),
  tagTypes: ['avatars', 'avatarGroups'],
  endpoints: (builder) => ({
    getGroups: builder.query({
      query: () => {
        return {
          url: '/learner/avatar_group',
          method: 'GET',
        };
      },
      providesTags: ['avatarGroups'],
    }),
    getGroup: builder.query({
      query: (id) => {
        return {
          url: `/learner/avatar_group/${id}`,
          method: 'GET',
        };
      },
      providesTags: ['avatarGroups'],
    }),
    createNewGroup: builder.mutation({
      query: (groupData) => {
        return {
          url: '/educator/avatar_group',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          method: 'POST',
          body: groupData,
        };
      },
      invalidatesTags: ['avatarGroups'],
    }),
    updateGroup: builder.mutation({
      query: ({id, groupData}) => {
        return {
          url: `/educator/avatar_group/${id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          method: 'PATCH',
          body: {...groupData},
        };
      },
      invalidatesTags: ['avatarGroups'],
    }),
    updateGroupsOrder: builder.mutation({
      query: (orderCombination) => {
        return {
          url: '/educator/cm/avatar_group',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          method: 'POST',
          body: orderCombination,
        };
      },
      invalidatesTags: ['avatarGroups'],
    }),
    deleteGroup: builder.mutation({
      query: (id) => {
        return {
          url: `/educator/avatar_group/${id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          method: 'DELETE',
        };
      },
      invalidatesTags: ['avatarGroups'],
    }),

    getAvatars: builder.query({
      query: (groupId) => {
        return {
          url: `/learner/avatar_group/${groupId}/avatar`,
          method: 'GET',
        };
      },
      providesTags: ['avatars'],
    }),
    getAvatar: builder.query({
      query: ({groupId, avatarId}) => {
        return {
          url: `/learner/avatar_group/${groupId}/avatar/${avatarId}`,
          method: 'GET',
        };
      },
      providesTags: ['avatars'],
    }),
    createNewAvatar: builder.mutation({
      query: ({avatar_group_id, avatarData}) => {
        return {
          url: `/educator/avatar_group/${avatar_group_id}/avatar`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          method: 'POST',
          body: {...avatarData},
        };
      },
      invalidatesTags: ['avatars'],
    }),
    updateAvatar: builder.mutation({
      query: ({groupId, avatarId, avatarData}) => {
        return {
          url: `/educator/avatar_group/${groupId}/avatar/${avatarId}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          method: 'PATCH',
          body: avatarData,
        };
      },
      invalidatesTags: ['avatars'],
    }),
    updateAvatarsOrder: builder.mutation({
      query: ({avatar_group_id, orderCombination}) => {
        return {
          url: `/educator/cm/avatar_group/${avatar_group_id}/avatar`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          method: 'POST',
          body: {orderCombination},
        };
      },
      invalidatesTags: ['avatars'],
    }),
    deleteAvatar: builder.mutation({
      query: ({groupId, avatarId}) => {
        return {
          url: `/educator/avatar_group/${groupId}/avatar/${avatarId}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          method: 'DELETE',
        };
      },
      invalidatesTags: ['avatars'],
    })
  }),
});

export const {
  useGetGroupsQuery,
  useGetGroupQuery,
  useCreateNewGroupMutation,
  useUpdateGroupMutation,
  useDeleteGroupMutation,
  useUpdateGroupsOrderMutation,

  useGetAvatarsQuery,
  useGetAvatarQuery,
  useCreateNewAvatarMutation,
  useUpdateAvatarMutation,
  useDeleteAvatarMutation,
  useUpdateAvatarsOrderMutation,
} = avatarsApi;