// import {
//   useGetAvatarsQuery,
//   useGetGroupQuery,
// } from 'services/avatarService';
import styled from '@emotion/styled';
import {Menu, Header} from './components';
import {LoaderSpinner} from '../../common/LoaderSpinner';
import {useParamId} from 'utils';
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react';
import {SharedWrapper, IsErrorMessage} from 'common';
import {ReactComponent as levelIcon} from 'assets/svg/book.svg';

export const NotificationEdit = () => {
  // const id = useParamId();

  // const {
  //   data,
  //   error,
  //   isLoading
  // } = useGetAvatarsQuery(id);

  // if (isLoading) {
  //   return <LoaderSpinner />;
  // }

  return (
    <>
      <Header isAvatars={true} />
      <Menu />
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <LevelIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Edit Notification
          </Text>
        </Flex>

      </SharedWrapper>
    </>
  );
};

const LevelIcon = styled(levelIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
