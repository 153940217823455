import {createStandaloneToast} from '@chakra-ui/react';
import {mainTheme} from 'theme';

export const valueIsImage = (image: File) => {
  const toast = createStandaloneToast({theme: mainTheme});

  const allowedExtensions = ['png', 'jpg', 'jpeg', 'gif', 'tiff', 'bpg'];
  const isAllowedExtension = allowedExtensions.find((ext) => image.name.includes(ext));

  if (!isAllowedExtension) {
    toast({
      title: 'Incorrect file',
      description: 'Please upload an image with the extension: "png", "jpg", "jpeg", "gif", "tiff" or "bpg"',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    return false;
  }

  const imgSize = Math.floor(image.size / (1024 * 1024));
  const isImageSizeLessThen15mb = imgSize < 15;

  if (!isImageSizeLessThen15mb) {
    toast({
      title: 'Size limit.',
      description: 'File size exceeds 15 MB',
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
    return false;
  }

  return true;
};
