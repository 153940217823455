import {Fragment} from 'react';
import {Box, Flex} from '@chakra-ui/react';
import {MenuItem} from './MenuItem';

type MenuProps = {
  baseUrl: string;
  links: {title: string; value: string}[];
  separatorAfterElement: number;
};

export const SharedMenu = ({
  baseUrl,
  links,
  separatorAfterElement,
}: MenuProps) => {
  return (
    <>
      <Flex justify="space-between" align="center" px="10vw" py="15">
        <Flex
          justify="space-between"
          align="center"
          flexBasis="70%"
          height="25px"
        >
          {links.map(({value, title}, idx: number) => {
            if (separatorAfterElement && idx === separatorAfterElement - 1)
              return (
                <Fragment key={idx}>
                  <MenuItem title={title} link={baseUrl + value} />
                  <Separator />
                </Fragment>
              );

            return (
              <MenuItem key={title} title={title} link={baseUrl + value} />
            );
          })}
        </Flex>
      </Flex>
    </>
  );
};

const Separator = () => <Box bg="slate.400" w="1px" height="80%" />;
