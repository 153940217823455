import {useEffect, useState} from 'react';
import {Button, Text} from '@chakra-ui/react';
import {useGetLessonsByTopicIdQuery} from 'services/lessonsService';
import {Level} from './Level';
import {LoaderSpinner, SharedWrapper} from 'common';
import { LevelModel } from '../../../../models';

export interface LessonsCollection {
  topicId: number;
  isArchived?: boolean;
  baseUrl?: string;
}

export const Lessons = ({topicId, isArchived}: LessonsCollection) => {
  const {data: lessons, isLoading: isLoadingLessons} = useGetLessonsByTopicIdQuery({id: topicId, isArchived});

  const [levels, setLevels] = useState<any>();
  const [levelCount, setLevelCount] = useState(0);

  useEffect(() => {
    if (!isLoadingLessons) {
      setLevels(lessons.levels);
      setLevelCount(lessons.levelCount);
    }
  }, [isLoadingLessons, lessons]);

  if (isLoadingLessons) {
    return <LoaderSpinner />;
  }


  const createNewLevel = () => {
    if (levels) {
      const newLevel = levelCount + 1;
      setLevelCount(newLevel);

      setLevels((prev: LevelModel[]) => [
        ...prev,
        {
          level: newLevel,
          lessons: [],
        },
      ]);
    }
  };

  return (
    <>
      {levels &&
        levels.map((level: LevelModel) => (
          <Level
            topicId={topicId}
            key={level.level}
            level={level.level}
            lessons={level.lessons}
            isArchived={isArchived}
          />
        ))}

      {!levels && (
        <SharedWrapper>
          <Text color="slate.800" p={5}>
            No {isArchived ? 'archived' : 'created'} lessons
          </Text>
        </SharedWrapper>
      )}

      <SharedWrapper p="5" my="5">
        <Button size="md" variant="solid" onClick={createNewLevel}>
          Create new Level
        </Button>
      </SharedWrapper>
    </>
  );
};
