import {useHistory} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer, useToast} from '@chakra-ui/react';
import {ImageContentInput, SharedWrapper} from 'common';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {QuestionHeader} from 'pages/question/common';
// import {nanoidInt} from 'utils';

export interface NewBinComponentModel {
  baseUrl: string | null;
  questionId: number;
  question: any;
}


export const NewBin = ({baseUrl, questionId, question}: NewBinComponentModel) => {
  const history = useHistory();
  const toast = useToast();

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    control,
    setValue,
    reset,
  } = useForm<any>();

  const [updateQuestion] = useUpdateQuestionMutation();

  const onSubmit = async ({title, imageContent}: any) => {
    const bins = question.questionPayload?.Bins || [];

    const binId = bins.length === 0 ? 0 : bins.length;
    const updatedBins = [...bins, {Id: binId, Title: title, assetURL: imageContent || null}];

    const updatedQuestion = {
      questionPayload: {...question.questionPayload, Bins: updatedBins},
    };

    const result = (await updateQuestion({questionId, updatedQuestion})) as any;
    reset({title: ''});

    if (result.data.success) {
      toast({title: 'Success', description: 'New bin added', status: 'success', duration: 3000, isClosable: true});
      history.push(baseUrl + '/parameters');
    }
  };

  const isInvalidForm = !!errors.title;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Bin" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="title" color="slate.400">
                Title
              </FormLabel>

              <Input
                id="title"
                bg="blue.200"
                autoComplete="off"
                {...register('title', {
                  required: 'Title is required',
                })}
              />
              <FormErrorMessage>{errors.title && errors.title.message}</FormErrorMessage>
            </Box>

            <Spacer />

            <Box>
              <FormLabel htmlFor="imageContent" color="slate.400">
                Image content
              </FormLabel>
              <Controller
                name="imageContent"
                control={control}
                render={({field: {value}}) => (
                  <ImageContentInput setValue={setValue} value={value} inputName="imageContent" imgLink={''} />
                )}
              />

              <FormErrorMessage>{errors.imageContent && errors.imageContent.message}</FormErrorMessage>
            </Box>
            <Button type="submit" isLoading={isSubmitting} mt="10">
              Create bin
            </Button>
          </FormControl>
        </Form>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
