import {Table, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import {QuestionHeader} from 'pages/question/common';
import {SharedWrapper} from 'common';
import {useGetExplanationTypesQuery} from 'services/questionsService';

export const Parameters = () => {
  const {data, isLoading} = useGetExplanationTypesQuery('');
  const explanationTypes = data?.filter(({type}: any) => type === 'common');

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title={'Explanations'} />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th>Case</Th>
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              explanationTypes.map(
                ({explanation_type_description, title}: any) => (
                  <Tr key={title}>
                    <Td>{explanation_type_description}</Td>
                    <Td>{title}</Td>
                  </Tr>
                )
              )}
          </Tbody>
        </Table>
      </SharedWrapper>
    </>
  );
};
