import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer} from '@chakra-ui/react';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {LoaderSpinner, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {useParamId} from 'utils';

export const Case = ({questionId, question}: any) => {
  const [updateQuestion] = useUpdateQuestionMutation();

  const id = useParamId();

  const caseElem = question.questionPayload.cases.find((elem: any) => +elem.id === +id);

  if (!caseElem) return <LoaderSpinner />;

  const {insight} = caseElem;

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>({
    defaultValues: {insight},
  });

  const isInvalidForm = !!errors.insight;

  const onSubmit = async ({insight}: any) => {
    const updatedCases = question.questionPayload.cases.map((elem: any) =>
      +elem.id === +id
        ? {
          case: elem.case,
          id,
          insight,
        }
        : elem
    );

    const updatedQuestion = {
      questionPayload: {cases: updatedCases, isMultiple: question.questionPayload.isMultiple},
    };

    await updateQuestion({questionId, updatedQuestion});
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="First Column Element" />

        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="insight" color="slate.400">
                Insight
              </FormLabel>

              <Input
                id="insight"
                bg="blue.200"
                autoComplete="off"
                {...register('insight', {
                  required: 'Title is required',
                })}
              />
              <FormErrorMessage>{errors.insight && errors.insight.message}</FormErrorMessage>
            </Box>
          </FormControl>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting} mt="10">
            Save changes
          </Button>
        </Form>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
