import {ChangeEvent, useEffect} from 'react';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spacer,
  Switch
} from '@chakra-ui/react';
import {ImageContentInput, LoaderSpinner, SharedWrapper} from 'common';
import {useGetAnswerIdQuery, useUpdateAnswerMutation} from 'services/questionsService';
import {QuestionHeader} from 'pages/question/common';
import {useParamId} from 'utils';

export const Answer = ({question}: any) => {
  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    control,
    setValue,
  } = useForm<any>();

  const id = useParamId();

  const {data: answer, isLoading} = useGetAnswerIdQuery(id);
  const [updateAnswer] = useUpdateAnswerMutation();

  const {imgOrders} = question.questionPayload;

  useEffect(() => {
    if (!isLoading && answer) {
      setValue('answer', answer.title);
      setValue('imageContent', answer.assetURL);
      setValue('order', answer.payload?.Order);
    }
  }, [answer]);

  const handleAllowEmptyTitle = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setValue('answer', 'U+2800');
    } else {
      setValue('answer', answer.title);
    }
  };

  const onSubmit = async (data: any) => {
    const order = imgOrders.find(({Order}: any) => +Order === +data.order);

    const updatedAnswer = {
      title: data.answer,
      payload: order,
      assetURL: data.imageContent,
    };
    await updateAnswer({answerId: id, updatedAnswer});
  };

  if (isLoading) return <LoaderSpinner />;

  const isInvalidForm = !!errors.answer || !!errors.imageContent;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title={'Answer details'} />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>

                <Input
                  id="answer"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('answer', {
                    required: 'Answer is required',
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
              </Box>

              <Box>
                <Flex justify="space-between" align="baseline">
                  <FormLabel htmlFor="allowEmptyTitle" color="slate.400">
                    Allow empty title ?
                  </FormLabel>
                  <Switch id="allowEmptyTitle" defaultIsChecked={answer.title === 'U+2800'} onChange={handleAllowEmptyTitle} />
                </Flex>
              </Box>

              <Box>
                <FormLabel htmlFor="order" color="slate.400">
                  Order
                </FormLabel>

                <Select
                  id="order"
                  bg="blue.200"
                  {...register('order', {
                    required: 'order is required',
                    valueAsNumber: true,
                  })}
                >
                  {imgOrders.map(({Order: order}: any) => {
                    return (
                      <option key={order} value={order}>
                        {order}
                      </option>
                    );
                  })}
                </Select>
              </Box>

              <Box>
                <FormLabel htmlFor="imageContent" color="slate.400">
                  Image content
                </FormLabel>
                <Controller
                  name="imageContent"
                  control={control}
                  render={({field: {value}}) => (
                    <ImageContentInput
                      inputName="imageContent"
                      value={value}
                      imgLink={answer.assetURL}
                      setValue={setValue}
                    />
                  )}
                />

                <FormErrorMessage>{errors.imageContent && errors.imageContent.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
