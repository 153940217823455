import {Controller, useForm} from 'react-hook-form';
import {Redirect} from 'react-router-dom';
import styled from '@emotion/styled';
import {CreatableSelect} from 'chakra-react-select';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Text,
  Textarea,
} from '@chakra-ui/react';
import {useCreateCourseMutation} from 'services/coursesService';
import {useGetTopicsTagsQuery} from 'services/topicsService';
import {Header, LoaderSpinner, SharedWrapper} from 'common';
import {NewCourseData, OptionType, Tag} from 'models';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';

export const NewCourse = () => {
  const {data: courseTags, isLoading: isLoadingTopicsTags} =
    useGetTopicsTagsQuery('');
  const [createCourse, {data: createdCourseData, isSuccess}] =
    useCreateCourseMutation();

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<NewCourseData>();

  const onSubmit = async (courseData: NewCourseData) => {
    await createCourse({courseData});
  };

  if (isSuccess) {
    return <Redirect to={`/course/${createdCourseData.data.id}`} />;
  }

  if (isLoadingTopicsTags) return <LoaderSpinner />;

  const topicsTagsFiltered = courseTags ?? []
    .filter((tag: Tag) => tag.is_enabled)
    .map(({tag_value}: Tag) => ({
      label: tag_value,
      value: tag_value,
    }));

  const isInvalidForm = !!errors.title || !!errors.description || !!errors.tags;

  return (
    <>
      <Header />

      <SharedWrapper my="40px" p="40px">
        <Flex align="center" mb="5">
          <DetailsIcon />

          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Course details
          </Text>
        </Flex>
        <Box>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="title" color="slate.400">
                  Title
                </FormLabel>

                <Input
                  id="title"
                  bg="blue.200"
                  {...register('title', {
                    required: 'Title is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.title && errors.title.message}
                </FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="description" color="slate.400">
                  Description
                </FormLabel>
                <Textarea
                  id="description"
                  bg="blue.200"
                  {...register('description', {
                    required: 'Description is required',
                  })}
                />
                <FormErrorMessage>
                  {errors.description && errors.description.message}
                </FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="tags" color="slate.400">
                  Tags
                </FormLabel>
                <Controller
                  name="tags"
                  control={control}
                  rules={{required: 'Tags is required'}}
                  render={({field: {onChange, onBlur}}) => (
                    <CreatableSelect
                      options={topicsTagsFiltered}
                      placeholder={'Choose...'}
                      isMulti
                      onChange={(options: OptionType[]) =>
                        onChange(
                          options?.map((option: OptionType) => option.value)
                        )
                      }
                      onBlur={onBlur}
                    />
                  )}
                />
                <FormErrorMessage>
                  {errors.tags && 'Tags is required'}
                </FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Create course
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
