import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const coursesApi = createApi({
  reducerPath: 'coursesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),
  tagTypes: ['courses'],

  endpoints: (builder) => ({
    getCourses: builder.query({
      query: (isArchived) => {
        const filter = isArchived ? '&filter=archived' : '';

        return {
          url: `/educator/cm/courses?limit=100&offset=0${filter}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['courses'],
    }),

    archiveCourse: builder.mutation({
      query: (id) => {
        return {
          url: `/educator/cm/courses/${id}?cmd=archive`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['courses'],
    }),
    unarchiveCourse: builder.mutation({
      query: (id) => {
        return {
          url: `/educator/cm/courses/${id}?cmd=un_archive`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['courses'],
    }),
    getCourseById: builder.query({
      query: ({id, version}) => {
        const filterVersion = version ? `?versions=true&version_number=${version}` : '';
        return {
          url: `/educator/cm/courses/${id}${filterVersion}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['courses'],
    }),
    getCourseStatuses: builder.query({
      query: () => {
        return {
          url: '/educator/cm/course-status-types',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['courses'],
    }),

    checkCourse: builder.query({
      query: (id) => {
        return {
          url: `/educator/cm/check-course-props/${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['courses'],
    }),

    getCourseStatusesById: builder.query({
      query: (id) => {
        return {
          url: `/educator/cm/course-statuses?course_id=${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['courses'],
    }),
    getCourseVersions: builder.query({
      query: (id) => {
        return {
          url: `/educator/cm/course-versions?course_id=${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['courses'],
    }),
    updateCourse: builder.mutation({
      query: ({id, courseData}) => {
        return {
          url: `/educator/cm/courses/${id}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: courseData,
        };
      },
      invalidatesTags: ['courses'],
    }),
    createNewVersionCourse: builder.mutation({
      query: ({id, courseData}) => {
        return {
          url: `/educator/cm/courses/${id}`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: courseData,
        };
      },
      invalidatesTags: ['courses'],
    }),
    createCourse: builder.mutation({
      query: ({courseData}) => {
        return {
          url: '/educator/cm/courses/',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: courseData,
        };
      },
      invalidatesTags: ['courses'],
    }),
  }),
});

export const {
  useGetCoursesQuery,
  useGetCourseStatusesQuery,
  useGetCourseStatusesByIdQuery,
  useGetCourseByIdQuery,
  useCheckCourseQuery,
  useGetCourseVersionsQuery,
  useCreateNewVersionCourseMutation,
  useArchiveCourseMutation,
  useUnarchiveCourseMutation,
  useUpdateCourseMutation,
  useCreateCourseMutation,
} = coursesApi;
