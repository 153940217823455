import {useHistory} from 'react-router-dom';
import {Avatar, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Skeleton, Text} from '@chakra-ui/react';
import {useAuthMeQuery, useLogoutUserMutation} from 'services/authService';
import {CoursesIcon} from 'common';
import {BreadcrumbsProps} from 'models';
import {Breadcrumbs} from './Breadcrumbs';
import {UpdateNavigation} from '../../../../common/SharedHeader/UpdateNavigation';
import {MenuIcon} from '../../../../common/SharedHeader/MenuIcon';

export const Header = (props: any) => {
  const history = useHistory();

  const {data: user, isLoading} = useAuthMeQuery('');
  const [logout] = useLogoutUserMutation();

  const logoutHandler = async () => {
    const isLogoutConfirmed = confirm('Are you sure you want to log out of your account?');

    if (isLogoutConfirmed) {
      await logout('');
      history.push('/logout');
    }
  };

  const {lastName, firstName} = user ?? '';
  const fullName = firstName + ' ' + lastName;

  return (
    <>
      <UpdateNavigation />

      <Flex justify="space-between" align="center" px="10vw" py="10px" bg="white">
        <Flex align="center">
          <CoursesIcon />
          <Breadcrumbs {...props} />
        </Flex>

        <Flex align="center">
          {isLoading ? (
            <Skeleton>..............</Skeleton>
          ) : (
            <Text fontWeight="medium" fontSize="16" color="slate.800">
              {fullName}
            </Text>
          )}
          <Text fontWeight="medium" fontSize="16" onClick={()=> {
            history.push('/level-feedback');
          }} color="slate.800" textDecoration={'underline'}>
              FEEDBACK
          </Text>
          <Avatar size="sm" name={isLoading ? '' : fullName} ml="10px" mr="20px" />
          <Menu>
            {({isOpen}) => (
              <>
                <MenuButton
                  as={IconButton}
                  colorScheme="blue.700"
                  icon={<MenuIcon />}
                  variant="unstyled"
                  size="sm"
                  isActive={isOpen}
                />
                <MenuList>
                  <MenuItem onClick={logoutHandler}>Logout</MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
        </Flex>
      </Flex>
    </>
  );
};
