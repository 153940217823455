import {Fragment} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {Badge, Box, Flex} from '@chakra-ui/react';
import {MenuItem} from 'common';

const links = [
  {
    title: 'details',
    value: '',
  },
  {
    title: 'VALIDATION ERRORS',
    value: '/course-errors',
  },
  {
    title: 'STATUS CHANGES',
    value: '/status-changes',
  },
  {
    title: 'VERSIONS',
    value: '/versions',
  },
  {
    title: 'TOPICS',
    value: '/topics',
  },
  {
    title: 'ARCHIVED',
    value: '/topics-archived',
  },
] as const;

const separatorAfterElement = 3;

type CourseMenuProps = {version: string};

export const Menu = ({version}: CourseMenuProps) => {
  const {url: baseUrl} = useRouteMatch();

  return (
    <>
      <Flex justify="space-between" align="center" px="10vw" py="15">
        <Flex
          justify="space-between"
          align="center"
          flexBasis="70%"
          height="25px"
        >
          {links.map(({value, title}, idx: number) => {
            if (separatorAfterElement && idx === separatorAfterElement - 1)
              return (
                <Fragment key={idx}>
                  <MenuItem title={title} link={baseUrl + value} />
                  <Separator />
                </Fragment>
              );

            return (
              <MenuItem key={title} title={title} link={baseUrl + value} />
            );
          })}
        </Flex>

        <Badge
          color="blue.700"
          fontWeight="500"
          fontSize="16"
          background="blue.400"
          variant="solid"
        >
          Version {version}
        </Badge>
      </Flex>
    </>
  );
};

export const Separator = () => <Box bg="slate.400" w="1px" height="80%" />;
