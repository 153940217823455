import {Route, useRouteMatch} from 'react-router-dom';
import {useGetQuestionByIdQuery} from 'services/questionsService';
import {Answer, Answers, Details, NewAnswer, Parameters} from './components';
import {useParamId} from 'utils';

export const Trivia = () => {
  const {url} = useRouteMatch();
  const questionId = useParamId();

  const {data: question} = useGetQuestionByIdQuery(questionId);

  return (
    <>
      <Route exact path={url} render={() => <Details questionId={questionId} question={question} />} />

      <Route
        exact
        path={url + '/parameters'}
        render={() => <Parameters questionId={questionId} question={question} />}
      />

      <Route exact path={url + '/new-answer'} render={() => <NewAnswer questionId={questionId} baseUrl={url} />} />
      <Route exact path={url + '/answers'} render={() => <Answers questionId={questionId} baseUrl={url} />} />

      <Route exact path={url + '/answers/:id'} render={() => <Answer />} />
    </>
  );
};
