import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const topicsApi = createApi({
  reducerPath: 'topicsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),
  tagTypes: ['topics'],

  endpoints: (builder) => ({
    getTopicsByCourseId: builder.query({
      query: ({id, isArchived}) => {
        const filter = isArchived ? '&filter=archived' : '';

        return {
          url: `/educator/cm/topics?course_id=${id}&limit=100&offset=0${filter}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['topics'],
    }),

    getTopicById: builder.query({
      query: (id) => {
        return {
          url: `/educator/cm/topics/${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['topics'],
    }),
    getKeyFactsById: builder.query({
      query: (id) => {
        return {
          url: `educator/cm/topic-key-facts?topic_id=${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['topics'],
    }),
    createTopic: builder.mutation({
      query: (topicData) => {
        return {
          url: '/educator/cm/topics',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: topicData,
        };
      },
      invalidatesTags: ['topics'],
    }),
    archiveTopic: builder.mutation({
      query: (id) => {
        return {
          url: `/educator/cm/topics/${id}?cmd=archive`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['topics'],
    }),
    unArchiveTopic: builder.mutation({
      query: (id) => {
        return {
          url: `/educator/cm/topics/${id}?cmd=un_archive`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['topics'],
    }),
    updateTopic: builder.mutation({
      query: ({id, topicData}) => {
        return {
          url: `/educator/cm/topics/${id}`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: topicData,
        };
      },
      invalidatesTags: ['topics'],
    }),
    createBVersion: builder.mutation({
      query: ({id}) => {
        return {
          url: `/educator/cm/topics/${id}`,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['topics'],
    }),
    createKeyfact: builder.mutation({
      query: (keyfactData) => {
        return {
          url: '/educator/cm/topic-key-facts',
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: keyfactData,
        };
      },
      invalidatesTags: ['topics'],
    }),
    getKeyfact: builder.query({
      query: (id) => {
        return {
          url: `/educator/cm/topic-key-facts/${id}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['topics'],
    }),
    updateKeyfact: builder.mutation({
      query: ({id, keyfactData}) => {
        return {
          url: `/educator/cm/topic-key-facts/${id}`,
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: keyfactData,
        };
      },
      invalidatesTags: ['topics'],
    }),
    deleteKeyfact: builder.mutation({
      query: (id) => {
        return {
          url: `/educator/cm/topic-key-facts/${id}`,
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['topics'],
    }),
    updateKeyfactsOrder: builder.mutation({
      query: ({id, order}) => {
        return {
          url: `/educator/cm/topic-key-facts/${id}`,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: order,
        };
      },
      invalidatesTags: ['topics'],
    }),
    getTopicsTags: builder.query({
      query: () => {
        return {
          url: '/educator/cm/topic-tags?limit=100&offset=0',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetTopicsByCourseIdQuery,
  useGetTopicByIdQuery,
  useGetKeyFactsByIdQuery,
  useUpdateTopicMutation,
  useCreateBVersionMutation,
  useCreateTopicMutation,
  useArchiveTopicMutation,
  useUnArchiveTopicMutation,
  useUpdateKeyfactMutation,
  useCreateKeyfactMutation,
  useDeleteKeyfactMutation,
  useUpdateKeyfactsOrderMutation,
  useGetKeyfactQuery,
  useGetTopicsTagsQuery,
} = topicsApi;
