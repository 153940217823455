import {Center, Spinner} from '@chakra-ui/react';


export const LoaderSpinner = () => {
  return (
    <Center>
      <Spinner
        thickness="5px"
        speed="0.65s"
        emptyColor="blue.200"
        color="blue.700"
        size="xl"
        my="10"
      />
    </Center>
  );
};
