import {Route, useRouteMatch} from 'react-router-dom';
import {useGetQuestionByIdQuery} from 'services/questionsService';
import {Answer, Answers, Details, Gap, NewAnswer, NewGap, Parameters} from './components';
import {useParamId} from 'utils';

export const TextGaps = () => {
  const {url} = useRouteMatch();
  const questionId = useParamId();

  const {data: question} = useGetQuestionByIdQuery(questionId);

  return (
    <>
      <Route exact path={url} render={() => <Details questionId={questionId} question={question} />} />

      <Route
        exact
        path={url + '/parameters'}
        render={() => <Parameters baseUrl={url} question={question} questionId={questionId} />}
      />

      <Route exact path={url + '/new-answer'} render={() => <NewAnswer questionId={questionId} baseUrl={url} />} />

      <Route
        exact
        path={url + '/parameters/gaps/:id'}
        render={() => <Gap questionId={questionId} question={question} baseUrl={url} />}
      />

      <Route
        exact
        path={url + '/parameters/new-gap'}
        render={() => <NewGap questionId={questionId} question={question} baseUrl={url} />}
      />

      <Route exact path={url + '/answers'} render={() => <Answers questionId={questionId} baseUrl={url} />} />

      <Route exact path={url + '/answers/:id'} render={() => <Answer question={question} baseUrl={url} />} />
    </>
  );
};
