import {useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useUpdateQuestionMutation} from 'services/questionsService';
import {ImageContentInput, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';

export const Parameters = ({question, questionId}: any) => {
  const [isMultiple, setIsMultiple] = useState(Boolean(question.questionPayload?.isMultiple));

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    setValue,
  } = useForm<any>({
    defaultValues: {
      imageContent: question.assetURL,
    },
  });

  const [updateQuestion] = useUpdateQuestionMutation();
  const isMultipleHandler = async () => {
    const updatedQuestion = {
      questionPayload: {isMultiple: !isMultiple},
    };
    updateQuestion({questionId, updatedQuestion});

    setIsMultiple((prev) => !prev);
  };
  const onSubmitImage = async ({imageContent}: any) => {
    await updateQuestion({
      questionId,
      updatedQuestion: {assetURL: imageContent},
    });
  };

  const {cases} = question.questionPayload ?? [];

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="U-Question Cases" />

        <Box>
          <Flex justify="space-between" align="baseline">
            <FormLabel htmlFor="muptiple" color="slate.400">
              IS THIS A MULTIPLE-CHOICE QUESTION?
            </FormLabel>

            <Switch id="muptiple" isChecked={isMultiple} onChange={isMultipleHandler} />
          </Flex>
        </Box>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            {cases &&
              cases.map(({case: title, id}: any) => (
                <Tr key={id}>
                  <Td>{title.join('+')}</Td>
                  <Td>
                    <Flex justify="flex-end">
                      <EditButton id={id} />
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Other" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmitImage)} autoComplete="off">
            <FormControl>
              <FormLabel htmlFor="imageContent" color="slate.400">
                Image content
              </FormLabel>
              <Controller
                name="imageContent"
                control={control}
                render={({field: {value}}) => (
                  <ImageContentInput
                    setValue={setValue}
                    inputName="imageContent"
                    value={value}
                    imgLink={question.assetURL}
                  />
                )}
              />

              <FormErrorMessage>{errors.imageContent && errors.imageContent.message}</FormErrorMessage>
            </FormControl>

            <Button isLoading={isSubmitting} type="submit">
              Save
            </Button>
          </Form>
        </Box>
      </SharedWrapper>

      <Box h="10" />
    </>
  );
};

const EditButton = ({id}: any) => {
  const history = useHistory();
  const {url} = useRouteMatch();

  const editHandler = () => {
    history.push(`${url}/cases/${id}`);
  };

  return (
    <Button size="sm" onClick={editHandler} mr="3">
      Edit
    </Button>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
