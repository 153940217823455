import {useHistory} from 'react-router-dom';
import styled from '@emotion/styled';
import {Box, Button, Flex, Table, Tbody, Text, Th, Thead, Tr} from '@chakra-ui/react';
import {useGetCoursesQuery} from 'services/coursesService';
import {CourseItem, Menu} from './components';
import {Header, IsErrorMessage, LoaderSpinner, SharedWrapper} from 'common';
import {CourseProps} from 'models';
import {ReactComponent as bookIcon} from 'assets/svg/book.svg';

type CoursesProps = {isArchived?: boolean};

export const Courses = ({isArchived}: CoursesProps) => {
  const history = useHistory();
  const {data, error, isLoading} = useGetCoursesQuery(isArchived);

  const createCourseHandler = () => {
    history.push('/create-course');
  };

  return (
    <>
      <Header />

      <Menu />

      <SharedWrapper>
        <Flex mx="4" mt="4" mb="6" align="center">
          <BookIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            {isArchived ? 'Archived' : 'My courses'}
          </Text>
        </Flex>

        {isLoading && <LoaderSpinner />}

        <IsErrorMessage error={error} />

        {!isLoading && !error && data && (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>TITLE</Th>
                  <Th>VERSIONS</Th>
                  <Th>CREATED</Th>
                  <Th />
                </Tr>
              </Thead>

              <Tbody>
                {data.map((course: CourseProps) => (
                  <CourseItem
                    key={course.id}
                    id={course.id}
                    title={course.title}
                    createdAt={course.createdAt}
                    versionCountPerCourse={course.versionCountPerCourse}
                    isArchived={isArchived}
                  />
                ))}
              </Tbody>
            </Table>

            {!data.length && (
              <Text color="slate.800" p={5}>
                No {isArchived ? 'archived' : 'created'} courses
              </Text>
            )}
          </>
        )}
      </SharedWrapper>

      <Box width="80%" mx="auto" mt="20px" mb="40px">
        <Button onClick={createCourseHandler}>Create new course</Button>
      </Box>
    </>
  );
};

const BookIcon = styled(bookIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
