import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/dist/query/react';

export const notificationsApi = createApi({
  reducerPath: 'notificationsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL as string,
  }),
  tagTypes: ['notifications'],
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: ({page, learnerId}) => {
        return {
          url: `/educator/notification?page=${page}&learner_id=${learnerId}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['notifications']
    }),
    getNotification: builder.query({
      query: (notificationId) => {
        return {
          url: `/educator/notification/${notificationId}`,
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['notifications']
    }),
    getNotificationMethods: builder.query({
      query: () => {
        return {
          url: '/educator/notification/method',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['notifications']
    }),
    getNotificationTypes: builder.query({
      query: () => {
        return {
          url: '/educator/notification/type',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['notifications']
    }),
    getNotificationStatuses: builder.query({
      query: () => {
        return {
          url: '/educator/notification/status',
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      providesTags: ['notifications']
    }),
    createNotification: builder.mutation({
      query: (data) => {
        return {
          url: '/educator/notification',
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {...data},
        };
      },
      invalidatesTags: ['notifications']
    }),
    editNotification: builder.mutation({
      query: ({notificationId, data}) => {
        return {
          url: `/educator/notification/${notificationId}`,
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: {...data},
        };
      },
      invalidatesTags: ['notifications']
    }),
    deleteNotification: builder.mutation({
      query: (notificationId) => {
        return {
          url: `/educator/notification/${notificationId}`,
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        };
      },
      invalidatesTags: ['notifications']
    }),
  }),
});

export const {
  useGetNotificationQuery,
  useGetNotificationsQuery,
  useCreateNotificationMutation,
  useDeleteNotificationMutation,
  useEditNotificationMutation,
  useGetNotificationMethodsQuery,
  useGetNotificationStatusesQuery,
  useGetNotificationTypesQuery,
} = notificationsApi;