import {MenuItem} from 'common';
import {Box, Flex} from '@chakra-ui/react';
import {useHistory} from 'react-router-dom';

const links = [
  {
    title: 'MY COURSES',
    value: '/courses',
  },
  {
    title: 'ARCHIVED',
    value: '/courses-archived',
  },
  {
    title: 'LEARNERS',
    value: '/learners',
  },
] as const;

export const LearnerMenu = ({id}: any) => {
  const history = useHistory();

  const openNotifications = () => {
    return '/learners/' + String(id) + '/notifications';
  };

  return (
    <>
      <Flex align="center" px="10vw" py="15">
        {links.map(({value, title}) => {
          return (
            <Box mr="5" key={title}>
              <MenuItem title={title} link={value} />
            </Box>
          );
        })}
        <Box mr="5" key={'Notifications'}>
          <MenuItem title={'Notifications'} link={openNotifications()} />
        </Box>
      </Flex>
    </>
  );
};
