import {useHistory, useRouteMatch} from 'react-router-dom';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useGetExplanationTypesQuery, useUpdateQuestionMutation} from 'services/questionsService';
import {ImageContentInput, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {start} from 'repl';

export const Parameters = ({question, questionId}: any) => {
  const history = useHistory();
  const {data, isLoading} = useGetExplanationTypesQuery('');
  const explanationTypes = data?.filter(({type}: any) => type === 'common');
  const {imgOrders} = question.questionPayload || [];

  const [updateQuestion] = useUpdateQuestionMutation();

  const newOrderHandler = () => {
    history.push(`/question/${questionId}/parameters/new-order`);
  };

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
  } = useForm<any>({
    defaultValues: {
      imageContent: question.assetURL,
      startTitle: question.questionPayload?.leftText,
      endTitle: question.questionPayload?.rightText,
    },
  });

  const onSubmit = async ({startTitle, endTitle}: any) => {
    const updatedQuestion = {questionPayload: {...question.questionPayload, leftText: startTitle, rightText: endTitle}};

    await updateQuestion({
      questionId,
      updatedQuestion,
    });
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Explanations" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th>Case</Th>
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              explanationTypes.map(({explanation_type_description, title}: any) => (
                <Tr key={title}>
                  <Td>{explanation_type_description}</Td>
                  <Td>{title}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />
      <SharedWrapper p="10">
        <QuestionHeader title="Orders" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Object</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            {imgOrders &&
              imgOrders.map(({Order: order}: any) => (
                <Tr key={order}>
                  <Td>{order}</Td>
                  <Td>
                    <Flex justify="flex-end">
                      <EditButton id={order} />
                      <DeleteButton id={order} question={question} questionId={questionId} />
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>

        <Button onClick={newOrderHandler} mt="5">
          Create new order
        </Button>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Other" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl>
              <Box>
                <FormLabel htmlFor="startTitle" color="slate.400">
                  Start Title
                </FormLabel>
                <Input id="startTitle" type="startTitle" bg="blue.200" {...register('startTitle')} />
                <FormErrorMessage>{errors.startTitle && errors.startTitle.message}</FormErrorMessage>
              </Box>

              <Box mb="5">
                <FormLabel htmlFor="endTitle" color="slate.400">
                  End Title
                </FormLabel>
                <Input id="endTitle" type="endTitle" bg="blue.200" {...register('endTitle')} />
                <FormErrorMessage>{errors.endTitle && errors.endTitle.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Button type="submit" isLoading={isSubmitting}>
              Save
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DeleteButton = ({id, question, questionId}: any) => {
  const [updateQuestion] = useUpdateQuestionMutation();

  const deleteHandler = async () => {
    const newOrders = question.questionPayload.imgOrders.filter(({Order}: any) => +Order !== +id);

    const updatedQuestion = {
      questionPayload: {...question.questionPayload, imgOrders: newOrders},
    };
    await updateQuestion({questionId, updatedQuestion});
  };

  return (
    <Button size="sm" onClick={deleteHandler} variant="secondaryWarning" mr="3">
      Delete
    </Button>
  );
};

const EditButton = ({id}: any) => {
  const history = useHistory();
  const {url} = useRouteMatch();

  const editHandler = () => {
    history.push(`${url}/order/${id}`);
  };

  return (
    <Button size="sm" onClick={editHandler} mr="3">
      Edit
    </Button>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
