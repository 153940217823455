import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Spacer} from '@chakra-ui/react';
import {useGetAnswerIdQuery, useUpdateAnswerMutation} from 'services/questionsService';
import {LoaderSpinner, SharedWrapper} from 'common';
import {QuestionHeader} from 'pages/question/common';
import {useParamId} from 'utils';
import { useHistory } from 'react-router-dom';
import { UQuestionModel } from '../../../../../models/u.question.model';

export interface AnswerDetailData {
  question: UQuestionModel;
}

export interface AnswerTitle {
  answer: string;
}

export interface UQuestionAnswerFormModel {
  answer: string;
}

export const Answer = ({question}: AnswerDetailData) => {
  const history = useHistory();
  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<UQuestionAnswerFormModel>();

  const id = useParamId();

  const {data: answer, isLoading} = useGetAnswerIdQuery(id);
  const [updateAnswer] = useUpdateAnswerMutation();

  useEffect(() => {
    if (!isLoading && answer) {
      setValue('answer', answer.title);
    }
  }, [answer]);

  const onSubmit = async (data: AnswerTitle) => {
    const updatedAnswer = {
      title: data.answer, //.toUpperCase(),
    };
    await updateAnswer({answerId: id, updatedAnswer});
    history.push(`/question/${question.id}/answers`);
  };

  if (isLoading) return <LoaderSpinner />;

  const isInvalidForm = !!errors.answer;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title={'Answer details'} />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>

                <Input
                  id="answer"
                  bg="blue.200"
                  autoComplete="off"
                  // textTransform="uppercase"
                  {...register('answer', {
                    required: 'Answer is required',
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
