import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {CreatableSelect} from 'chakra-react-select';
import {useParamId} from 'utils';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Spacer,
  Text,
} from '@chakra-ui/react';
import {
  useGetGroupsQuery,
  useGetGroupQuery,
  useUpdateGroupMutation,
} from 'services/avatarService';

import {
  LoaderSpinner,
  SharedWrapper,
  ImageContentInput,
  IsErrorMessage
} from 'common';
import {AvatarGroupChildProps} from 'models';
import {ReactComponent as detailsIcon} from 'assets/svg/tablet.svg';
import {MenuEdit, Header} from './components';

export const AvatarGroupEdit = () => {
  const id = useParamId();
  const {data: groups, error: groupsError , isLoading: isLoadingGroups} = useGetGroupsQuery(null);
  const {data: group, error: groupError, isLoading: isLoadingGroup} = useGetGroupQuery(id);

  const [createCourse, {data: createdGroup, isSuccess}] = useUpdateGroupMutation();

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<AvatarGroupChildProps>();

  const onSubmit = async (avatarGroupData: AvatarGroupChildProps) => {
    await createCourse({groupData: avatarGroupData, id});
  };

  if (isLoadingGroups || isLoadingGroup) return <LoaderSpinner />;

  const groupsFiltered = (groups ?? []).map((group: AvatarGroupChildProps) => {
    if (!group.parent_id) {
      return {
        label: group.label,
        value: group.id,
      };
    }
  });

  const isInvalidForm = !!errors.label;

  return (
    <>
      <Header group={group} />
      <MenuEdit id={id} isParent={group.parent_id} />

      <SharedWrapper my="40px" p="40px">
        <Flex align="center" mb="5">
          <DetailsIcon />

          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Update Group
          </Text>
        </Flex>

        <IsErrorMessage error={groupsError} />
        <IsErrorMessage error={groupError} />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="label" color="slate.400">
                  Label
                </FormLabel>

                <Input
                  id="label"
                  bg="blue.200"
                  {...register('label', {
                    required: 'Label is required',
                  })}
                  defaultValue={group.label}
                />
                <FormErrorMessage>
                  {errors.label && errors.label.message}
                </FormErrorMessage>
              </Box>
              <Box>
                <FormLabel htmlFor="thumbnail_url" color="slate.400">
                  Thumbnail
                </FormLabel>
                <Controller
                  name="thumbnail_url"
                  control={control}
                  defaultValue={group.thumbnail_url}
                  render={({field: {value}}) => (
                    <ImageContentInput value={value} inputName="thumbnail_url" setValue={setValue} />
                  )}
                />

                <FormErrorMessage>{errors.thumbnail_url && errors.thumbnail_url.message}</FormErrorMessage>
              </Box>
              {((parentId) => {
                if (parentId) {
                  return (
                    <Box>
                      <FormLabel htmlFor="parent_id" color="slate.400">
                        Parent Group
                      </FormLabel>
                      <Controller
                        name="parent_id"
                        control={control}
                        rules={{}}
                        render={({field: {onChange, onBlur}}) => (
                          <CreatableSelect
                            options={groupsFiltered}
                            placeholder="Choose..."
                            onChange={
                              (option: any | null) => {
                                console.log(option);
                                if (option !== null) {
                                  onChange(option.value);
                                }
                              }
                            }
                            defaultValue={group.parent_id}
                            onBlur={onBlur}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {errors.parent_id && 'Tags is required'}
                      </FormErrorMessage>
                    </Box>
                  );
                }
              })(group.parent_id)}
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Update
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const DetailsIcon = styled(detailsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
