import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Input, Select, Spacer, useToast} from '@chakra-ui/react';
import {LoaderSpinner, SharedWrapper} from 'common';
import {useGetAnswerIdQuery, useUpdateAnswerMutation} from 'services/questionsService';
import {QuestionHeader} from 'pages/question/common';
import {useParamId} from 'utils';
import {useHistory} from 'react-router-dom';

export const Answer = ({question, baseUrl}: any) => {
  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<any>();

  const id = useParamId();
  const toast = useToast();
  const history = useHistory();

  const {data: answer, isLoading} = useGetAnswerIdQuery(id);
  const [updateAnswer] = useUpdateAnswerMutation();

  useEffect(() => {
    if (!isLoading && answer) {
      const {title} = answer;

      setValue('answer', title);
      setValue('order', answer.payload?.Order);
    }
  }, [answer]);

  const onSubmit = async (data: any) => {
    const gap = question.questionPayload?.find(({Order}: any) => +Order === +data.order);

    const updatedAnswer = {
      title: data.answer,
      payload: gap,
    };
    const result = (await updateAnswer({answerId: id, updatedAnswer})) as any;

    if (result.data.success) {
      toast({
        title: 'Answer edited',
        description: result.data.message,
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
      history.push(baseUrl + '/answers');
    }
  };

  if (isLoading) return <LoaderSpinner />;

  const isInvalidForm = !!errors.answer || !!errors.order;

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title={'Answer details'} />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="answer" color="slate.400">
                  Answer
                </FormLabel>

                <Input
                  id="answer"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('answer', {
                    required: 'Answer is required',
                  })}
                />
                <FormErrorMessage>{errors.answer && errors.answer.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="order" color="slate.400">
                  Order
                </FormLabel>

                <Select
                  id="order"
                  bg="blue.200"
                  {...register('order', {
                    required: 'order is required',
                    valueAsNumber: true,
                  })}
                >
                  {question &&
                    question.questionPayload?.map(({Order: order, LocationX: locationX, LocationY: locationY}: any) => {
                      return (
                        <option key={order} value={order}>
                          {order}. LocationX: {locationX || 'not set'}, LocationY: {locationY || 'not set'}
                        </option>
                      );
                    })}
                </Select>
                <FormErrorMessage>{errors.order && errors.order.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
