import {ChangeEvent, useEffect, useRef, useState} from 'react';
import styled from '@emotion/styled';
import {Box, Button, createStandaloneToast, Flex, Link, Text} from '@chakra-ui/react';
import {useSendAudioTranscriptionMutation} from 'services/filesService';
import {getFilenameFromInput, stopPropagation} from 'utils';
import {mainTheme} from 'theme';

export const AudioTranscriptInput = ({setValue, mediaPath, topicId, lessonId}: any) => {
  const toast = createStandaloneToast({theme: mainTheme});

  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [filename, setFilename] = useState('');
  const [isLinkActive, setLinkActive] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isUploaded, setUploaded] = useState(false);

  const [uploadFile] = useSendAudioTranscriptionMutation();

  useEffect(() => {
    if (mediaPath) {
      setFilename(mediaPath);
      setLinkActive(true);
    }
  }, [mediaPath]);

  const inputHandler = () => {
    if (inputRef.current) inputRef.current.click();
  };

  const deleteHandler = () => {
    setFile(null);
    setFilename('');
    setLinkActive(false);
  };
  const inputOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setUploaded(false);
    if (event.target.files) {
      const newFile: File = event.target.files[0];
      const newFileName = getFilenameFromInput(event.target.value);

      //TODO validate FILE
      // eslint-disable-next-line no-constant-condition
      if (1) {
        setFile(newFile);
        setFilename(newFileName);
        setLinkActive(false);
      }
    }
  };

  const uploadHanlder = async () => {
    setSubmitting(true);
    const result = (await uploadFile({
      topicId,
      lessonId,
      file,
    })) as any;

    if (result) {
      if (result.data.success) {
        setSubmitting(false);
        setUploaded(true);
        const path = result.data.payload.location;
        setValue('audioTranscription', path);
        toast({title: 'Uploaded', status: 'success', duration: 1000, isClosable: true});
      } else {
        setSubmitting(false);
        toast({
          title: 'An error occurred.',
          description: 'Unable to upload file.',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Box my="2">
      <Flex justify="space-between" align="center">
        <Box
          bg="blue.200"
          border="1px solid"
          borderColor="blue.400"
          onClick={inputHandler}
          w="100%"
          p="3"
          mr="5"
          borderRadius="md"
          _hover={{cursor: 'pointer'}}
        >
          {isLinkActive ? (
            <Link href={filename} isExternal onClick={stopPropagation} style={{overflowWrap: 'anywhere'}}>
              {filename}
            </Link>
          ) : (
            <Text>{filename || '⠀'}</Text>
          )}
        </Box>
        {isLinkActive ? (
          <Button onClick={deleteHandler} isLoading={isSubmitting}>
            Delete
          </Button>
        ) : isUploaded ? (
          <Button onClick={uploadHanlder} isLoading={isSubmitting} disabled>
            Uploaded
          </Button>
        ) : (
          <Button onClick={uploadHanlder} isLoading={isSubmitting} disabled={isUploaded}>
            Upload
          </Button>
        )}
      </Flex>

      <Input
        type="file"
        ref={inputRef}
        id="audioPath"
        name="audioPath"
        onChange={inputOnChange}
        style={{display: 'none'}}
      />
    </Box>
  );
};

const Input = styled.input`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 10px;
`;
