import {useHistory} from 'react-router-dom';
import {Button, ButtonGroup, Flex, Td, Tr} from '@chakra-ui/react';
import {useArchiveCourseMutation, useUnarchiveCourseMutation} from 'services/coursesService';
import {CourseProps} from 'models';
import {getDateFromStr} from 'utils';

export const CourseItem = ({title, createdAt, versionCountPerCourse, id, isArchived}: CourseProps) => {
  const history = useHistory();

  const editHandler = () => {
    history.push(`/course/${id}`);
  };

  const createdAtDate = getDateFromStr(createdAt);

  return (
    <Tr>
      <Td>{title}</Td>
      <Td>{versionCountPerCourse}</Td>
      <Td>{createdAtDate}</Td>

      <Td>
        <Flex justify="flex-end">
          <ButtonGroup>
            <Button size="sm" variant="secondary" onClick={editHandler}>
              Edit
            </Button>
            {isArchived ? <UnarchiveButton id={id} /> : <ArchiveButton id={id} />}
          </ButtonGroup>
        </Flex>
      </Td>
    </Tr>
  );
};

const ArchiveButton = ({id}: {id: number}) => {
  const [archiveCourse] = useArchiveCourseMutation();

  const archiveHandler = async () => {
    await archiveCourse(id);
  };

  return (
    <Button size="sm" variant="secondaryWarning" onClick={archiveHandler}>
      Archive
    </Button>
  );
};
const UnarchiveButton = ({id}: {id: number}) => {
  const [unarchiveCourse] = useUnarchiveCourseMutation();

  const unarchiveHandler = async () => {
    await unarchiveCourse(id);
  };

  return (
    <Button size="sm" variant="secondaryWarning" onClick={unarchiveHandler}>
      Unarchive
    </Button>
  );
};
