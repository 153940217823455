import {Td, Tr} from '@chakra-ui/react';
import {StatusItemProps} from 'models';
import {getDateTimeFromStr} from 'utils';

export const StatusItem = ({
  changedAt,
  educator,
  currentStatus,
  previousStatus,
}: StatusItemProps) => {
  const changedAtDateTime = getDateTimeFromStr(changedAt);

  return (
    <Tr>
      <Td>
        {previousStatus
          ? currentStatus.viewValue + ' → ' + previousStatus.viewValue
          : currentStatus.viewValue}
      </Td>
      <Td>
        {educator.first_name} {educator.last_name}
      </Td>
      <Td>{changedAtDateTime}</Td>
      <Td />
    </Tr>
  );
};
