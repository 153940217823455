import React from 'react';
import {Flex, Text} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {ReactComponent as questionIcon} from 'assets/svg/tablet.svg';

export const QuestionHeader = ({title}: any) => {
  return (
    <Flex align="center" mb="5">
      <QuestionIcon />

      <Text fontWeight="medium" fontSize="16" color="slate.900">
        {title}
      </Text>
    </Flex>
  );
};

const QuestionIcon = styled(questionIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
