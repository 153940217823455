import {useForm} from 'react-hook-form';
import {Link, Redirect} from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Link as ChakraLink,
  Spacer,
  Text,
} from '@chakra-ui/react';
import {useAuthUserMutation} from 'services/authService';
import {User} from 'models';
import {ReactComponent as userIcon} from 'assets/svg/user.svg';

export const Login = () => {
  const {
    handleSubmit,
    register,
    formState: {errors, isSubmitting},
  } = useForm<User>();

  const [authUser, {data: responseData, isSuccess, isError}] =
    useAuthUserMutation();

  const onSubmit = async (data: User) => {
    await authUser(data);
  };

  if (isSuccess) {
    localStorage.setItem('token', responseData.accessToken);
    return <Redirect to={'/courses'} />;
  }

  const isInvalidForm = !!errors.email || !!errors.password;

  return (
    <Center w="100vw" h="100vh">
      <Flex
        w="20%"
        height="35%"
        p="15px"
        minWidth="400px"
        direction="column"
        border="1px solid"
        borderColor="slate.200"
        borderRadius="4px"
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Flex align="center">
            <Flex align="center">
              <UserIcon />
              <Text fontSize="16">Log in to your account</Text>
            </Flex>

            <Spacer />

            {isError && (
              <Text fontSize="12" color="red.700">
                Invalid credentials
              </Text>
            )}
          </Flex>
          <Spacer />

          <FormControl isInvalid={isInvalidForm}>
            <Box>
              <FormLabel htmlFor="email" color="slate.400">
                Email address
              </FormLabel>

              <Input
                id="email"
                bg="blue.200"
                {...register('email', {
                  required: 'This is required',
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                  },
                })}
              />

              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </Box>

            <Box>
              <FormLabel htmlFor="password" color="slate.400">
                Password
              </FormLabel>

              <Input
                id="password"
                type="password"
                bg="blue.200"
                {...register('password', {
                  required: 'This is required',
                  minLength: {
                    value: 8,
                    message: 'Minimum length should be 8',
                  },
                })}
              />

              <FormErrorMessage>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </Box>
          </FormControl>

          <Spacer />

          <Button type="submit" isLoading={isSubmitting}>
            Log in
          </Button>
        </Form>

        <Spacer />

        <Center>
          <ChakraLink
            as={Link}
            to={'/register'}
            fontSize="15"
            mt="10px"
            color="slate.800"
          >
            Register
          </ChakraLink>
        </Center>
      </Flex>
    </Center>
  );
};

export const UserIcon = styled(userIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
