import { useHistory } from 'react-router-dom';
import styled from '@emotion/styled';
import { useGetQuestionsQuery, useUpdateQuestionOrderMutation } from 'services/questionsService';
import { Box, Button, Flex, Table, Tbody, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { QuestionItem } from './QuestionItem';
import { IsErrorMessage, LoaderSpinner, SharedWrapper } from 'common';
import { ReactComponent as questionsIcon } from 'assets/svg/book.svg';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import { useDebounce } from 'use-debounce';
import { QuestionBaseModel } from '../../../../models/question.base.model';

export const Questions = ({ lessonId, isArchived }: any) => {
  const history = useHistory();
  const { data, isLoading, error } = useGetQuestionsQuery({ lessonId, isArchived });
  const [items, setItems] = useState<any>(null);
  const [updateQuestionOrder] = useUpdateQuestionOrderMutation();

  const sortedData =
    data && [...data].sort((a: QuestionBaseModel, b: QuestionBaseModel) => a.orderIndex - b.orderIndex);

  const [value] = useDebounce(items, 200);

  const updateOrder = useCallback(
    (items, value) => {
      if (items) {
        const isChanged = !R.equals(items, sortedData);

        if (value && isChanged) {
          const orderCombination = value.reduce(
            (acc: any, item: any, idx: number) => [
              ...acc,
              {questionId: item.id, orderIndex: idx},
            ],
            []
          );

          updateQuestionOrder({id: lessonId, order: {orderCombination}});
        }
      }
    },
    [items, value]
  );
  useEffect(() => {
    if (!isLoading && data) {
      setItems(sortedData);
    }

    return () => {
      setItems(null);
    };
  }, [isLoading, data]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  useEffect(() => {
    updateOrder(items, value);

    return () => {
      updateOrder(items, value);
    };
  }, [value]);

  if (isLoading) return <LoaderSpinner />;

  const createQuestionHandler = () => {
    history.push({ pathname: `/lesson/${lessonId}/new-question`, state: { lessonId } });
  };

  return (
    <>
      <SharedWrapper>
        <Flex mx="4" mt="4" mb="6" align="center">
          <QuestionsIcon />

          <Text fontWeight="medium" fontSize="16" color="slate.900">
            {isArchived ? 'Archived' : 'Questions'}
          </Text>
        </Flex>

        <IsErrorMessage error={error} />

        {!isLoading && !error && data && items && (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Th>ID</Th>
                  <Th>Question</Th>
                  <Th>Type</Th>
                  <Th>Date Created</Th>
                  <Th />
                </Tr>
              </Thead>

              <Tbody>
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                  <SortableContext items={items} strategy={verticalListSortingStrategy}>
                    {items.map((question: any) => (
                      <QuestionItem
                        key={question.id}
                        id={question.id}
                        questionCondition={question.questionCondition}
                        questionTypeId={question.questionTypeId}
                        createdAt={question.createdAt}
                        isArchived={!!question.archivedAt}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              </Tbody>
            </Table>

            {!data.length && (
              <Text color="slate.800" p={5}>
                No {isArchived ? 'archived' : 'created'} questions
              </Text>
            )}
          </>
        )}
        <Box m="4">
          <Button onClick={createQuestionHandler}>Create new question</Button>
        </Box>
      </SharedWrapper>
    </>
  );

  function handleDragEnd(event: any) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items: any) => {
        const oldIndex = items.findIndex(({id}: any) => id === active.id);
        const newIndex = items.findIndex(({id}: any) => id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
};


const QuestionsIcon = styled(questionsIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
