import {useHistory} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  Spacer,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import {useCreateQuestionMutation} from 'services/questionsService';
import {Header, SharedWrapper} from 'common';
import {QuestionHeader, questionTypesSelect} from './common';

export const NewQuestion = ({location}: any) => {
  const history = useHistory();
  const toast = useToast();

  const lessonId = +location.state.lessonId;

  const [selectedQuestionId, setSelectedQuestionId] = useState(1);

  const [inputLengthCondition, setInputLengthCondition] = useState(0);
  const maxLengthCondition = selectedQuestionId === 16 || selectedQuestionId === 4 ? 140 : 65;

  const [isTimeLimit, setTimeLimit] = useState(Boolean(false));
  const timeLimitHandler = () => setTimeLimit((prev) => !prev);

  const {
    handleSubmit,
    formState: {errors, isSubmitting},
    register,
    setValue,
  } = useForm<any>({defaultValues: {questionTypeId: 1}});

  const [createQuestion] = useCreateQuestionMutation();

  useEffect(() => {
    if (getTimeLimit(selectedQuestionId)) setTimeLimit(true);
    else setTimeLimit(false);
  }, [selectedQuestionId]);

  const onSubmit = async ({acceptability, questionCondition, questionTypeId, timeLimit}: any) => {
    const defaultAcceptability = getAccessibility(questionTypeId);
    const defaultTimeLimit = getTimeLimit(questionTypeId);

    const newAcceptability = acceptability || defaultAcceptability;
    const newTimeLimit = isTimeLimit ? timeLimit || defaultTimeLimit : null;
    const newManatory = questionTypeId !== 16;

    setValue('acceptability', newAcceptability);
    setValue('timeLimit', +newTimeLimit);

    const updatedQuestion = {
      acceptability: newAcceptability,
      questionCondition,
      isMandatory: newManatory,
      questionTypeId,
      lessonId,
      timeLimit: newTimeLimit,
    };

    const result = (await createQuestion(updatedQuestion)) as any;
    const newQuestionId = result.data.data.id;

    if (result.data.success)
      toast({
        title: 'Question created successfully',
        status: 'success',
        duration: 9000,
        isClosable: true,
      });

    history.push(`/question/${newQuestionId}`);
  };

  const setConditionHandler = () => {
    setValue('questionCondition', 'Fill in the gap');
    setInputLengthCondition(15);
  };

  const isInvalidForm = !!errors.questionCondition || !!errors.acceptability;

  return (
    <>
      <Header isCourse isTopic isLesson />

      <SharedWrapper p="10">
        <QuestionHeader title="New Question" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl isInvalid={isInvalidForm}>
              <Box>
                <FormLabel htmlFor="questionTypeId" color="slate.400">
                  Question Types
                </FormLabel>

                <Select
                  id="questionTypeId"
                  bg="blue.200"
                  {...register('questionTypeId', {
                    required: 'Question Types is required',
                    valueAsNumber: true,
                    onChange: (e) => setSelectedQuestionId(+e.target.value),
                  })}
                >
                  {Object.entries(questionTypesSelect).map((questionType) => {
                    return (
                      <option key={questionType[0]} value={questionType[0]}>
                        {questionType[1]}
                      </option>
                    );
                  })}
                </Select>
                <FormErrorMessage>{errors.questionTypes && errors.questionTypes.message}</FormErrorMessage>
              </Box>

              <Box>
                <FormLabel htmlFor="questionCondition" color="slate.400">
                  Condition
                </FormLabel>

                <Input
                  id="questionCondition"
                  bg="blue.200"
                  autoComplete="off"
                  {...register('questionCondition', {
                    required: 'Condition is required',
                    maxLength: {value: maxLengthCondition, message: 'Too long title'},
                    onChange: (e) => setInputLengthCondition(e.target.value.length),
                  })}
                />
                <FormErrorMessage>{errors.questionCondition && errors.questionCondition.message}</FormErrorMessage>
                <Text>
                  {inputLengthCondition} of {maxLengthCondition} available characters
                </Text>
              </Box>

              {selectedQuestionId === 8 && (
                <Button onClick={setConditionHandler}>Set &quot;Fill in the gap&quot; condition</Button>
              )}
              {selectedQuestionId !== 16 && (
                <Box>
                  <FormLabel htmlFor="acceptability" color="slate.400">
                    Acceptability
                  </FormLabel>

                  <Input
                    id="acceptability"
                    type="number"
                    bg="blue.200"
                    autoComplete="off"
                    {...register('acceptability', {
                      valueAsNumber: true,
                    })}
                  />
                  <FormErrorMessage>{errors.acceptability && errors.acceptability.message}</FormErrorMessage>
                </Box>
              )}

              <Box>
                <Flex justify="space-between" align="baseline">
                  <FormLabel htmlFor="timeLimit" color="slate.400">
                    Time limit
                  </FormLabel>

                  <Switch id="isTimeLimit" isChecked={isTimeLimit} onChange={timeLimitHandler} />
                </Flex>

                <Input
                  id="timeLimit"
                  bg="blue.200"
                  type="number"
                  autoComplete="off"
                  disabled={!isTimeLimit}
                  {...register('timeLimit', {
                    valueAsNumber: true,
                  })}
                />
                <FormErrorMessage>{errors.timeLimit && errors.timeLimit.message}</FormErrorMessage>
              </Box>
            </FormControl>

            <Spacer />

            <Button type="submit" isLoading={isSubmitting} mt="10">
              Save changes
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;

const getAccessibility = (questionTypeId: number) => {
  switch (questionTypeId) {
  case 1:
    return 56;
  case 2:
    return 75;
  case 3:
    return 75;
  case 4:
    return 100;
  case 5:
    return 67;
  case 7:
    return 75;
  case 8:
    return 100;
  case 9:
    return 100;
  case 10:
    return 67;
  case 11:
    return 50;
  case 14:
    return 100;
  case 15:
    return 100;
  }
};

const getTimeLimit = (questionTypeId: number) => {
  switch (questionTypeId) {
  case 1:
    return null;
  case 2:
    return 30;
  case 3:
    return 30;
  case 4:
    return null;
  case 5:
    return null;
  case 7:
    return 30;
  case 8:
    return null;
  case 9:
    return null;
  case 10:
    return null;
  case 11:
    return null;
  case 14:
    return null;
  case 15:
    return null;
  case 16:
    return null;
  }
};
