import {useCheckCourseQuery} from '../../../../services/coursesService';
import {IsErrorMessage, LoaderSpinner, SharedWrapper} from '../../../../common';
import {Flex, Table, Tbody, Text, Th, Thead, Tr} from '@chakra-ui/react';
import styled from '@emotion/styled';
import {ReactComponent as clockIcon} from '../../../../assets/svg/clock.svg';
import {CourseValidationErrorItem, CourseValidationErrorItemModel} from './CourseValidationErrorItem';

export const CourseValidationErrorList = ({courseId}: any) => {
  const {data, isLoading, error} = useCheckCourseQuery(courseId);

  return (
    <>
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <ClockIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
                        COURSE VALIDATION ERRORS
          </Text>
        </Flex>

        {isLoading && <LoaderSpinner />}

        <IsErrorMessage error={error} />

        {!isLoading && !error && data && (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>SOURCE → DESTINATION</Th>
                  <Th>ERROR/WARNING</Th>
                  <Th>DETAILS</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {data.map(({id,
                  item,
                  courseTitle,
                  courseId,
                  lessonTitle,
                  lessonId,
                  lessonLevel,
                  lessonTypeId,
                  questionTypeId,
                  questionId,
                  theoryType,
                  type,
                  topicTitle,
                  topicId,
                  mechanicName,
                  answerId}: CourseValidationErrorItemModel) => (
                  <CourseValidationErrorItem
                    key={id}
                    courseId={courseId}
                    courseTitle={courseTitle}
                    lessonId={lessonId}
                    lessonLevel={lessonLevel}
                    lessonTitle={lessonTitle}
                    lessonTypeId={lessonTypeId}
                    questionTypeId={questionTypeId}
                    questionId={questionId}
                    item={item}
                    topicId={topicId}
                    topicTitle={topicTitle}
                    id={id}
                    mechanicName={mechanicName}
                    type={type}
                    theoryType={theoryType}
                    answerId={answerId}/>
                ))}
              </Tbody>
            </Table>

            {!data.length && (
              <Text color="slate.800" p={5}>
                                No data yet
              </Text>
            )}
          </>
        )}
      </SharedWrapper>
    </>
  );
};

const ClockIcon = styled(clockIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
