import {useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import styled from '@emotion/styled';
import {Box, Button, Flex, Table, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import {useGetExplanationTypesQuery, useUpdateQuestionMutation} from 'services/questionsService';
import {QuestionHeader} from 'pages/question/common';
import {SharedWrapper} from 'common';

export interface BinModel {
  Id: number;
  Title: string;
  assetURL: string | null;
}

export const Parameters = ({question, questionId, baseUrl}: any) => {
  const history = useHistory();
  const {data, isLoading} = useGetExplanationTypesQuery('');
  const [updateQuestion] = useUpdateQuestionMutation();

  const explanationTypes = data?.filter(({type}: any) => type === 'common');
  const bins = question.questionPayload?.Bins;

  const [appearingSpeed, setAppearingSpeed] = useState(question.questionPayload?.ObjectAppearingSpeed || 2);
  const editObjectAppearingSpeed = async () => {
    const value = prompt('ObjectAppearingSpeed') as string;
    const validatedValue = !Number.isInteger(+value) ? 0 : +value;
    setAppearingSpeed(validatedValue);
    const updatedQuestion = {
      questionPayload: {...question.questionPayload, ObjectAppearingSpeed: validatedValue},
    };
    await updateQuestion({questionId, updatedQuestion});
  };

  const [fallingSpeed, setFallingSpeed] = useState(question.questionPayload?.ObjectFallingSpeed || 2);
  const editObjectFallingSpeed = async () => {
    const value = prompt('ObjectFallingSpeed') as string;
    const validatedValue = !Number.isInteger(+value) ? 0 : +value;
    setFallingSpeed(validatedValue);
    const updatedQuestion = {
      questionPayload: {...question.questionPayload, ObjectFallingSpeed: validatedValue},
    };
    await updateQuestion({questionId, updatedQuestion});
  };

  const createBinHandler = () => {
    history.push(baseUrl + '/parameters/new-bin');
  };

  const deleteHandler = async (e: any) => {
    const {id} = e.target;
    const updatedBins = question.questionPayload.Bins.filter((elem: any) => +elem.Id !== +id);
    const binsToSend: BinModel[] = [];
    if (updatedBins.length > 0) {
      let i = 0;
      for (const bin of updatedBins) {
        binsToSend.push({
          Id: i,
          Title: bin.Title,
          assetURL: Object.prototype.hasOwnProperty.call(bin, 'assetURL') ? bin.assetURL : null,
        });
        i += 1;
      }
    }


    const updatedQuestion = {
      questionPayload: {...question.questionPayload, Bins: binsToSend},
    };
    await updateQuestion({questionId, updatedQuestion});
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Explanations" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th>Case</Th>
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              explanationTypes.map(({explanation_type_description, title}: any) => (
                <Tr key={title}>
                  <Td>{explanation_type_description}</Td>
                  <Td>{title}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Bins" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Object</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            {bins &&
              bins.map(({Title: title, Id: id}: any) => (
                <Tr key={id}>
                  <Td>{title}</Td>
                  <Td>
                    <Flex justify="flex-end">
                      <EditButton id={id} />
                      <Button size="sm" variant="secondaryWarning" id={id} onClick={deleteHandler}>
                        Delete
                      </Button>
                    </Flex>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>

        <Button onClick={createBinHandler} mt="3">
          Create new bin
        </Button>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Constants" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Constants</Th>
              <Th>Value</Th>
              <Th />
            </Tr>
          </Thead>

          <Tbody>
            <Tr>
              <Td>ObjectAppearingSpeed</Td>
              <Td>{appearingSpeed}</Td>

              <Td>
                <Flex justify="end">
                  <ButtonEdit onClick={editObjectAppearingSpeed}>Edit</ButtonEdit>
                </Flex>
              </Td>
            </Tr>

            <Tr>
              <Td>ObjectFallingSpeed</Td>
              <Td>{fallingSpeed}</Td>
              <Td>
                <Flex justify="end">
                  <ButtonEdit onClick={editObjectFallingSpeed}>Edit</ButtonEdit>
                </Flex>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />
    </>
  );
};

const EditButton = ({id}: any) => {
  const history = useHistory();
  const {url} = useRouteMatch();

  const editHandler = () => {
    history.push(`${url}/bins/${id}`);
  };

  return (
    <Button size="sm" onClick={editHandler} mr="3">
      Edit
    </Button>
  );
};

const ButtonEdit = styled(Button)``;
