import {useGetGroupsQuery} from 'services/avatarService';
import {Menu, Header} from './components';
import {LoaderSpinner} from 'common';
import {AvatarGroupsList} from './components/AvatarGroupsList';

export const AvatarGroups = () => {
  const {data, error, isLoading} = useGetGroupsQuery(null);

  return (
    <>
      <Header />
      <Menu />

      {!isLoading && !error && data && (
        <AvatarGroupsList avatarGroups={data} error={error} />
      )}
    </>
  );
};
