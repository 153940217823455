import {Controller, useForm} from 'react-hook-form';
import styled from '@emotion/styled';
import {Box, Button, FormControl, FormErrorMessage, FormLabel, Table, Tbody, Td, Th, Thead, Tr} from '@chakra-ui/react';
import {QuestionHeader} from 'pages/question/common';
import {ImageContentInput, SharedWrapper} from 'common';
import {useGetExplanationTypesQuery, useUpdateQuestionMutation} from 'services/questionsService';

export const Parameters = ({questionId, question}: any) => {
  const {data, isLoading} = useGetExplanationTypesQuery('');
  const explanationTypes = data?.filter(({type}: any) => type === 'common');
  const [updateImage] = useUpdateQuestionMutation();

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
    setValue,
  } = useForm<any>({
    defaultValues: {
      imageContent: question.assetURL,
    },
  });


  const onSubmit = async ({imageContent}: any) => {
    await updateImage({
      questionId,
      updatedQuestion: {assetURL: imageContent},
    });
  };

  return (
    <>
      <SharedWrapper p="10">
        <QuestionHeader title="Explanations" />

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Explanation</Th>
              <Th>Case</Th>
            </Tr>
          </Thead>

          <Tbody>
            {!isLoading &&
              explanationTypes.map(({explanation_type_description, title}: any) => (
                <Tr key={title}>
                  <Td>{explanation_type_description}</Td>
                  <Td>{title}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </SharedWrapper>

      <Box h="10" />

      <SharedWrapper p="10">
        <QuestionHeader title="Other" />

        <Box>
          <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <FormControl>
              <FormLabel htmlFor="imageContent" color="slate.400">
                Image content
              </FormLabel>
              <Controller
                name="imageContent"
                control={control}
                render={({field: {value}}) => (
                  <ImageContentInput
                    value={value}
                    inputName="imageContent"
                    setValue={setValue}
                    imgLink={question.assetURL}
                  />
                )}
              />

              <FormErrorMessage>{errors.imageContent && errors.imageContent.message}</FormErrorMessage>
            </FormControl>

            <Button isLoading={isSubmitting} type="submit">
              Save
            </Button>
          </Form>
        </Box>
      </SharedWrapper>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-basis: 90%;
`;
