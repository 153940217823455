import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from 'redux-persist';
import storage from 'redux-persist/lib/storage';


import {authApi} from 'services/authService';
import {coursesApi} from 'services/coursesService';
import {topicsApi} from 'services/topicsService';
import {lessonsApi} from 'services/lessonsService';
import {filesApi} from 'services/filesService';
import {questionsApi} from 'services/questionsService';
import {avatarsApi} from 'services/avatarService';
import {learnersApi} from 'services/learnersService';
import {notificationsApi} from 'services/notificationsService';

import {navigationReducer} from 'common/SharedHeader/navigationSlice';

import {rtkQueryErrorLogger} from 'store/middlewares/errorLoggerMiddleware';
import {userFeedbackApi} from 'services/feedbackService';
import {useGetFdlUrlApi} from '../services/getFldUrlService';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['navigationReducer'],
};


const rootReducer = {
  navigationReducer,
  [authApi.reducerPath]: authApi.reducer,
  [coursesApi.reducerPath]: coursesApi.reducer,
  [topicsApi.reducerPath]: topicsApi.reducer,
  [lessonsApi.reducerPath]: lessonsApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
  [questionsApi.reducerPath]: questionsApi.reducer,
  [userFeedbackApi.reducerPath]: userFeedbackApi.reducer,
  [useGetFdlUrlApi.reducerPath]: useGetFdlUrlApi.reducer,
  [avatarsApi.reducerPath]: avatarsApi.reducer,
  [learnersApi.reducerPath]: learnersApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
};

const persistedReducer = persistReducer(
  persistConfig,
  combineReducers(rootReducer)
);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      authApi.middleware,
      coursesApi.middleware,
      topicsApi.middleware,
      lessonsApi.middleware,
      filesApi.middleware,
      questionsApi.middleware,
      userFeedbackApi.middleware,
      useGetFdlUrlApi.middleware,
      rtkQueryErrorLogger
    ),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
