import * as R from 'ramda';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDebounce} from 'use-debounce';
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import styled from '@emotion/styled';
import {Box, Button, ButtonGroup, Flex, Table, Tbody, Text, Th, Thead, Tr} from '@chakra-ui/react';
import { useUpdateGroupsOrderMutation } from 'services/avatarService';
import {SharedWrapper} from 'common';
import {AvatarGroupItem} from '../AvatarGroupItem';
import {AvatarGroupsChildrenList} from '../AvatarGroupsChildrenList';
import {ReactComponent as levelIcon} from 'assets/svg/book.svg';

export const AvatarGroupsList = ({avatarGroups}: any) => {
  const [items, setItems] = useState<any>(null);
  const [value] = useDebounce(items, 200);
  const history = useHistory();

  const sortedData = [...avatarGroups].sort((a: any, b: any) => a.orderIndex - b.orderIndex);
  const [updateOrderRequest] = useUpdateGroupsOrderMutation();

  const updateOrder = useCallback(
    (items, value) => {
      if (items) {
        const isChanged = !R.equals(items, sortedData);

        if (value && isChanged) {
          const orderCombination = value.reduce(
            (acc: any[], item: any, idx: number) => [...acc, {id: item.id, order: idx}],
            []
          );

          updateOrderRequest({orderCombination});
        }
      }
    },
    [items, value]
  );

  useEffect(() => {
    setItems(sortedData);
  }, [avatarGroups]);

  useEffect(() => {
    updateOrder(items, value);

    return () => {
      updateOrder(items, value);
    };
  }, [value]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const createGroupHandler = () => {
    history.push('/create-group');
  };

  return (
    <>
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <LevelIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Groups
          </Text>
        </Flex>

        {items && (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Label</Th>
                  <Th>ID</Th>
                  <Th>Created At</Th>
                  <Th />
                  <Th />
                </Tr>
              </Thead>

              <Tbody>
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                  <SortableContext items={items} strategy={verticalListSortingStrategy}>
                    {items.map(({label, id, created_at, order, parent_id, updated_at, thumbnail_url, children}: any) => (
                      <>
                        <AvatarGroupItem
                          key={id}
                          id={id}
                          label={label}
                          created_at={created_at}
                          order={order}
                          parent_id={parent_id}
                          updated_at={updated_at}
                          thumbnail_url={thumbnail_url}
                          isParent={true}
                        />
                        <AvatarGroupsChildrenList avatarGroups={children} />
                      </>
                    ))}
                  </SortableContext>
                </DndContext>
              </Tbody>
            </Table>
          </>
        )}
        <Box m="4">
          <ButtonGroup>
            <Button onClick={createGroupHandler}>Create new avatar</Button>
          </ButtonGroup>
        </Box>
      </SharedWrapper>
    </>
  );

  function handleDragEnd(event: any) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items: any) => {
        const oldIndex = items.findIndex(({id}: any) => id === active.id);
        const newIndex = items.findIndex(({id}: any) => id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
};

const LevelIcon = styled(levelIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
