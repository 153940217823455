import * as R from 'ramda';
import {useCallback, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDebounce} from 'use-debounce';
import {closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy} from '@dnd-kit/sortable';
import styled from '@emotion/styled';
import {Box, Button, ButtonGroup, Flex, Table, Tbody, Text, Th, Thead, Tr} from '@chakra-ui/react';
import {useDeleteLevelMutation, useUpdateLessonOrderMutation} from 'services/lessonsService';
import {SharedWrapper} from 'common';
import {LessonItem} from './LessonItem';
import {ReactComponent as levelIcon} from 'assets/svg/book.svg';
import { LessonItemModel, LevelModel } from '../../../../models';


export const Level = ({level, lessons, isArchived, topicId}: LevelModel) => {
  const [items, setItems] = useState<any>(null);
  const [value] = useDebounce(items, 200);
  const history = useHistory();

  const [deleteLevel] = useDeleteLevelMutation();

  const sortedData = [...lessons].sort((a: LessonItemModel, b: LessonItemModel) => a.orderIndex - b.orderIndex);
  const [updateOrderRequest] = useUpdateLessonOrderMutation();

  const updateOrder = useCallback(
    (items, value) => {
      if (items) {
        const isChanged = !R.equals(items, sortedData);

        if (value && isChanged) {
          const orderCombination = value.reduce(
            (acc: LessonItemModel[], item: LessonItemModel, idx: number) => [...acc, {lessonId: item.id, orderIndex: idx}],
            []
          );

          updateOrderRequest({topicId, updatedOrder: {orderCombination}});
        }
      }
    },
    [items, value]
  );

  useEffect(() => {
    setItems(sortedData);
  }, [lessons]);

  useEffect(() => {
    updateOrder(items, value);

    return () => {
      updateOrder(items, value);
    };
  }, [value]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const createLessonHandler = () => {
    history.push({pathname: `/topic/${topicId}/new-lesson`, state: {level}});
  };

  const deleteLevelHandler = async () => {
    const result = confirm(
      'Do you really want to delete this level? \n' + 'This will also delete all the lessons in that level'
    );

    if (result) await deleteLevel({level, topicId});
  };

  return (
    <>
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <LevelIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Level {level}
          </Text>
        </Flex>

        {items && (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>LESSON</Th>
                  <Th>LESSON ID</Th>
                  <Th>Questions</Th>
                  <Th>CREATED</Th>
                  <Th />
                </Tr>
              </Thead>

              <Tbody>
                <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                  <SortableContext items={items} strategy={verticalListSortingStrategy}>
                    {items.map(({title, id, createdAt, questionCount, orderIndex}: LessonItemModel) => (
                      <LessonItem
                        topicId={topicId}
                        key={id}
                        id={id}
                        questionCount={questionCount}
                        title={title}
                        createdAt={createdAt}
                        orderIndex={orderIndex}
                        isArchived={isArchived}
                      />
                    ))}
                  </SortableContext>
                </DndContext>
              </Tbody>
            </Table>
          </>
        )}
        <Box m="4">
          <ButtonGroup>
            <Button onClick={createLessonHandler}>Create new lesson</Button>
            <Button variant="secondaryWarning" onClick={deleteLevelHandler}>
              Delete level
            </Button>
          </ButtonGroup>
        </Box>
      </SharedWrapper>
    </>
  );

  function handleDragEnd(event: any) {
    const {active, over} = event;

    if (active.id !== over.id) {
      setItems((items: any) => {
        const oldIndex = items.findIndex(({id}: any) => id === active.id);
        const newIndex = items.findIndex(({id}: any) => id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }
};

const LevelIcon = styled(levelIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
