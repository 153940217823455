import styled from '@emotion/styled';
import {Flex, Table, Tbody, Text, Th, Thead, Tr} from '@chakra-ui/react';
import {useGetCourseStatusesByIdQuery} from 'services/coursesService';
import {StatusItem} from './StatusItem';
import {IsErrorMessage, LoaderSpinner, SharedWrapper} from 'common';
import {StatusItemProps} from 'models';
import {ReactComponent as clockIcon} from 'assets/svg/clock.svg';

type StatusChangesProps = {courseId: string};

export const StatusChanges = ({courseId}: StatusChangesProps) => {
  const {data, isLoading, error} = useGetCourseStatusesByIdQuery(courseId);

  return (
    <>
      <SharedWrapper my="5">
        <Flex mx="4" mt="4" mb="6" align="center">
          <ClockIcon />
          <Text fontWeight="medium" fontSize="16" color="slate.900">
            Status changes
          </Text>
        </Flex>

        {isLoading && <LoaderSpinner />}

        <IsErrorMessage error={error} />

        {!isLoading && !error && data && (
          <>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>FROM → TO</Th>
                  <Th>EDUCATOR</Th>
                  <Th>CHANGED</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {data.map(({courseId, changedAt, educator, currentStatus, previousStatus}: StatusItemProps) => (
                  <StatusItem
                    key={changedAt}
                    courseId={courseId}
                    educator={educator}
                    changedAt={changedAt}
                    currentStatus={currentStatus}
                    previousStatus={previousStatus}
                  />
                ))}
              </Tbody>
            </Table>

            {!data.length && (
              <Text color="slate.800" p={5}>
                No status chages yet
              </Text>
            )}
          </>
        )}
      </SharedWrapper>
    </>
  );
};

const ClockIcon = styled(clockIcon)`
  height: 16px;
  width: auto;
  padding-right: 5px;
`;
